import * as React from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Collapse,
  Form,
  Radio,
  Space,
  Switch,
  notification,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { IdLabel } from "src/references/getIdNames";
import { isAttrDictionary } from "src/types/AttrType";
import { onError } from "src/common/onError";
import { FieldsWithTools } from "src/pages/ManagementPage/FieldsWithTools";
import { findNode } from "src/common/findNode";
import { ZAttribute } from "src/types/ZAttribute";
import { SelectAttribute, buildTreeForSrcDict } from "../SelectAttribute";
import { edAttrField } from "../../EdAttribute";
import { loadRestrictions } from "../../../objectsApi";
import { Obj2TabStore } from "../../Obj2TabStore";
import { findNodeOwnerObject } from "../../utils/findNodeOwnerObject";
import { AttributeO2, CommonNodeO2 } from "../../Obj2Nodes";
import { RestrictionsTableField } from "./RestrictionsTableField";
import { RolesEditor } from "../../roles/RolesEditor";
import { UpToObject } from "../UpToObject";
import { AttrCommonFields } from "./AttrCommonFields";
import styles from "./AttrForm2.module.less";

/**
 * Внутри формы формат полей соответствует EdAttribute
 */

interface PropsAttrForm2 {
  store: Obj2TabStore;
  attrNode: AttributeO2;
}

export const AttrForm2: React.FC<PropsAttrForm2> = observer(
  ({ store, attrNode }) => {
    const { attr, states = [] } = attrNode;
    const valueType = Form.useWatch(edAttrField.valueType);
    const useRestriction = Form.useWatch(edAttrField.useRestriction);
    const disabled = !attrNode.actions.update;

    const typeFilter = (typeId: number): boolean =>
      isAttrDictionary(store.attrTypesDict[typeId]);
    const dictVisible = typeFilter(valueType);

    React.useEffect(() => {
      loadRestrictions(attr.id).catch(onError);
    }, [attr.id]);

    // Доступ к словарям
    const [refTree, setRefTree] = React.useState<CommonNodeO2[]>([]);
    React.useEffect(() => {
      if (dictVisible) {
        const owner = findNodeOwnerObject(attrNode.key, store.treeData);
        if (owner) {
          setRefTree(
            buildTreeForSrcDict(owner, { dstAttrId: attr.id, typeFilter }),
          );
        } else notification.warning({ message: "Не найден объект" });
      } else {
        setRefTree([]);
      }
    }, [dictVisible]);

    const rolesGroup = store.roleGroups;

    return (
      <FieldsWithTools
        tools={
          <>
            <UpToObject store={store} />
            <Button
              danger
              icon={<DeleteOutlined />}
              onClick={() => store.safeDeleteCurNode()}
              disabled={!attrNode.actions.delete}
              id="deleteCurAttr"
            >
              Удалить атрибут
            </Button>
          </>
        }
      >
        <AttrCommonFields
          attrKey={attrNode.key}
          attrId={attrNode.attr.id}
          objectId={store.curObjectId ?? 0}
          disabled={disabled}
          dataSource={store}
          isFieldUnique={uniqueAttrTest(store.treeData)}
          loadObjectOptions={() => store.loadObjectOptions()}
        />
        <div className={styles.twoCols}>
          <div className={styles.blockVert} />
        </div>
        <div>
          {/* Переключатель становится виден только если тип текущего атрибута использует словарь */}
          {dictVisible && (
            <>
              <label>
                <Space style={{ marginBottom: 24 }}>
                  <Form.Item name={edAttrField.useRestriction} noStyle>
                    <Switch disabled={disabled} />
                  </Form.Item>
                  <span>Ограничения по значению</span>
                </Space>
              </label>

              {useRestriction && (
                <>
                  <Form.Item
                    name={edAttrField.masterAttrId}
                    label="Выберите атрибут"
                    rules={[
                      { required: true, message: "Необходимо выбрать атрибут" },
                    ]}
                    className="idMasterAttr"
                  >
                    <SelectAttribute tree={refTree} disabled={disabled} />
                  </Form.Item>
                  <RestrictionsTableField disabled={disabled} />
                </>
              )}
            </>
          )}
        </div>
        <Collapse
          accordion
          defaultActiveKey={states[0]?.id.toString()}
          items={states.map(({ id, name }) => ({
            key: String(id),
            label: `${name} (${id})`,
            children: (
              <Form.Item label="" name={[edAttrField.rolesMap, String(id)]}>
                <RolesEditor
                  columns={store.attrTypeActions}
                  rows={rolesGroup}
                />
              </Form.Item>
            ),
          }))}
        />
      </FieldsWithTools>
    );
  },
);

type PropsTypeSelect = {
  types: IdLabel[];
  value?: number;
  onChange?(v: number): void;
  disabled: boolean;
};
const TypeSelect: React.FC<PropsTypeSelect> = (props) => {
  const { types, value, onChange, disabled } = props;
  return (
    <Radio.Group
      disabled={disabled}
      value={value}
      onChange={(e) => {
        onChange?.(e.target.value);
      }}
      className={styles.radioVert}
      options={types.map(({ id, label }) => ({ value: id, label }))}
    />
  );
};
TypeSelect.defaultProps = {
  value: undefined,
  onChange: undefined,
};

const uniqueAttrTest =
  (treeData: CommonNodeO2[]) =>
  (field: keyof ZAttribute, attrKey: React.Key, value: unknown): boolean => {
    const obj = findNodeOwnerObject(attrKey, treeData);
    return !findNode((node: CommonNodeO2) => {
      if (node.type !== "attr" || node.key === attrKey || !value) return false;
      return node.attr[field] === value;
    }, obj?.children ?? []);
  };
