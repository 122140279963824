import * as React from "react";
import { FrownOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { apiLoadPersonList } from "src/common/apiPerson";
import { ifDef } from "src/common/ifDef";
import { makeDictionary } from "src/common/makeDictionary";
import { onError } from "src/common/onError";

type SinglePerson = {
  id: string;
  label: React.ReactNode;
};

interface PropsPersonCellInfo {
  values: string[];
}

export const PersonCellInfo: React.FC<PropsPersonCellInfo> = ({ values }) => {
  const [persons, setPersons] = React.useState<SinglePerson[]>([]);
  const [wait, setWait] = React.useState(false);
  React.useEffect(() => {
    setWait(true);
    makePersonsList(values)
      .then((list) => setPersons(list))
      .catch(onError)
      .finally(() => setWait(false));
  }, [values]);
  return (
    <Spin spinning={wait} size="small" delay={50}>
      {persons.map(({ id, label }, index) => (
        <React.Fragment key={id}>
          {index > 0 && ", "}
          {label}
        </React.Fragment>
      ))}
    </Spin>
  );
};

// TODO: Конечно тут бы нужно какое-то кеширование, но пока так
const makePersonsList = async (
  values: string[] = [],
): Promise<SinglePerson[]> => {
  const result = await apiLoadPersonList({ roleIds: [], userIds: values });
  const dict = makeDictionary(result.rows, ({ id }) => id);
  return values.map(
    (value) =>
      ifDef(dict[value], ({ id, login }) => ({ id, label: login })) ?? {
        id: value,
        label: <FrownOutlined />,
      },
  );
};
