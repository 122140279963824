import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { z } from "zod";
import { modalSupportStore } from "src/components/ModalSupport/ModalSupportStore";
import { ZMenuItem } from "./ZMenuItem";
import { loadMenu } from "./loadMenu";
import { getOpenedMenuItems } from "./getOpenedMenuItems";

const zMenuState = z.object({
  menuActive: z.boolean(),
  openedItems: z.string().array().nullable(),
});
type ZMenuState = z.infer<typeof zMenuState>;

const leftMenuStateKey = "leftMenuStateKey";

export class LeftMenuStore {
  constructor() {
    makeAutoObservable(this);
  }

  menuList: ZMenuItem[] = [];

  setMenuList(list: ZMenuItem[]) {
    this.menuList = list;
  }

  menuState: ZMenuState = { menuActive: true, openedItems: [] };

  setMenuState(state: ZMenuState) {
    this.menuState = state;
  }

  activeMenuElement = "";

  setActiveMenuElement(element: string) {
    this.activeMenuElement = element;
  }

  safeOpenedItems: string[] | null = [];

  setSafeOpenedItems(items: string[] | null) {
    this.safeOpenedItems = items;
  }

  defaultMenuState(list: ZMenuItem[]): ZMenuState {
    return {
      ...this.menuState,
      openedItems: getOpenedMenuItems(list) ?? [],
    };
  }

  parseStoredState(storedState: string | null, list: ZMenuItem[]): ZMenuState {
    try {
      return storedState
        ? zMenuState.parse(JSON.parse(storedState))
        : this.defaultMenuState(list);
    } catch {
      return this.defaultMenuState(list);
    }
  }

  protected loadMenuState(list: ZMenuItem[]): void {
    try {
      const storedState = localStorage.getItem(leftMenuStateKey);
      const newState = this.parseStoredState(storedState, list);
      if (this.menuState.menuActive) {
        this.setMenuState(newState);
      } else {
        this.menuState.menuActive = newState.menuActive;
      }
      this.setSafeOpenedItems(newState.openedItems);
    } catch (e) {
      onError(e);
    }
  }

  saveMenuState(): void {
    try {
      localStorage.setItem(leftMenuStateKey, JSON.stringify(this.menuState));
    } catch (e) {
      onError(e);
    }
  }

  onActiveToggle(): void {
    this.setMenuState({
      menuActive: !this.menuState.menuActive,
      openedItems: this.safeOpenedItems ?? [],
    });
    this.saveMenuState();
  }

  onOpenMenuItems(keys: string[]): void {
    this.menuState.openedItems = keys;
    if (this.menuState.menuActive) {
      this.setSafeOpenedItems(keys);
      this.saveMenuState();
    }
  }

  loading = false;

  setLoading(flag: boolean) {
    this.loading = flag;
  }

  async init() {
    try {
      this.setLoading(true);
      const menu = await loadMenu();
      if (menu) {
        this.loadMenuState(menu);
        this.setMenuList(menu);
      }
    } catch (e) {
      onError(e);
    } finally {
      this.setLoading(false);
    }
  }

  supportStore = modalSupportStore;

  showSupport() {
    this.supportStore.setSupportState("show");
  }
}

export const leftMenuStore = new LeftMenuStore();
