import { anchorItem, AnchorItem } from "src/common/anchorItem";
import { FormBlockDef } from "src/components/FormWithBlocks";
import { makeBlockId } from "src/components/FormWithBlocks/blockTypes/makeBlockId";
import { blockTitle } from "../blockBuilder/blockTitle";

const flatBlocks = (block: FormBlockDef): FormBlockDef[] => [
  block,
  ...(block.subBlocks ?? [])
    .filter((subBlock) => !!subBlock)
    .flatMap((subBlock) => flatBlocks(subBlock!)),
];

export const anchorList = (block: FormBlockDef | null): AnchorItem[] => {
  if (!block) return [];
  const res = flatBlocks(block)
    .filter(({ items }) => !!items?.length)
    .map(({ key, title }) =>
      anchorItem(`#${makeBlockId(key)}`, blockTitle(title)),
    );
  return res;
};
