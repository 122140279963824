import * as React from "react";
import { Button, Modal, Spin } from "antd";
import { onError } from "src/common/onError";
import { Obj2TabStore } from "../Obj2TabStore";
import { SelectAttribute } from "./SelectAttribute";
import { CommonNodeO2, ValueO2 } from "../Obj2Nodes";
import { findNodeOwnerByKey } from "../../../../common/findNodeOwnerByKey";
import { activateValueNodeO2 } from "../utils/createMainTree";

/**
 * Есть ограничение на то, какой атрибут может быть выбран
 * groupByDictionary
 * +--value1
 * |  +-- attr
 * +--value2
 *    +-- attr
 * То есть среди атрибутов, которые принадлежат значениям (кроме текущего) в текущей группе
 *
 * А еще нужно подгрузить эти значения
 */

interface PropsAddExistingAttribute {
  store: Obj2TabStore;
  valueNode: ValueO2;
}
export const AddExistingAttribute: React.FC<PropsAddExistingAttribute> = ({
  store,
  valueNode,
}) => {
  const [open, setOpen] = React.useState(false);
  const [attrId, setAttrId] = React.useState<number | undefined>();
  const [tree, setTree] = React.useState<CommonNodeO2[]>([]);
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    if (open) {
      setAttrId(undefined);
      setLoading(true);
      loadTree(valueNode, store.treeData)
        .then((topNodes) => setTree(topNodes))
        .catch(onError)
        .finally(() => setLoading(false));
    }
  }, [open]);
  const onChange = (newValue: number | undefined) => {
    setAttrId(newValue);
  };
  const create = () => {
    setOpen(false);
    if (attrId) {
      store.addExistingAttr(valueNode, attrId);
    }
  };
  return (
    <>
      <Button onClick={() => setOpen(true)}>
        Добавить существующий атрибут...
      </Button>
      <Modal
        open={open}
        title="Выберите атрибут"
        onCancel={() => setOpen(false)}
        okButtonProps={{ disabled: !attrId }}
        onOk={create}
      >
        <Spin spinning={loading}>
          <SelectAttribute value={attrId} onChange={onChange} tree={tree} />
        </Spin>
      </Modal>
    </>
  );
};

const loadTree = async (
  curValueNode: ValueO2,
  allObjects: CommonNodeO2[],
): Promise<CommonNodeO2[]> => {
  const { owner } = findNodeOwnerByKey(curValueNode.key, allObjects) ?? {};
  if (!owner || owner.type !== "group") return [];
  const topNodes = (owner.children ?? []).filter(
    (node) => node.key !== curValueNode.key,
  );
  const unloadedNodes = topNodes.filter((n) => !n.children);
  if (unloadedNodes.length > 0) {
    await Promise.all(
      unloadedNodes.map((node) =>
        node.type === "value" ? activateValueNodeO2(node) : Promise.resolve(),
      ),
    );
  }
  return topNodes;
};
