import React from "react";
import { CloseOutlined } from "@ant-design/icons";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { Spin } from "antd";
import styles from "./ObjectRefSelector.module.less";
import { ObjectRefSelectorStore } from "./ObjectRefSelectorStore";

type PropsSelectedTags = {
  store: ObjectRefSelectorStore;
  onChange?: (value: string[]) => void;
};

export const SelectedTags: React.FC<PropsSelectedTags> = observer(
  ({ store, onChange }) => (
    <Spin spinning={store.loading}>
      <div className={styles.selectedEntitiesTitle}>
        {t("Selected entities")}
      </div>
      <div className={styles.selectedEntities}>
        {store.options.map((opt) => (
          <div className={styles.selectedEntitiesItemInner}>
            {opt.label}
            <CloseOutlined
              onClick={() => {
                const changed = store.options
                  .filter((item) => item.value !== opt.value)
                  .map((e) => String(e.value));
                onChange?.(changed);
                store.onSelect(changed);
              }}
            />
          </div>
        ))}
      </div>
    </Spin>
  ),
);

SelectedTags.defaultProps = {
  onChange: undefined,
};
