import * as React from "react";
import { AttrPersonSelectProps } from "src/common/attrEdit/components/ZAttrPersonSelect";
import { ZAttribute } from "src/types/ZAttribute";
import { ZPersonRow } from "src/types/ZPersonRow";
import { Modal, Spin } from "antd";
import { apiLoadPersonList } from "src/common/apiPerson";
import { observer } from "mobx-react-lite";
import { onError } from "src/common/onError";
import { classNames } from "src/common/classNames";
import { AColumn } from "../tables/AsyncTable";
import { TableFacade } from "../tables/TableFacade";
import { TableStore } from "../tables/TableStore";
import styles from "./PersonSelect.module.less";

/* eslint jsx-a11y/click-events-have-key-events: "off" */
/* eslint jsx-a11y/no-noninteractive-element-interactions: "off" */

type PropsPersonSelect = AttrPersonSelectProps & {
  value?: string[] | null;
  onChange?(newValue?: string[] | null): void;
  attr: ZAttribute;
  disabled?: boolean;
  allowClear?: boolean;
};

type FilterPerson = {};

export const PersonSelect: React.FC<PropsPersonSelect> = observer((props) => {
  const { value, onChange, disabled, attr, multiple, allowClear } = props;
  const roleIds = attr.roleIds ?? [];
  const [loading, setLoading] = React.useState(false);
  const [dict, setDict] = React.useState<Record<string, ZPersonRow>>({});
  const [store, setStore] = React.useState<
    TableStore<ZPersonRow, FilterPerson> | undefined
  >();
  const sortedValue = (value ? [...value] : []).sort();
  React.useEffect(() => {
    if (sortedValue[0]) {
      setLoading(true);
      const userIds = sortedValue.map((sid) => sid);
      apiLoadPersonList({ roleIds, userIds })
        .then(({ rows }) =>
          setDict((prevDict) =>
            rows.reduce(
              (curMap, pers) => ({ ...curMap, [String(pers.id)]: pers }),
              { ...prevDict },
            ),
          ),
        )
        .catch(onError)
        .finally(() => setLoading(false));
    }
  }, [value]);
  const open = () => {
    const newStore = new TableStore({
      rowKey: "id",
      fnLoad: (params) => apiLoadPersonList({ ...params, roleIds }),
      selectionSettings: {
        selectionType: multiple ? "checkbox" : "radio",
        keepSelected: true,
      },
    });
    if (value) {
      newStore.setSelected(
        sortedValue.map((sid) => ({ id: sid }) as ZPersonRow),
      );
    }
    setStore(newStore);
  };
  const close = () => {
    setStore(undefined);
  };
  const columns: AColumn<ZPersonRow>[] = [
    { key: "login", title: "ФИО", dataIndex: "login" },
  ];
  return (
    <>
      <Spin spinning={loading} size="small">
        <button
          type="button"
          className={styles.selectBox}
          disabled={disabled}
          onClick={open}
        >
          {sortedValue[0] && (
            <span className={styles.text}>
              {dict[sortedValue[0]]?.login ?? sortedValue[0]}
            </span>
          )}
          {value && value.length > 1 && (
            <span className={styles.counter}>+{value.length - 1}</span>
          )}
          <span className={styles.space} />
          {allowClear && !!value && (
            <span
              role="img"
              className={classNames([
                "anticon",
                "anticon-close-circle",
                styles.clear,
              ])}
              aria-label="close-circle"
              onClick={(e) => {
                e.stopPropagation();
                onChange?.([]);
              }}
            >
              {iconClose}
            </span>
          )}
        </button>
      </Spin>
      <Modal
        open={!!store}
        onCancel={close}
        onOk={() => {
          onChange?.(makeValue(store?.selected));
          close();
        }}
      >
        {!!store && <TableFacade store={store} columns={columns} />}
      </Modal>
    </>
  );
});

const makeValue = (list?: ZPersonRow[]): string[] | null | undefined => {
  const dstList = list?.map(({ id }) => String(id));
  return dstList && dstList.length === 0 ? null : dstList;
};

PersonSelect.defaultProps = {
  value: undefined,
  onChange: undefined,
  disabled: false,
  allowClear: false,
};

const iconClose = (
  <svg
    fillRule="evenodd"
    viewBox="64 64 896 896"
    focusable="false"
    data-icon="close-circle"
    width="1em"
    height="1em"
    fill="currentColor"
    aria-hidden="true"
  >
    <path d="M512 64c247.4 0 448 200.6 448 448S759.4 960 512 960 64 759.4 64 512 264.6 64 512 64zm127.98 274.82h-.04l-.08.06L512 466.75 384.14 338.88c-.04-.05-.06-.06-.08-.06a.12.12 0 00-.07 0c-.03 0-.05.01-.09.05l-45.02 45.02a.2.2 0 00-.05.09.12.12 0 000 .07v.02a.27.27 0 00.06.06L466.75 512 338.88 639.86c-.05.04-.06.06-.06.08a.12.12 0 000 .07c0 .03.01.05.05.09l45.02 45.02a.2.2 0 00.09.05.12.12 0 00.07 0c.02 0 .04-.01.08-.05L512 557.25l127.86 127.87c.04.04.06.05.08.05a.12.12 0 00.07 0c.03 0 .05-.01.09-.05l45.02-45.02a.2.2 0 00.05-.09.12.12 0 000-.07v-.02a.27.27 0 00-.05-.06L557.25 512l127.87-127.86c.04-.04.05-.06.05-.08a.12.12 0 000-.07c0-.03-.01-.05-.05-.09l-45.02-45.02a.2.2 0 00-.09-.05.12.12 0 00-.07 0z" />
  </svg>
);
