/* eslint-disable react/prop-types */
/* eslint-disable spaced-comment */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";
import { TableStore } from "src/components/tables/TableStore";
import { Empty, Pagination, Spin } from "antd";
import { observer } from "mobx-react-lite";
import { AColumn } from "src/components/tables/AsyncTable";
import { PaginationProps } from "antd/lib";
import { onTableParamsChange } from "src/components/tables/AsyncTable/onTableParamsChange";
import { ATVTemplateToken } from "./types";
import { atvTokenStd } from "./templates/atvTokenStd";
import styles from "./AsyncTableView.module.less";

export interface PropsAsyncTableView<TRow extends {}, TFilters extends {}> {
  store: TableStore<TRow, TFilters>;
  columns: AColumn<TRow>[];
  initialColumns?: AColumn<TRow>[]; // Используются в случае динамического изменения columns
  usePagination?: boolean;
  useHeader?: boolean;
  useSelection?: boolean;
  templateToken?: ATVTemplateToken;
  headerExtra?: React.ReactNode;
  onRowClick?(row: TRow, index?: number): void;
}

export const AsyncTableView = observer(
  <TRow extends {}, TFilters extends object>(
    props: PropsAsyncTableView<TRow, TFilters>,
  ): React.ReactElement => {
    const {
      store,
      columns,
      initialColumns,
      usePagination,
      useHeader,
      templateToken,
      headerExtra,
    } = props;

    React.useEffect(() => {
      store.init(initialColumns ?? columns);
    }, [store]);

    const { rowKey } = store;

    const pagination: PaginationProps = {
      current: store.page + 1,
      pageSize: store.pageSize,
      showSizeChanger: true,
      total: store.totalItems,
      onChange(current, pageSize) {
        onTableParamsChange(store, { current, pageSize });
      },
    };

    const finalTemplateToken = { ...atvTokenStd, ...(templateToken || {}) };
    const { item, container, header } = finalTemplateToken;

    const empty = store.rows.length === 0 && !store.loading;
    const rowsContent = empty ? (
      <div className={styles.emptyContent}>
        <Empty />
      </div>
    ) : (
      container(
        props,
        <>
          {useHeader && header(props)}
          {store.rows.map((row) => item(props, row, rowKey))}
        </>,
      )
    );
    if (store.loading)
      return (
        <div className={styles.spinBox}>
          <Spin />
        </div>
      );

    return (
      <div className={styles.tableLayout}>
        {headerExtra}
        <div className={styles.tableBox}>{rowsContent}</div>
        {usePagination && !empty && (
          <div className={styles.pagination}>
            <Pagination {...pagination} />
          </div>
        )}
      </div>
    );
  },
);
