import { Alert, Button } from "antd";
import * as React from "react";
import { Link } from "react-router-dom";
import { appStore } from "src/appStore";
import { PageUrl } from "src/routes/PageUrl";
import { makeUrl } from "src/routes/makeUrl";
import { RemoteData } from "src/common/RemoteData";
import axios from "axios";
import { getErrorMessage } from "src/common/onError";
import styles from "./HomePage.module.less";

export const HomePage: React.FC = () => {
  const [testInfo, setTestInfo] = React.useState<RemoteData<unknown>>({
    status: "none",
  });
  const testRequest = () => {
    setTestInfo({ status: "wait" });
    axios
      .get("/api/category")
      .then((result) => setTestInfo({ status: "ready", result }))
      .catch((error) => setTestInfo({ status: "error", error }));
  };
  return (
    <div className={styles.box}>
      <h1>Главная страница</h1>
      <div>Пользователь: {appStore.userInfo.userName}</div>
      <div>
        <Link to={makeUrl(PageUrl.categories)}>Справочник категорий</Link>
      </div>
      <div>
        <Link to={makeUrl(PageUrl.testTable)}>Тестовая таблица</Link>
      </div>
      <div>
        <Link to={makeUrl(PageUrl.modelConstructor)}>Демо</Link>
      </div>
      <div>
        <Link to={makeUrl(PageUrl.entities, { objectId: "" })}>
          список сущностей
        </Link>
      </div>

      <div>
        <Button onClick={() => appStore.logout()}>Logout</Button>
      </div>
      <div>
        <Button onClick={testRequest} loading={testInfo.status === "wait"}>
          Тестовый запрос
        </Button>
        {testInfo.status === "error" && (
          <Alert {...getErrorMessage(testInfo.error)} />
        )}
        {testInfo.status === "ready" && (
          <pre>{JSON.stringify(testInfo.result, null, "  ")}</pre>
        )}
      </div>
    </div>
  );
};
