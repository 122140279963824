/**
 * Пользователи системы.
 * Person потому что User - это тот пользователь, который запустил приложение.
 */

import { z } from "zod";

export const zPersonRow = z.object({
  id: z.string(),
  login: z.string(),
  roleIds: z.number().array(),
});

export type ZPersonRow = z.infer<typeof zPersonRow>;

export const zPersonListResponse = z.object({
  totalElements: z.number(),
  content: zPersonRow.array(),
});
