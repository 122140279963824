import {
  FormBlockItem,
  ItemProps,
  blockItem,
} from "src/components/FormWithBlocks";
import { readOnlyInput } from "src/components/FormWithBlocks/items";
import { ZAttribute } from "src/types/ZAttribute";
import { ObjectRefSelector } from "src/components/ObjectRefSelector/ObjectRefSelector";
import { AttrTypeName } from "src/types/AttrType";
import {
  ZAttrEditInfo,
  cvtItemProps,
  editorsByType,
  getEditorInfo,
  zAttrComponentEditor,
} from "src/common/attrEdit";
import {
  makeInputNumberProps,
  makeInputProps,
  makeObjectRefSelectorProps,
  makeObjectRefTableProps,
  makeSelectProps,
  makeTextAreaProps,
  makeUploaderProps,
} from "src/common/attrEdit/components";
import { Permission, hasPermissionIn } from "src/types/Permission";
import { makeMaskedInputProps } from "src/common/attrEdit/components/ZAttrMaskedInput";
import { PersonSelect } from "src/components/PersonSelect";
import { makeAttrPersonSelectProps } from "src/common/attrEdit/components/ZAttrPersonSelect";
import { getViewInfo } from "src/common/attrView";
import { ChildEntities } from "src/components/ChildEntities/ChildEntities";
import { makeChildEntitiesProps } from "src/common/attrEdit/components/ZChildEntities";
import { ObjectRefTable } from "src/components/ObjectRefTable/ObjectRefTable";
import { buildUploader } from "./buildUploader";
import { buildReferenceItem } from "./buildReferenceItem";
import { fieldLabel, fieldName, fieldProps } from "./fieldName";
import { BuilderCtx } from "./BuilderCtx";
import { EntityInput } from "../fieldComponents/EntityInput";
import { EntityCheckbock } from "../fieldComponents/EntityCheckbox";
import { EntitySwitch } from "../fieldComponents/EntitySwitch";
import { EntityDatePicker } from "../fieldComponents/EntityDatePicker";
import {
  EntityInputNumber,
  PropsEntityInputNumber,
} from "../fieldComponents/EntityInputNumber";
import { EntityMaskedInput } from "../fieldComponents/EntityMaskedInput";
import { EntityTextArea } from "../fieldComponents/EntityTextArea";
import { EntityTimePicker } from "../fieldComponents/EntityTimePicker";

export const createItem2 = (attr: ZAttribute, ctx: BuilderCtx): FormBlockItem =>
  createItemOpt(attr, ctx) ??
  readOnlyInput(fieldName(attr), fieldLabel(attr), {}, {});

export const getComponentEditor = (
  editorInfo: ZAttrEditInfo | undefined,
  typesMap: Record<number, string>,
  attr: ZAttribute,
) => {
  let component = editorInfo?.component;
  if (!component) {
    const typeName = typesMap[attr.valueType];
    const list = editorsByType[typeName as AttrTypeName];
    const editor = list?.[0];
    if (editor) {
      try {
        component = zAttrComponentEditor.parse({ editor });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e); // для отладки
      }
    }
  }
  return component;
};

const createItemOpt = (
  attr: ZAttribute,
  ctx: BuilderCtx,
): FormBlockItem | undefined => {
  const { name, label } = fieldProps(attr);
  const editorInfo = getEditorInfo(attr.viewStyles);
  const viewInfo = getViewInfo(attr.viewType);
  const canUpdate =
    ctx.canUpdate && hasPermissionIn(attr, Permission.attrUpdate);
  const disabled = !canUpdate;

  // Дефолтные компоненты должны уметь себя рисовать без какой-либо дополнительной информации
  // То есть, у них не должно быть required-полей.

  const component = getComponentEditor(editorInfo, ctx.typesMap, attr);
  const srcItemProps = editorInfo?.itemProps ?? {};
  const dstItemProps = cvtItemProps(
    srcItemProps,
    ctx.typesMap[attr.valueType] as AttrTypeName,
  );

  if (!component) return undefined;
  // Не удалось приспособить словарь. Похоже что switch - единственный способ в данном случае
  // https://www.typescriptlang.org/docs/handbook/2/narrowing.html#discriminated-unions

  switch (component.editor) {
    case "Checkbox":
      return blockItem(
        name,
        " ",
        EntityCheckbock,
        { label, disabled },
        dstItemProps,
      );
    case "Switch":
      return blockItem(name, label, EntitySwitch, { disabled }, dstItemProps);
    case "Select": {
      const isMulti = ctx.typesMap[attr.valueType] === AttrTypeName.dictMulti;
      return buildReferenceItem(attr, isMulti, dstItemProps, {
        ...makeSelectProps(component, isMulti),
        disabled,
      });
    }
    case "DatePicker":
      return blockItem(
        name,
        label,
        EntityDatePicker,
        {
          showTime: ctx.typesMap[attr.valueType] === AttrTypeName.dateTime,
          disabled,
        },
        dstItemProps,
      );

    case "InputNumber": {
      const compProps = makeInputNumberProps(component);
      if (ctx.typesMap[attr.valueType] === AttrTypeName.int)
        compProps.precision = 0;
      return buildNumber(name, label, dstItemProps, {
        ...compProps,
        disabled,
      });
    }

    case "Input":
      return blockItem(
        name,
        label,
        EntityInput,
        {
          allowClear: true,
          ...makeInputProps(component),
          disabled,
        },
        dstItemProps,
      );

    case "MaskedInput":
      return blockItem(
        name,
        label,
        EntityMaskedInput,
        {
          ...makeMaskedInputProps(component),
          disabled,
        },
        dstItemProps,
      );

    case "TextArea":
      return blockItem(
        name,
        label,
        EntityTextArea,
        { ...makeTextAreaProps(component), disabled },
        dstItemProps,
      );

    case "ObectRefSelector":
      return blockItem(
        name,
        label,
        ObjectRefSelector,
        {
          ...makeObjectRefSelectorProps(component, attr, viewInfo),
          disabled,
          label,
        },
        dstItemProps,
      );
    case "ObjectRefTable":
      return blockItem(
        name,
        label,
        ObjectRefTable,
        {
          ...makeObjectRefTableProps(attr, component, viewInfo),
          disabled,
          label,
        },
        dstItemProps,
      );
    case "TimePicker":
      return blockItem(
        name,
        label,
        EntityTimePicker,
        { disabled },
        dstItemProps,
      );
    case "Uploader":
      return buildUploader(
        attr,
        ctx.typesMap[attr.valueType] || "",
        dstItemProps,
        makeUploaderProps(component),
        disabled,
      );
    case "PersonSelect":
      return blockItem(
        name,
        label,
        PersonSelect,
        {
          allowClear: true,
          ...makeAttrPersonSelectProps(component),
          attr,
          disabled,
        },
        dstItemProps,
      );
    case "ChildEntities":
      return blockItem(
        name,
        label,
        ChildEntities,
        { ...makeChildEntitiesProps(attr, component), disabled },
        dstItemProps,
      );
    default:
      return undefined;
  }
};

export const buildNumber = (
  name: string,
  label: string,
  itemProps: ItemProps,
  compProps?: PropsEntityInputNumber,
) =>
  blockItem(
    name,
    label,
    EntityInputNumber,
    { style: { width: "100%" }, ...compProps },
    itemProps,
  );
