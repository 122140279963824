import * as React from "react";
import { observer } from "mobx-react-lite";
import { AddButton, LayoutWithSider } from "src/components/LayoutWithSider";
import { LoaderBox } from "src/components/LoaderBox";
import { VerticalLayout } from "src/components/VerticalLayout";
import { ModelessForm } from "src/components/ModelessForm";
import { ModalDelete } from "src/components/ModalDelete";
import { Empty } from "antd";
import { RolesTabStore } from "./RolesTabStore";
import { RolesTree } from "./RolesTree";
import { visitRoleNode } from "./nodes/visitRoleNode";
import { RoleGroupFields } from "./forms/RoleGroupFields";
import { RoleObjectField } from "./forms/RoleObjectField";
import { RoleAttrField } from "./forms/RoleAttrField";

interface PropsRolesTab {
  store: RolesTabStore;
}

export const RolesTab: React.FC<PropsRolesTab> = observer(({ store }) => {
  React.useEffect(() => {
    store.init();
  }, []);
  return (
    <LayoutWithSider
      siderHeader={
        <AddButton
          text="Добавить группу"
          id="addRoleGroup"
          onClick={() => store.safeAddGroup()}
        />
      }
      siderContent={<RolesTree store={store} />}
    >
      <VerticalLayout padding>
        <LoaderBox
          remoteData={store.data}
          drawReady={() => <MainPart store={store} />}
        />
      </VerticalLayout>
    </LayoutWithSider>
  );
});

const MainPart: React.FC<PropsRolesTab> = observer(({ store }) => {
  const { curNode } = store;
  if (!curNode)
    return (
      <Empty
        description="Ничего не выбрано"
        image={Empty.PRESENTED_IMAGE_SIMPLE}
      />
    );
  return (
    <>
      <ModelessForm store={store.formStore} submit="Сохранить" name="curItem">
        {visitRoleNode<React.ReactNode>(curNode, {
          roleGroup: (it) => <RoleGroupFields node={it} store={store} />,
          roleObject: (it) => <RoleObjectField node={it} store={store} />,
          attr: (it) => <RoleAttrField node={it} store={store} />,
          default: () => <div>Неизвестный тип</div>,
        })}
      </ModelessForm>
      <ModalDelete
        open={!!store.msgDelete}
        message={store.msgDelete}
        waiting={store.deletingWait}
        onOk={() => store.unsafeDeleteCurNode()}
        onCancel={() => store.setMsgDelete("")}
      />
    </>
  );
});
