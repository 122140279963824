import * as React from "react";
import { Button, Form, Input, InputRef, Radio, Select, Space } from "antd";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import { delay } from "src/common/delay";
import { observer } from "mobx-react-lite";
import { trimRequired } from "src/common/validationRules/trimRequired";
import {
  ZIconName,
  ZViewType,
  internalMenuLinkUrls,
  viewType,
} from "src/components/MainFrame/LeftMenu/ZMenuItem";
import z from "zod";
import { iconsMap } from "src/components/MainFrame/LeftMenu/iconsMap";
import { t } from "i18next";
import { Menu2TabStore } from "./Menu2TabStore";
import { FieldsWithTools } from "../FieldsWithTools";
import styles from "./Menu2Tab.module.less";

const viewTypeLabels: Record<ZViewType, string> = {
  bigGroup: "Группа сервисов",
  bigLink: "Сервис (1-ый уровень)",
  smallGroup: "Раздел сервиса (2-ой уровень)",
  smallLink: "Ссылка на раздел/сервис (пункт меню 2-ого уровня)",
};

export const linkType = ["text", "object", "internal", "external"] as const;
export const zLinkType = z.enum(linkType);
export type ZLinkType = z.infer<typeof zLinkType>;
const linkTypeLabels: Record<ZLinkType, string> = {
  text: "Без ссылки",
  object: "Ссылка на объект",
  internal: "Внутренняя ссылка",
  external: "Внешняя ссылка",
};

interface PropsMenuItemFields {
  store: Menu2TabStore;
}

export const MenuItemFields: React.FC<PropsMenuItemFields> = observer(
  ({ store }) => {
    const ref1 = React.useRef<InputRef>(null);
    const curViewType: ZViewType | null = Form.useWatch("viewType");
    const curLinkType: ZLinkType | null = Form.useWatch(["link", "type"]);

    React.useEffect(() => {
      delay(10).then(() => ref1.current?.focus());
    }, [store.curSelect]);

    const objectFilterOption = (
      input: string,
      option?: { label: string; value: number },
    ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

    return (
      <FieldsWithTools
        tools={
          <>
            <Button
              icon={<PlusOutlined />}
              onClick={() => store.safeCreateMenuItem(true)}
              disabled={store.isNew ?? false}
            >
              Добавить подпункт
            </Button>

            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={() => {
                store.startDeleteCurNode();
              }}
            >
              {t("Delete")}
            </Button>
          </>
        }
      >
        <Form.Item name="name" label="Название" rules={[trimRequired()]}>
          <Input ref={ref1} allowClear />
        </Form.Item>
        <Form.Item
          name="viewType"
          label="Вид отображения"
          rules={[{ required: true }]}
        >
          <Radio.Group
            className={styles.radioGroup}
            options={viewType.map((vt) => ({
              value: vt,
              label: viewTypeLabels[vt],
            }))}
          />
        </Form.Item>

        {curViewType === "bigLink" && (
          <Form.Item name="iconName" label="Иконка">
            <Select
              className={styles.iconSelect}
              options={Object.keys(iconsMap).map((icon) => ({
                value: icon,
                label: (
                  <Space className={styles.iconOption}>
                    {iconsMap[icon as ZIconName]} {icon}
                  </Space>
                ),
              }))}
              showSearch
            />
          </Form.Item>
        )}

        <Form.Item
          name={["link", "type"]}
          label="Ссылка"
          rules={[{ required: true }]}
        >
          <Radio.Group
            className={styles.radioGroup}
            options={linkType.map((lt) => ({
              value: lt,
              label: linkTypeLabels[lt],
            }))}
          />
        </Form.Item>

        {curLinkType === "object" && (
          <Form.Item name={["link", "objectId"]} rules={[{ required: true }]}>
            <Select
              options={store.objectData.map(({ name, id }) => ({
                value: id,
                label: name,
              }))}
              showSearch
              filterOption={objectFilterOption}
            />
          </Form.Item>
        )}

        {curLinkType === "internal" && (
          <Form.Item name={["link", "url"]} rules={[{ required: true }]}>
            <Select
              options={internalMenuLinkUrls.map((url) => ({
                value: url,
                label: url,
              }))}
              showSearch
            />
          </Form.Item>
        )}

        {curLinkType === "external" && (
          <Form.Item
            name={["link", "href"]}
            rules={[
              {
                required: true,
                pattern: /^https?:\/\//,
                message: "Ссылка должна начинаться с http:// или https://",
              },
            ]}
          >
            <Input allowClear />
          </Form.Item>
        )}
      </FieldsWithTools>
    );
  },
);
