import { z } from "zod";

export const zAttrSelect = z.object({
  editor: z.literal("Select"),
  maxCount: z.number().nullable().optional(), // Для мультивыбора
});

export type ZAttrSelect = z.infer<typeof zAttrSelect>;

export type AttrSelectProps = {
  maxCount?: number;
  disabled?: boolean;
};

export const makeSelectProps = (
  src: ZAttrSelect,
  isMulti: boolean,
): AttrSelectProps => ({
  maxCount: isMulti ? src.maxCount ?? undefined : undefined,
});
