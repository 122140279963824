import { ZAttributeValue, zAttribute } from "src/types/ZAttribute";
import { rest } from "./rest";
import { apiObjUrl } from "./apiUrl";

const refCache: Record<number, ZAttributeValue[]> = {};
export const loadRefDict = async (
  refId: number,
): Promise<ZAttributeValue[]> => {
  const cacheValue = refCache[refId];
  if (cacheValue) return cacheValue;
  const resp = await rest.get(apiObjUrl(`/attributes/${refId}`));
  const refValues = zAttribute.parse(resp.data).values || [];
  refCache[refId] = refValues;
  return refValues;
};
