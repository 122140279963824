// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-FieldsWithTools-FieldsWithTools-module__layout--kU2KL {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.src-pages-ManagementPage-FieldsWithTools-FieldsWithTools-module__tools--wEWwS {
  display: flex;
  flex-direction: row;
  justify-content: end;
  gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/FieldsWithTools/FieldsWithTools.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AACJ;AACA;EACI,aAAA;EACA,mBAAA;EACA,oBAAA;EACA,SAAA;AACJ","sourcesContent":[".layout {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n.tools {\n    display: flex;\n    flex-direction: row;\n    justify-content: end;\n    gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `src-pages-ManagementPage-FieldsWithTools-FieldsWithTools-module__layout--kU2KL`,
	"tools": `src-pages-ManagementPage-FieldsWithTools-FieldsWithTools-module__tools--wEWwS`
};
export default ___CSS_LOADER_EXPORT___;
