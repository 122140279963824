import * as React from "react";
import { observer } from "mobx-react-lite";
import {
  FormBlockDef,
  FormWithBlockStore,
  FormWithBlocks,
} from "src/components/FormWithBlocks";
import { Button, Form, notification, FormInstance, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { onError } from "src/common/onError";
import { SizeType } from "antd/es/config-provider/SizeContext";
import { t } from "i18next";
import { entityCardFormName } from "../EntityCardStore";
import { EdCardValues } from "../apiEntityCard";
import { ZEntityAvailableState } from "../EntityPage.types";

interface PropsEntityForm {
  formStore: FormWithBlockStore;
  block: FormBlockDef;
  submitText: string;
  initialData: EdCardValues;
  onSubmit(values: EdCardValues): Promise<EdCardValues>;
  onSuccess(values: EdCardValues): void;
  disabled: boolean;
  availableStates: ZEntityAvailableState[] | undefined;
  onChangeState?(stateId: number | string, form: FormInstance): Promise<void>;
  isBuzy: boolean;
  onDelete?(): Promise<void>;
}

/**
 * @deprecated Вместо него теперь используется EntityFormWithAnchor2
 * Однако, EntityForm всё ещё используется для превью при изменении порядка атрибутов.
 * Там как раз не нужно показывать поля в пределах видимой области
 */
export const EntityForm: React.FC<PropsEntityForm> = observer((props) => {
  const {
    formStore,
    block,
    submitText,
    onSubmit,
    onSuccess,
    initialData,
    disabled,
    availableStates,
    onChangeState,
    isBuzy,
    onDelete,
  } = props;
  const [debugValues, setDebugValues] = React.useState<object>({});
  const isDebug = window.location.hostname.startsWith("-localhost");
  React.useEffect(() => {
    setDebugValues(initialData);
  }, [initialData]);

  const [form] = Form.useForm();
  const buttonsSize: SizeType = "middle";

  const extraButtons = (
    <>
      {availableStates?.map(({ stateId, workflowName }) => (
        <Button
          key={stateId}
          id={`switchToState${stateId}`}
          onClick={() =>
            onChangeState?.(stateId, form)
              .then(() =>
                notification.success({
                  message: "Выполнено изменение состояния",
                }),
              )
              .catch(onError)
          }
          disabled={isBuzy}
          size={buttonsSize}
        >
          {workflowName}
        </Button>
      ))}
      {!!onDelete && (
        <Button
          id="deleteCurEntity"
          icon={<DeleteOutlined />}
          onClick={() => {
            Modal.confirm({
              title: t("Attention!"),
              content: t("Do you want to delete the current entry?"),
              okButtonProps: { danger: true, id: "deleteCurEntityOk" },
              cancelButtonProps: { id: "deleteCurEntityCancel" },
              onOk: () => onDelete(),
            });
          }}
          danger
          size={buttonsSize}
        >
          {t("Delete")}
        </Button>
      )}
    </>
  );
  return (
    <div>
      <FormWithBlocks<EdCardValues>
        form={form}
        name={entityCardFormName}
        initialData={initialData}
        block={block}
        store={formStore}
        submit={onSubmit}
        submitText={submitText}
        onSuccess={onSuccess}
        onValuesChange={(_, values) => setDebugValues(values)}
        disabled={disabled}
        buttonsSize={buttonsSize}
        extraButtons={extraButtons}
      />
      {isDebug && (
        <>
          <div style={{ marginTop: 32 }}>Содержимое формы (для отладки)</div>
          <pre>{JSON.stringify(debugValues, null, "  ")}</pre>
        </>
      )}
    </div>
  );
});
