/* eslint-disable class-methods-use-this */
/* eslint-disable max-classes-per-file */
import { z } from "zod";
import { makeAutoObservable } from "mobx";
import {
  canBeHidden,
  ColumnKey,
  ColumnPoor,
} from "src/components/tables/ColumnPoor";
import { notification } from "antd";
import { debounce, DebounceCounter } from "src/common/debounce";
import { getErrorMessage } from "src/common/onError";
import { FilterSettingsStore } from "./FiltersForm";

const applySortingStd = (
  store: CtrlSort,
  columns: ColumnPoor[],
): ColumnPoor[] => {
  const { sort, sortOrder } = store;
  return columns.map((col) => (col.key === sort ? { ...col, sortOrder } : col));
};

export const applyOrderStd = (
  store: CtrlColumns,
  columns: ColumnPoor[],
): ColumnPoor[] => {
  const orderMap = new Map<string, number>(
    store.columnOrder.map((key, index) => [key, index]),
  );

  return columns.slice().sort((a, b) => {
    const orderA = orderMap.get(a.key) ?? Number.MAX_SAFE_INTEGER;
    const orderB = orderMap.get(b.key) ?? Number.MAX_SAFE_INTEGER;
    return orderA - orderB;
  });
};

export type SortOrder = "ascend" | "descend"; // совместимо с ant
export type SortKey = string;

export interface TableLoadParams<TFilters extends object> {
  page: number; // zero based
  pageSize: number;
  sort?: SortKey;
  sortOrder?: SortOrder;
  filters?: TFilters;
}

export interface TableResponse<TRow> {
  readonly rows: TRow[];
  readonly totalItems: number;
}

export type FnLoad<TRow, TFilters extends object> = (
  params: TableLoadParams<TFilters>,
) => Promise<TableResponse<TRow>>;

export const stubLoader = async () => ({ totalItems: 0, rows: [] });

export interface CtrlResizable {
  readonly columnWidths: Record<string, number | string>;
  readonly resizableColumns: boolean;
  setColumnWidth(key: string, width?: number | string): void;
}

export interface CtrlFiltration<TFilters extends object> {
  readonly filters: TFilters | undefined;
  readonly initialFilters: TFilters;
  readonly filterSettings: FilterSettingsStore | null;
  reload(): Promise<void>;
  setFilters(newFilters: TFilters): void;
}

export interface CtrlPagination {
  readonly page: number;
  setPage(newPage: number): void;
  readonly pageSize: number;
  setPageSize(newPageSize: number): void;
  readonly totalItems: number;
}

export interface CtrlSort {
  readonly sort?: SortKey;
  readonly sortOrder?: SortOrder;
  setSorting(sort?: SortKey, sortOrder?: SortOrder): void;
}

export interface CtrlColumns {
  readonly columns: ColumnPoor[];
  readonly finalColumns: ColumnPoor[];
  readonly columnOrder: string[];
  isColumnVisible(key: ColumnKey): boolean;
  setColumnVisible(key: ColumnKey, visible: boolean): void;
  canColumnHide(key: ColumnKey): boolean;
  setColumnOrder(newOrder: string[]): void;
}
export interface CtrlRows<TRow extends {}> {
  readonly rows: TRow[];
}
export interface CtrlSelection<TRow extends {}> {
  readonly rowKey: keyof TRow;
  readonly selectionSettings: SelectionSetings<TRow>;
  readonly selected: TRow[];
  readonly rows: TRow[];
  setSelected(rows: TRow[]): void;
  safeSelect(rows: TRow[]): void;
}

const zSettings = z.object({
  hiddenColumns: z.string().array(),
  pageSize: z.optional(z.number()),
  resizableColumns: z.optional(z.boolean()),
  columnWidths: z.record(z.string(), z.number().or(z.string())).optional(),
  columnOrder: z.array(z.string()).optional(),
});
type Settings = z.infer<typeof zSettings>;

export type SelectionSetings<TRow> = {
  keepSelected?: boolean;
  onSelect?(rows: TRow[]): void;
  selectionType?: "checkbox" | "radio";
};

interface ParamsTableStore<TRow, TFilters extends object> {
  rowKey: keyof TRow;
  fnLoad: FnLoad<TRow, TFilters>;
  initialParams?: Partial<TableLoadParams<TFilters>>;
  settingsKey?: string;
  selectionSettings?: SelectionSetings<TRow>;
  onLoad?(): void;
  filterSettingsKey?: string;
  fnIsSelectionDisabled?: (row: TRow) => boolean;
  resizableColumns?: boolean;
}

export class TableStore<TRow, TFilters extends object>
  implements CtrlFiltration<TFilters>, CtrlPagination, CtrlColumns, CtrlSort
{
  // Это поле появилось не сразу, т.к. изначально было меньше функциональности и не хотелось перегружать код.
  // Но потом появилась достаточно сложная логика для selected. Там без ключей всё стало работать неправильно.
  rowKey: keyof TRow;

  fnLoad: FnLoad<TRow, TFilters>;

  // ключ для хранения настроек столбцов в localStorage
  readonly settingsKey?: string;

  readonly initialFilters: TFilters;

  readonly selectionSettings: SelectionSetings<TRow>;

  private onLoad?: () => void;

  readonly filterSettings: FilterSettingsStore | null;

  fnIsSelectionDisabled: ((row: TRow) => boolean) | null = null;

  resizableColumns: boolean;

  columnWidths: Record<string, number | string> = {};

  setColumnWidth(key: string, width: number | string) {
    this.columnWidths[key] = width;
    this.saveSettings();
  }

  constructor({
    rowKey,
    fnLoad,
    initialParams,
    settingsKey,
    selectionSettings,
    onLoad,
    filterSettingsKey,
    fnIsSelectionDisabled,
    resizableColumns,
  }: ParamsTableStore<TRow, TFilters>) {
    this.rowKey = rowKey;
    this.selectionSettings = selectionSettings ?? {
      keepSelected: false,
      onSelect: undefined,
      selectionType: "checkbox",
    };
    this.fnLoad = fnLoad;
    this.settingsKey = settingsKey;
    this.onLoad = onLoad;
    this.fnIsSelectionDisabled = fnIsSelectionDisabled ?? null;
    this.params = {
      page: 0,
      pageSize: 10,
      ...initialParams,
    };
    this.initialFilters = initialParams?.filters ?? ({} as TFilters);
    this.result = {
      rows: [],
      totalItems: 0,
    };
    this.filterSettings = filterSettingsKey
      ? new FilterSettingsStore(filterSettingsKey)
      : null;
    this.resizableColumns = resizableColumns ?? false;
    makeAutoObservable(this);
  }

  params: TableLoadParams<TFilters>;

  setParams(newParams: TableLoadParams<TFilters>) {
    this.params = newParams;
  }

  result: TableResponse<TRow>;

  setResult(newResult: TableResponse<TRow>) {
    this.result = newResult;
    this.onLoad?.();
  }

  get rows(): TRow[] {
    return this.result.rows;
  }

  loading = false;

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  columns: ColumnPoor[] = [];

  setColumns(newColumns: ColumnPoor[]) {
    this.columns = [...newColumns];
  }

  columnOrder: string[] = [];

  setColumnOrder(newOrder: string[]) {
    this.columnOrder = newOrder;
    this.saveSettings();
  }

  // Иногда список колонок формируется внутри компонента таблицы,
  // т.к. используется render ячеек, зависящий от внутреннего состояния.
  // Поэтому список колонок приходит при монтировании компонента таблицы.
  async init(columns: ColumnPoor[]) {
    const checkParams = () => {
      // Нужно избежать указания сортировки по тем столбцам, которые этого не поддерживают (SRMDEV-2106)
      const { sort } = this.params;
      type ColumnSort = ColumnPoor & { sorter?: boolean };
      if (sort) {
        const sortCol = columns.find(({ key }) => key === sort);
        let needToClear = false;
        const warn = (shortMsg: string) => {
          const message = `${shortMsg}: ${sort}`;
          // eslint-disable-next-line no-console
          console.warn(message);
          notification.warning({ message });
          needToClear = true;
        };
        if (!sortCol) {
          warn("Указана сортировка по несуществующему столбцу");
        } else if (!(sortCol as ColumnSort).sorter) {
          warn("Указанная колонка не поддерживает сортировку");
        }
        if (needToClear) {
          this.setParams({ ...this.params, sort: undefined });
        }
      }
    };
    checkParams();

    if (!this.selectionSettings.keepSelected) this.setSelected([]);
    this.setColumns(columns);
    this.loadSettings();
    await this.reload();
  }

  clear() {
    this.setSelected([]);
    this.setResult({ totalItems: 0, rows: [] });
  }

  private visibleFiltersOnly(srcFilters: TFilters): TFilters {
    const { filterSettings } = this;
    if (!filterSettings) return srcFilters;
    return Object.keys(srcFilters).reduce(
      (dstFilters, key) =>
        filterSettings.isVisible(key)
          ? { ...dstFilters, [key]: srcFilters[key as keyof TFilters] }
          : dstFilters,
      {} as TFilters,
    );
  }

  async reload() {
    return this.load(this.params);
  }

  async load(params: Partial<TableLoadParams<TFilters>>) {
    const newParams = {
      ...this.params,
      ...params,
      filters: { ...this.initialFilters, ...params.filters },
    };
    const requestParams = {
      ...newParams,
      filters: this.visibleFiltersOnly(newParams.filters),
    };

    this.setLoading(true);
    this.fnLoad(requestParams)
      .then((response) => {
        this.setParams(newParams);
        this.updateSelected(response.rows);
        this.setResult(response);
        this.setLoading(false);
      })
      .catch((e) => notification.error(getErrorMessage(e)))
      .finally(() => this.setLoading(false));
  }

  updateCounter: DebounceCounter = {};

  updateParams: Partial<TableLoadParams<TFilters>> = {};

  update(params: Partial<TableLoadParams<TFilters>>) {
    this.updateParams = { ...this.params, ...this.updateParams, ...params };
    debounce(this.updateCounter, 200, () => {
      this.load(this.updateParams);
      this.clearUpdateParams();
    });
  }

  clearUpdateParams() {
    this.updateParams = {};
  }

  get sort(): SortKey | undefined {
    return this.params.sort;
  }

  get sortOrder(): SortOrder | undefined {
    return this.params.sortOrder;
  }

  setSorting(sortField?: SortKey, sortOrder?: SortOrder) {
    this.update({ sort: sortField, sortOrder });
  }

  loadSettings() {
    let settings: Settings = {
      hiddenColumns: this.columns
        .filter(({ visibility }) => visibility === "defaultHidden")
        .map(({ key }) => key),
      resizableColumns: this.resizableColumns,
      columnWidths: {},
      columnOrder: [],
    };
    if (this.settingsKey) {
      try {
        const text = localStorage.getItem(this.settingsKey);
        if (text) {
          const json = JSON.parse(text);
          settings = zSettings.parse(json);
        }
      } catch (e) {
        notification.error({
          message: "Ошибка загрузки настроек таблицы",
          description: e.message,
        });
      }
    }
    this.invisibleColumnsKeys = new Set(settings.hiddenColumns);
    if (settings.pageSize) this.params.pageSize = settings.pageSize;
    this.resizableColumns = settings.resizableColumns ?? false;
    this.columnWidths = settings.columnWidths || {};
    this.columnOrder = settings.columnOrder || [];
  }

  saveSettings() {
    if (!this.settingsKey) return;
    try {
      const settings: Settings = {
        hiddenColumns: Array.from(this.invisibleColumnsKeys),
        pageSize: this.pageSize,
        resizableColumns: this.resizableColumns,
        columnWidths: this.columnWidths,
        columnOrder: this.columnOrder,
      };
      localStorage.setItem(this.settingsKey, JSON.stringify(settings));
    } catch (e) {
      notification.error({
        message: "Ошибка сохранения настроек таблицы",
        description: e.message,
      });
    }
  }

  // implementation of ColumnsVisibility
  invisibleColumnsKeys = new Set<ColumnKey>();

  get finalColumns(): ColumnPoor[] {
    const sortedColumns = applyOrderStd(this, this.columns);
    const resized = sortedColumns.map((col) => ({
      ...col,
      width: this.columnWidths[col.key] || col.width,
    }));
    const visible = resized.filter(({ key }) => this.isColumnVisible(key));
    return applySortingStd(this, visible);
  }

  isColumnVisible(key: ColumnKey): boolean {
    return !this.invisibleColumnsKeys.has(key);
  }

  setColumnVisible(colKey: ColumnKey, visible: boolean) {
    const column = this.columns.find(({ key }) => colKey === key);
    if (!column || !canBeHidden(column)) return;
    if (visible) {
      this.invisibleColumnsKeys.delete(colKey);
    } else {
      this.invisibleColumnsKeys.add(colKey);
    }
    this.saveSettings();
  }

  canColumnHide(colKey: ColumnKey): boolean {
    const column = this.columns.find(({ key }) => colKey === key);
    if (!column || !canBeHidden(column)) return false;
    // нельзя скрывать последний видимый столбец
    return !(
      this.isColumnVisible(colKey) &&
      this.invisibleColumnsKeys.size + 1 === this.columns.length
    );
  }

  // implementation of Pagination
  get page(): number {
    return this.params.page;
  }

  setPage(page: number) {
    if (page !== this.page) this.update({ page });
  }

  get pageSize(): number {
    return this.params.pageSize;
  }

  get totalItems(): number {
    return this.result.totalItems;
  }

  setPageSize(pageSize: number) {
    if (pageSize !== this.pageSize) {
      this.params.pageSize = pageSize;
      this.saveSettings();
      this.update({ pageSize, page: 0 });
    }
  }

  // implementation of Filtration
  get filters(): TFilters | undefined {
    return this.params.filters;
  }

  setFilters(filters: TFilters, forced?: boolean) {
    if (forced || JSON.stringify(filters) !== JSON.stringify(this.filters)) {
      // При изменении фильтров перезод на первую страницу
      this.update({ filters, page: 0 });
    }
  }

  // implementation of Selection
  selected: TRow[] = [];

  setSelected(rows: TRow[]) {
    this.selected = rows;
  }

  disabledSelections = new Set<unknown>();

  setDisabledSelections(keys: unknown[]) {
    this.disabledSelections = new Set(keys);
  }

  getDisabledSelection(
    rowKey: keyof TRow,
  ): ((row: TRow) => boolean) | undefined {
    if (this.fnIsSelectionDisabled) {
      return this.fnIsSelectionDisabled;
    }
    if (this.disabledSelections.size > 0) {
      return (row: TRow) => this.disabledSelections.has(row[rowKey]);
    }
    return undefined;
  }

  updateSelected(list: TRow[]) {
    const { rowKey } = this;
    if (!this.selectionSettings.keepSelected) {
      const oldSelected = new Set(this.selected.map((row) => row[rowKey]));
      this.safeSelect(list.filter((row) => oldSelected.has(row[rowKey])));
    }
  }

  safeSelect(list: TRow[]) {
    const { rowKey, selectionSettings } = this;
    if (
      selectionSettings.keepSelected &&
      selectionSettings.selectionType === "checkbox"
    ) {
      // Сначала нужно сохранить те строки, которые не относятся к текущей странице
      const curPageRowsSet = new Set(this.rows.map((row) => row[rowKey]));
      const outPage = this.selected.filter(
        (row) => !curPageRowsSet.has(row[rowKey]),
      );
      // Теперь совокупность выделенных строк получается из входного списка плюс те, которые не с этой страницы
      this.setSelected([...outPage, ...list]);
    } else {
      this.setSelected(list);
    }
  }
}
