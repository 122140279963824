/**
 * Элемент для редактирования одной фразы на нескольких языках.
 * Должен быть частью формы Form
 */
import * as React from "react";
import { Form, Input } from "antd";
import { appStore } from "src/appStore";
import { classNames } from "src/common/classNames";
import styles from "./MultiLangFields.module.less";

interface PropsMultiLangFields {
  basePath: string[];
}

export const MultiLangFields: React.FC<PropsMultiLangFields> = (props) => {
  const { basePath } = props;
  const { contentLanguages } = appStore;
  const clsRow = (i: number): [boolean, string] => [
    i < contentLanguages.length - 1,
    styles.row,
  ];
  return (
    <div className={styles.fieldsBox}>
      {contentLanguages.map(({ code, name, nativeName }, i) => (
        <React.Fragment key={code}>
          <div
            className={classNames([styles.label, clsRow(i)])}
            title={makeTooltip(name, nativeName)}
          >
            {code.toUpperCase()}
          </div>
          <div className={classNames([styles.inputBox, clsRow(i)])}>
            <Form.Item name={[...basePath, code]} noStyle>
              <Input variant="borderless" allowClear />
            </Form.Item>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

const makeTooltip = (name: string, nativeName: string) =>
  name === nativeName ? name : `${name} (${nativeName})`;
