// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-AuthChecker-overlays-LoginPage-LoginPage-module__resetPasswordLink--djHxl {
  font-size: 14px;
  padding: 0;
}
`, "",{"version":3,"sources":["webpack://./src/components/AuthChecker/overlays/LoginPage/LoginPage.module.less"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,UAAA;AACF","sourcesContent":[".resetPasswordLink {\n  font-size: 14px;\n  padding: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"resetPasswordLink": `src-components-AuthChecker-overlays-LoginPage-LoginPage-module__resetPasswordLink--djHxl`
};
export default ___CSS_LOADER_EXPORT___;
