// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-TShapeForm-TShapeForm-module__frame--_bOn3 {
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}
.src-components-TShapeForm-TShapeForm-module__header--KHFn5 {
  padding: 12px 24px 16px 24px;
  background: #FFF;
  border-bottom: 1px solid rgba(0, 0, 0, 0.06);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
}
.src-components-TShapeForm-TShapeForm-module__title--EKQU3 {
  color: rgba(0, 0, 0, 0.85);
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}
.src-components-TShapeForm-TShapeForm-module__buttons--jj8xE {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 16px;
}
.src-components-TShapeForm-TShapeForm-module__mainPart--HadzW {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  gap: 24px;
  background: #F5F5F5;
}
.src-components-TShapeForm-TShapeForm-module__mainPart--HadzW > div {
  height: 100%;
  overflow-y: auto;
}
.src-components-TShapeForm-TShapeForm-module__leftPart--sIhcR {
  width: 214px;
  padding: 16px 0 16px 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.src-components-TShapeForm-TShapeForm-module__rightPart--mjPdL {
  flex: 1;
}
`, "",{"version":3,"sources":["webpack://./src/components/TShapeForm/TShapeForm.module.less"],"names":[],"mappings":"AAAA;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,sBAAA;AACJ;AACA;EACI,4BAAA;EACA,gBAAA;EACA,4CAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,SAAA;AACJ;AACA;EACI,0BAAA;EACA,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,iBAAA;AACJ;AACA;EACI,OAAA;EACA,aAAA;EACA,mBAAA;EACA,yBAAA;EACA,SAAA;AACJ;AAIA;EACI,OAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,SAAA;EACA,mBAAA;AAFJ;AAGI;EACI,YAAA;EACA,gBAAA;AADR;AAQA;EACI,YAAA;EACA,yBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AANJ;AAQA;EACI,OAAA;AANJ","sourcesContent":[".frame {\n    height: 100%;\n    overflow: hidden;\n    display: flex;\n    flex-direction: column;\n}\n.header {\n    padding: 12px 24px 16px 24px;\n    background: #FFF;\n    border-bottom: 1px solid rgba(0, 0, 0, 0.06);\n    display: flex;\n    flex-direction: row;\n    align-items: center;\n    gap: 16px;\n}\n.title {\n    color: rgba(0, 0, 0, 0.85);\n    font-size: 20px;\n    font-style: normal;\n    font-weight: 700;\n    line-height: 120%;\n}\n.buttons {\n    flex: 1;\n    display: flex;\n    flex-direction: row;\n    justify-content: flex-end;\n    gap: 16px;\n}\n\n@horizSpace: 24px;\n\n.mainPart {\n    flex: 1;\n    overflow: hidden;\n    display: flex;\n    flex-direction: row;\n    gap: @horizSpace;\n    background: #F5F5F5;\n    & > div {\n        height: 100%;\n        overflow-y: auto;    \n    }\n}\n\n@leftPartVertSpace: 16px;\n\n// Это дефолтные стили для левого блока. Чтобы их переопределить, нужно указать leftPartClass в пропсах компонента\n.leftPart {\n    width: 214px;\n    padding: @leftPartVertSpace 0 @leftPartVertSpace @horizSpace;\n    display: flex;\n    flex-direction: column;\n    gap: @leftPartVertSpace;\n}\n.rightPart {\n    flex: 1;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"frame": `src-components-TShapeForm-TShapeForm-module__frame--_bOn3`,
	"header": `src-components-TShapeForm-TShapeForm-module__header--KHFn5`,
	"title": `src-components-TShapeForm-TShapeForm-module__title--EKQU3`,
	"buttons": `src-components-TShapeForm-TShapeForm-module__buttons--jj8xE`,
	"mainPart": `src-components-TShapeForm-TShapeForm-module__mainPart--HadzW`,
	"leftPart": `src-components-TShapeForm-TShapeForm-module__leftPart--sIhcR`,
	"rightPart": `src-components-TShapeForm-TShapeForm-module__rightPart--mjPdL`
};
export default ___CSS_LOADER_EXPORT___;
