import { observer } from "mobx-react-lite";
import React from "react";
import { Button } from "antd";
import style from "./AttsHierarchy.module.less";

type PropsControlButtons = {
  onOk?: () => void;
  onReset?: () => void;
};

export const ControlButtons: React.FC<PropsControlButtons> = observer(
  ({ onReset, onOk }) => (
    <div className={style.controllButtons}>
      <Button
        size="large"
        onClick={() => {
          onReset?.();
        }}
      >
        сбросить
      </Button>
      <Button
        size="large"
        type="primary"
        onClick={() => {
          onOk?.();
        }}
      >
        применить
      </Button>
    </div>
  ),
);
