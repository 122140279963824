// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-PersonSelect-PersonSelect-module__selectBox--ddtm3 {
  display: flex;
  flex-direction: row;
  gap: 6px;
  align-items: center;
  justify-content: left;
  border: thin solid #D9D9D9;
  border-radius: 4px;
  height: 38px;
  width: 100%;
  padding: 7px 11px;
  background-color: white;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.88);
  cursor: pointer;
}
.src-components-PersonSelect-PersonSelect-module__selectBox--ddtm3:disabled {
  background-color: #FAFAFA;
  color: #555;
}
.src-components-PersonSelect-PersonSelect-module__selectBox--ddtm3 .src-components-PersonSelect-PersonSelect-module__text--e80Ei {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
}
.src-components-PersonSelect-PersonSelect-module__selectBox--ddtm3 .src-components-PersonSelect-PersonSelect-module__counter--lumBU {
  font-weight: bold;
}
.src-components-PersonSelect-PersonSelect-module__selectBox--ddtm3 .src-components-PersonSelect-PersonSelect-module__space--Wtgby {
  flex: 1;
}
.src-components-PersonSelect-PersonSelect-module__selectBox--ddtm3 .src-components-PersonSelect-PersonSelect-module__clear--_9jdz {
  color: rgba(0, 0, 0, 0.25);
}
.src-components-PersonSelect-PersonSelect-module__selectBox--ddtm3 .src-components-PersonSelect-PersonSelect-module__clear--_9jdz:hover {
  color: rgba(0, 0, 0, 0.5);
}
`, "",{"version":3,"sources":["webpack://./src/components/PersonSelect/PersonSelect.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;EACA,qBAAA;EAEA,0BAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,iBAAA;EACA,uBAAA;EACA,eAAA;EACA,0BAAA;EACA,eAAA;AAAJ;AACI;EACI,yBAAA;EACA,WAAA;AACR;AAnBA;EAsBQ,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;AAAR;AAzBA;EA4BQ,iBAAA;AAAR;AA5BA;EA+BQ,OAAA;AAAR;AA/BA;EAkCQ,0BAAA;AAAR;AACQ;EACI,yBAAA;AACZ","sourcesContent":[".selectBox {\n    display: flex;\n    flex-direction: row;\n    gap: 6px;\n    align-items: center;\n    justify-content: left;\n\n    border: thin solid #D9D9D9;\n    border-radius: 4px;\n    height: 38px;\n    width: 100%;\n    padding: 7px 11px;\n    background-color: white;\n    font-size: 16px;\n    color: rgba(0,0,0,0.88);\n    cursor: pointer;\n    &:disabled {\n        background-color: #FAFAFA;\n        color: #555;\n    }\n\n    .text {\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        text-align: left;\n    }\n    .counter {\n        font-weight: bold;\n    }\n    .space {\n        flex: 1;\n    }\n    .clear {\n        color: rgba(0,0,0,0.25);\n        &:hover {\n            color: rgba(0,0,0,0.5);\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectBox": `src-components-PersonSelect-PersonSelect-module__selectBox--ddtm3`,
	"text": `src-components-PersonSelect-PersonSelect-module__text--e80Ei`,
	"counter": `src-components-PersonSelect-PersonSelect-module__counter--lumBU`,
	"space": `src-components-PersonSelect-PersonSelect-module__space--Wtgby`,
	"clear": `src-components-PersonSelect-PersonSelect-module__clear--_9jdz`
};
export default ___CSS_LOADER_EXPORT___;
