import * as React from "react";
import { t } from "i18next";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { Alert, Tabs, notification } from "antd";
import { PageLayout } from "src/components/PageLayout";
import { LoaderBox } from "src/components/LoaderBox";
import { onError } from "src/common/onError";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import { breadItem } from "src/common/breadItem";
import { entityCardStore as pageStore } from "./EntityCardStore";
import { EntityFormWithAnchor2 } from "./EntityFormWithAnchor";
import styles from "./EntityCardPage.module.less";
import { EntityHistory } from "./EntityHistory";

export const EntityCardPage: React.FC = observer(() => {
  const { id } = useParams(); // URL of this page = PageUrl.entityCard
  const navigate = useNavigate();
  React.useEffect(() => {
    if (id) pageStore.init(id);
  }, [id]);
  const [curTab, setCurTab] = React.useState<string>("main");
  const { rootBlock } = pageStore;
  if (!id) return <Alert type="error" message="Не указан id сущности" />;

  const Inner: React.FC = observer(() => {
    if (!rootBlock) return null;
    const doDelete = async () => {
      try {
        await pageStore.doDelete();
        notification.success({ message: "Выполнено удаление объекта" });
        navigate(makeUrl(PageUrl.entities, { objectId: pageStore.objectId }), {
          replace: true,
        });
      } catch (e) {
        onError(e);
      }
    };
    return (
      <EntityFormWithAnchor2
        title={pageStore.entityName}
        store={pageStore}
        submitText={t("Save")}
        onSubmit={(values) => pageStore.save(values)}
        onSuccess={() => {
          notification.success({ message: "Выполнено сохранение данных" });
        }}
        disabled={!pageStore.canEntityUpdate}
        availableStates={pageStore.availableStates}
        onChangeState={(stateId, form) => pageStore.changeState(stateId, form)}
        onDelete={pageStore.availableDelete ? doDelete : undefined}
      />
    );
  });

  type CardTab = {
    label: string;
    render: () => React.ReactNode;
  };
  const tabs: Record<string, CardTab> = {
    main: {
      label: t("Main"),
      render: () => (
        <div className={styles.entityContent}>
          <LoaderBox remoteData={pageStore.info} drawReady={() => <Inner />} />
        </div>
      ),
    },
    history: {
      label: t("History of changes"),
      render: () =>
        pageStore.historyStore && (
          <div className={styles.entityContent}>
            <EntityHistory store={pageStore.historyStore} />
          </div>
        ),
    },
  };
  const tabItems = Object.entries(tabs).map(([key, { label }]) => ({
    key,
    label,
  }));

  return (
    <PageLayout
      breadcrumb={[
        breadItem(pageStore.objectName, PageUrl.entities, {
          objectId: pageStore.objectId,
        }),
        breadItem(pageStore.entityName),
      ]}
      documentTitle={pageStore.documentTitle}
    >
      <div className={styles.pageContent}>
        <Tabs
          size="middle"
          items={tabItems}
          className={styles.tabs}
          activeKey={curTab}
          onChange={(tab) => setCurTab(tab)}
        />
        <div className={styles.tabContent}>{tabs[curTab]?.render()}</div>
      </div>
    </PageLayout>
  );
});
