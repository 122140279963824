// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ObjectRefTable-ObjectRefTable-module__box--Fnc1g {
  display: grid;
  gap: 15px;
}
.src-components-ObjectRefTable-ObjectRefTable-module__selectBtn--NRNTU {
  width: 100%;
}
.src-components-ObjectRefTable-ObjectRefTable-module__drawer--m5JQm .ant-drawer-footer {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 16px 24px;
}
.src-components-ObjectRefTable-ObjectRefTable-module__drawer--m5JQm .ant-drawer-body {
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/ObjectRefTable/ObjectRefTable.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;AACF;AACA;EACE,WAAA;AACF;AAEA;EAEI,aAAA;EACA,oBAAA;EACA,mBAAA;EACA,kBAAA;AADJ;AAJA;EASI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,YAAA;AAFJ","sourcesContent":[".box {\n  display: grid;\n  gap: 15px;\n}\n.selectBtn {\n  width: 100%;\n}\n\n.drawer {\n  :global(.ant-drawer-footer) {\n    display: flex;\n    justify-content: end;\n    align-items: center;\n    padding: 16px 24px;\n  }\n\n  :global(.ant-drawer-body) {\n    display: flex;\n    flex-direction: column;\n    overflow-y: hidden;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-components-ObjectRefTable-ObjectRefTable-module__box--Fnc1g`,
	"selectBtn": `src-components-ObjectRefTable-ObjectRefTable-module__selectBtn--NRNTU`,
	"drawer": `src-components-ObjectRefTable-ObjectRefTable-module__drawer--m5JQm`
};
export default ___CSS_LOADER_EXPORT___;
