import React from "react";
import { Link } from "react-router-dom";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import styles from "./ObjectRefSelector.module.less";

interface PropsObjectRefSelectorLabel {
  label: React.JSX.Element | React.ReactNode[];
  entityId: number;
}

export const ObjectRefSelectorLabel: React.FC<PropsObjectRefSelectorLabel> = ({
  label,
  entityId,
}) => (
  <Link
    to={makeUrl(PageUrl.entityCard, { id: entityId })}
    className={styles.entityLink}
    target="_blank"
  >
    {label}
  </Link>
);
