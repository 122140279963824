// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-common-attrView-components-ObjectRef-ObjectRef-module__box--ZNJzo .ant-tag {
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/common/attrView/components/ObjectRef/ObjectRef.module.less"],"names":[],"mappings":"AAAA;EAEI,SAAA;AAAJ","sourcesContent":[".box {\n  :global(.ant-tag) {\n    margin: 0;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-common-attrView-components-ObjectRef-ObjectRef-module__box--ZNJzo`
};
export default ___CSS_LOADER_EXPORT___;
