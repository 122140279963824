// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-HomePage-HomePage-module__box--woaAM {
  display: flex;
  flex-direction: column;
  padding: 10px 20px;
  gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/HomePage/HomePage.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,kBAAA;EACA,SAAA;AACJ","sourcesContent":[".box {\n    display: flex;\n    flex-direction: column;\n    padding: 10px 20px;\n    gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-HomePage-HomePage-module__box--woaAM`
};
export default ___CSS_LOADER_EXPORT___;
