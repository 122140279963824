import { z } from "zod";
import { ZAttrMeta } from "src/types/ZAttrMeta";
import { ZAttrViewInfo } from "../zAttrViewInfo";

export const zObectRef = z.object({
  view: z.literal("ObectRef"),
  labelAtts: z.string().array().optional().nullable(),
});
export type ZObectRef = z.infer<typeof zObectRef>;

export const makeObectRefProps = (
  attrMeta: ZAttrMeta,
  cmp: ZObectRef,
  values: string[],
  viewInfo: ZAttrViewInfo | undefined,
) => ({
  labelAtts: cmp.labelAtts || [],
  values,
  viewInfo,
  objectId: attrMeta.referenceId ?? null,
});
