import React from "react";
import { Checkbox, CheckboxProps } from "antd";
import { observer } from "mobx-react-lite";
import { CtrlSelection } from "src/components/tables/TableStore";
import { onError } from "src/common/onError";
import styles from "./TableSelection.module.less";

type PropsTableSelectionCheckbox<TRow extends {}> = {
  store: CtrlSelection<TRow>;
  row?: TRow;
  main?: boolean;
};

export const TableSelectionCheckbox = observer(
  <TRow extends {}>({
    store,
    main,
    row,
  }: PropsTableSelectionCheckbox<TRow>) => {
    const { onSelect } = store.selectionSettings;
    const { rowKey } = store;
    const selectedSet = new Set(store.selected.map((r) => r[rowKey]));
    const subSequenceSelectedNum = store.rows.reduce((acc, curr) => {
      if (selectedSet.has(curr[rowKey])) {
        return acc + 1;
      }
      return acc;
    }, 0);
    const isAllSelected = subSequenceSelectedNum === store.rows.length;
    const indeterminate =
      store.selected.length !== 0 &&
      store.selected.length !== store.rows.length &&
      !isAllSelected;

    const mainHandler = (checked: boolean) => {
      store.safeSelect(checked ? store.rows : []);
      onSelect?.(store.selected);
    };

    const certainHandler = (checked: boolean) => {
      try {
        if (!row) throw new Error(`TableSelectionStd: row does not exist`);
        const onPageKeys = new Set(store.rows.map((r) => r[rowKey]));
        const newSelected = store.selected.filter((r) =>
          onPageKeys.has(r[rowKey]),
        );
        store.safeSelect(
          checked
            ? [...newSelected, row]
            : newSelected.filter((r) => r[rowKey] !== row[rowKey]),
        );
        onSelect?.(store.selected);
      } catch (error) {
        onError(error);
      }
    };

    const handleOnChange = main ? mainHandler : certainHandler;
    const checkboxProps: CheckboxProps = main
      ? { checked: isAllSelected, indeterminate }
      : {
          checked: !!store.selected.find(
            (item) => item[rowKey] === row?.[rowKey],
          ),
        };

    return (
      <Checkbox
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...checkboxProps}
        onClick={(e) => {
          e.stopPropagation();
        }}
        onChange={(checked) => {
          handleOnChange(checked.target.checked);
        }}
        className={styles.selection}
      />
    );
  },
);
