import { ZAttrViewInfo } from "src/common/attrView";
import { ZAttribute } from "src/types/ZAttribute";
import { z } from "zod";

export const zObjectRefSelector = z.object({
  editor: z.literal("ObectRefSelector"),
  multiple: z.boolean().optional(),
  labelAtts: z.string().array().optional().nullable(),
});
export type ZObjectRefSelector = z.infer<typeof zObjectRefSelector>;

export const makeObjectRefSelectorProps = (
  src: ZObjectRefSelector,
  attr: ZAttribute,
  viewInfo: ZAttrViewInfo | undefined,
) => ({
  multiple: src.multiple ?? false,
  labelAtts: src.labelAtts ?? null,
  objectId: String(attr.referenceId),
  viewInfo,
});
