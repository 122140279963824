import { ZTranslation, zTranslation } from "src/types/ZTranslation";
import { appStore } from "src/appStore";
import { rest } from "../rest";
import { apiI18nUrl } from "../apiUrl";

export const apiReadTranslations = async (
  keyPhrases: string[],
): Promise<ZTranslation[]> => {
  const data = { values: keyPhrases };
  const resp = await rest.post(apiI18nUrl("/translations/search"), data);
  return zTranslation.array().parse(resp.data);
};

/**
 * Функция работает не только на загрузку но и на создание
 * @param keyPhrase Если пустая строка, то создаются пустые варианты перевода (без обращения к серверу)
 * @returns Для общего списка языков контента обязательно будут записи (пустые если не приши в ответе). Если придут лишние, то они тоже появятся в списке
 */
export const loadTranslation = async (
  keyPhrase: string,
): Promise<ZTranslation> => {
  const { contentLanguages } = appStore;
  const result: ZTranslation = {
    value: keyPhrase,
    translations: contentLanguages.reduce(
      (acc, { code }) => ({ ...acc, [code]: "" }),
      {},
    ),
  };

  if (keyPhrase) {
    const list = await apiReadTranslations([keyPhrase]);
    if (list.length !== 1)
      throw Error(
        `Ожидалось получить один вариант перевода, а получено: ${list.length}`,
      );
    const item = list[0]!;
    Object.entries(item.translations).forEach(([langCode, localPhrase]) => {
      result.translations[langCode] = localPhrase;
    });
  }
  return result;
};

export const saveTranslation = async (
  oldPhrase: string,
  translation: ZTranslation,
): Promise<void> => {
  if (!translation.value) return;
  const data = [
    {
      ...translation,
      oldValue: oldPhrase,
    },
  ];
  await rest.post(apiI18nUrl("/translations/save"), data);
};
