import { AttrViewStylesNames } from "./ZAttrViewStyles";
import { ZAttrComponentView } from "./zAttrViewInfo";

export const viewStylesByType = {
  SimpleText: ["fontWeight", "textAlign"],
  PersonCellInfo: [],
  DictRef: ["fontWeight", "textAlign"],
  ObectRef: ["fontWeight", "textAlign"],
  ImgList: [],
  LinkStd: ["fontWeight", "textAlign"],
  Tag: ["fontWeight"],
  FileList: ["fontWeight", "textAlign"],
} satisfies Record<ZAttrComponentView["view"], AttrViewStylesNames[]>;
