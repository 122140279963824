export default {
  translation: {
    "Action is irreversible": "Action is irreversible",
    Add: "Add",
    "all attributes": "all attributes",
    "Appeal to technical support is registered":
      "Appeal to technical support is registered",
    "Are you sure to delete {{count}} persons?":
      "Are you sure to delete {{count}} persons?",
    "Are you sure to delete {{count}} persons?_one":
      "Are you sure to delete {{count}} person?",
    "Attached files": "Attached files",
    "Attention!": "Attention!",
    Attributes: "Attributes",
    "Bussiness dictionaries": "Bussiness dictionaries",
    Cancel: "Cancel",
    Close: "Close",
    "Contacting tech support": "Contacting tech support",
    "Current state": "Current state",
    DateFormat: "DD.MM.YYYY",
    DateTimeFormat: "DD.MM.YYYY HH:mm",
    Delete: "Delete",
    "Describe the question": "Describe the question",
    Dictionaries: "Dictionaries",
    "Do you want to delete the current entry?":
      "Do you want to delete the current entry?",
    "Enter a name": "Enter a name",
    "Enter email": "Enter email",
    Entity_one: "Entity",
    Entity_few: "Entities",
    "Error when accessing the server": "Error when accessing the server",
    "Format:": "Format: {{value}}",
    "Full name": "Full name",
    "General information": "General information",
    "Instance of object": "An instance of the {{name}} object",
    "Invalid email format": "Invalid email format",
    "Invalid page URL": "Invalid page URL",
    "History of changes": "History of changes",
    "Groups and roles": "Groups and roles",
    Logout: "Logout",
    Main: "Main",
    Management: "Management",
    "Maximum number: count": "Maximum number: {{count}}",
    "Maximum total size:": "Maximum total size: {{size}} MB",
    "Menu settings": "Menu Settings",
    Models: "Models",
    "New instance": "New instance",
    "New instance of object": "A new instance of the {{name}} object",
    "Next state": "Next state",
    "No match for path {path}": "No match for path {path}",
    "Objects and attributes": "Objects and attributes",
    Password: "Password",
    Persons: "Persons",
    Question: "Question",
    Register: "Register",
    Repeat: "Repeat",
    "Restore password": "Restore password",
    Save: "Save",
    Search: "Search",
    Send: "Send",
    Setting: "Setting",
    "Selected entities": "Selected entities",
    "Sign In": "Sign In",
    "Specify the topic": "Specify the topic",
    Status: "Status",
    Subject: "Subject",
    Submit: "Submit",
    templates: "templates",
    "The field is mandatory": "The field is mandatory",
    "Total files size exceeds": "Total files size exceeds {{size}} MB",
    "Total models: {{count}}": "Total models: {{count}}",
    "Total information": "Total information",
    "Total instances count": "Total instances: {{count}}",
    "Total persons count": "Total {{count}} users",
    "Total persons count_one": "Total {{count}} user",
    Upload: "Upload",
    "Your request has been registered by the technical support service":
      "Your request has been registered by the technical support service",

    actionType: {
      // see ActionTypeName
      ATTRIBUTE: "Attribute",
      OBJECT: "Object",
      ENTITY: "Instance",
    },
    attrType: {
      BOOLEAN: "Boolean",
      CHILD_OBJECT_ID: "List of dependent objects",
      DATE: "Date",
      DATE_WITH_TIME: "Date and time",
      OBJECT_ID: "Link to another object",
      ONE_CHOICE_DICTIONARY: "Reference",
      FILE: "File",
      FORMULA: "Formula",
      IMAGE: "Image",
      INT: "Integer",
      LINK_TO_EXTERNAL_SOURCE: "Link to external resource",
      MULTIPLE_CHOICE_DICTIONARY: "Multiple choice from a reference",
      NUMERIC: "Fractional number",
      STRING: "Text",
      TIME: "Time",
      USER_FILTER: "Users list",
    },
    groupType: {
      Dictionary: "Dictionary",
      Mnemonic: "Mnemonic",
    },
    management: {
      AddGroup: "Add group",
      "Confirm deletion of dictionary group":
        "Are you sure you want to delete the dictionary group?",
      "Confirm deletion of dictionary":
        "Are you sure you want to delete the dictionary?",
      "Deleting a dictionary group": "Deleting a dictionary group",
      "Group name": "Group name",
      "New group of dictionaries": "New group of dictionaries",
      "Wrong group type": "Wrong group type",
    },
    Background: {
      CommOffer: "Coordination of commercial offers",
      Description:
        "Automation of the processes of interaction between manufacturers, distributors and suppliers with retail chains",
      Header: "Process Automation",
      Promo: "Promo calendar",
      Revaluation: "Applications for revaluation",
    },
    Pages: {
      Plans: "Plans",
      Projects: "Projects",
      Reports: "Reports",
    },
  },
};
