/**
 * Таблица редактирования ограничений по значениям
 * Предназначено для использования внутри <Form.Item />
 */
/* eslint jsx-a11y/control-has-associated-label: "off" */
import * as React from "react";
import { CheckOutlined } from "@ant-design/icons";
import { ZIdName } from "src/types/ZIdName";
import { Table, TableColumnsType } from "antd";
import { AttrRestrictions } from "./restrictionUtils";
import styles from "./RestrictionsTable.module.less";
import imgSwitch from "./switch.svg";

export type PrimaryDirectionMode = "column" | "row";

interface PropsRestrictionsTable {
  value?: AttrRestrictions;
  onChange?(newValue: AttrRestrictions): void;
  primaryDictionary: ZIdName[];
  secondaryDictionary: ZIdName[];
  direction: PrimaryDirectionMode;
  onChangeDirection(mode: PrimaryDirectionMode): void;
  disabled?: boolean;
}

const sortedOptions = (src: ZIdName[]): ZIdName[] =>
  [...src].sort((a, b) =>
    a.name.toLocaleLowerCase().localeCompare(b.name.toLowerCase()),
  );

export const RestrictionsTable: React.FC<PropsRestrictionsTable> = (
  props: PropsRestrictionsTable,
) => {
  const {
    primaryDictionary,
    secondaryDictionary,
    value,
    direction,
    onChange,
    onChangeDirection,
    disabled,
  } = props;

  const getPair = (
    rowId: number,
    columnId: number,
  ): { primaryId: number; secondId: number } =>
    direction === "row"
      ? { primaryId: columnId, secondId: rowId }
      : { primaryId: rowId, secondId: columnId };

  const isChecked = (rowId: number, columnId: number): boolean => {
    const { primaryId, secondId } = getPair(rowId, columnId);
    return value?.[primaryId]?.includes(secondId) ?? false;
  };

  const toggle = (rowId: number, columnId: number) => {
    if (disabled) return;
    const checked = isChecked(rowId, columnId);
    const { primaryId, secondId } = getPair(rowId, columnId);
    const newDict = { ...value };
    if (checked) {
      newDict[primaryId] = (newDict[primaryId] ?? []).filter(
        (n) => n !== secondId,
      );
    } else {
      newDict[primaryId] = [...(newDict[primaryId] ?? []), secondId];
    }
    onChange?.(newDict);
  };
  const primarySorted = React.useMemo(
    () => sortedOptions(primaryDictionary),
    [primaryDictionary],
  );
  const secondarySorted = React.useMemo(
    () => sortedOptions(secondaryDictionary),
    [secondaryDictionary],
  );
  const [columns, rows] =
    direction === "column"
      ? [secondarySorted, primarySorted]
      : [primarySorted, secondarySorted];

  type Row = { id: number };
  const tableRows = rows.map(({ id }) => ({ id }));

  const tableColumns: TableColumnsType<Row> = [
    {
      key: "left",
      title: (
        <button
          type="button"
          id="toggleRestrictionsTable"
          onClick={() => {
            onChangeDirection(direction === "column" ? "row" : "column");
          }}
        >
          <img src={imgSwitch} alt="" />
        </button>
      ),
      width: 100,
      render: (_text: unknown, _row: unknown, i: number) => rows[i]?.name,
      fixed: true,
      align: "center",
    } as const,
    ...columns.map(
      ({ id, name }) =>
        ({
          key: id,
          title: name,
          width: 100,
          align: "center",
          render: (_: unknown, row: Row) => (
            <button
              type="button"
              onClick={() => toggle(row.id, id)}
              disabled={disabled}
            >
              {isChecked(row.id, id) && <CheckOutlined />}
            </button>
          ),
        }) as const,
    ),
  ];
  return (
    <Table<Row>
      className={styles.table}
      columns={tableColumns}
      dataSource={tableRows}
      scroll={{ x: "max-content", y: 360 }}
      pagination={false}
      id="restrictionsTable"
    />
  );
};

RestrictionsTable.defaultProps = {
  value: undefined,
  onChange: undefined,
  disabled: false,
};

/*
  В новой версии появилась прокрутка. Но имхо она не слала сильно лучше.
  Есть большая вероятность, что этот вариант тоже не понравится.
  Поэтому на всякий случай оставлен код из предыдущей версии.
    <table className={styles.table}>
      <thead>
        <tr>
          <td className={styles.switcher}>
            <button
              type="button"
              onClick={() => {
                onChangeDirection(direction === "column" ? "row" : "column");
              }}
            >
              <img src={imgSwitch} alt="" />
            </button>
          </td>
          {columns.map(({ id, name }) => (
            <th key={id}>
              {name} ({id})
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map((row) => (
          <tr key={row.id}>
            <th>
              {row.name} ({row.id})
            </th>
            {columns.map(({ id }) => (
              <td key={id}>
                <button
                  type="button"
                  onClick={() => toggle(row.id, id)}
                  disabled={disabled}
                >
                  {isChecked(row.id, id) && <CheckOutlined />}
                </button>
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>

*/
