import { makeAutoObservable } from "mobx";
import { RemoteData } from "src/common/RemoteData";
import { ZEntity } from "src/types/ZEntity";
import {
  FormBlockDef,
  FormWithBlockStore,
} from "src/components/FormWithBlocks";
import { ZIdName } from "src/types/ZIdName";
import { getIdNames } from "src/references/getIdNames";
import { Permission, hasPermissionIn } from "src/types/Permission";
import { FormInstance } from "antd";
import { ZObjState } from "src/types/ZObjState";
import { ifDef } from "src/common/ifDef";
import { ZObjectItem } from "src/types/ZObjectItem";
import { getValidStateId } from "src/common/getValidStateId";
import { AnchorItem } from "src/common/anchorItem";
import { t } from "i18next";
import { emmitLastObjectUpdateSE } from "src/common/storageEventUtils";
import { ZAttribute } from "src/types/ZAttribute";
import { buildMainBlock } from "./blockBuilder/buildMainBlock";
import {
  EdCardValues,
  callChangeEntityState,
  createEntity,
  deleteEntity,
  ed2entity,
  entity2ed,
  getAttributesFromObject,
  loadAvailableStates,
  loadEntity,
  loadObject,
  saveEntity,
} from "./apiEntityCard";
import { ZEntityAvailableState } from "./EntityPage.types";
import { loadObjectStates } from "../ManagementPage/Obj2Tab/roles/rolesApi";
import { anchorList } from "./EntityFormWithAnchor/anchorList";
import { EntityHistoryStore } from "./EntityHistory/EntityHistoryStore";

const initData2InitEnt = (data: Record<string, string[]>): ZEntity =>
  ({
    attributeValues: Object.entries(data).map(([attrId, values]) => ({
      attributeId: Number(attrId),
      values,
    })),
  }) as ZEntity;

const validateInitialData = (
  objAtts: ZAttribute[],
  initialData: Record<string, string[]>,
): Promise<Record<string, string[]>> =>
  new Promise((resolve, reject) => {
    const initialKeys = Object.keys(initialData);
    const objKeySet = new Set(objAtts.map((attr) => String(attr.id)));
    const hasWrong = initialKeys.find((key) => !objKeySet.has(key));
    if (hasWrong) {
      reject(Error(`Wrong initial attribute wint id = "${hasWrong}"`));
    } else {
      resolve(initialData);
    }
  });

export type EntityCardData = {
  object: ZObjectItem; // Метаданные - описание объекта
  entity?: ZEntity; // // Данные - описание сущности
  availableStates?: ZEntityAvailableState[];
  states?: ZObjState[];
};

export const entityCardFormName = "EntityForm";

export const entityCardStore = makeAutoObservable({
  info: {
    status: "none",
  } satisfies RemoteData<EntityCardData> as RemoteData<EntityCardData>,
  setInfo(info: RemoteData<EntityCardData>) {
    this.info = info;
  },
  cvtEntity(entity?: ZEntity): EdCardValues {
    if (!entity) return {};
    const { info } = this;
    if (info.status !== "ready") return {};
    return entity2ed(entity /* , info.result.object, this.attrTypesMap */);
  },
  get initialData(): EdCardValues {
    const { info } = this;
    return info.status === "ready" ? this.cvtEntity(info.result.entity) : {};
  },
  get availableStates(): ZEntityAvailableState[] | undefined {
    const { info } = this;
    return info.status === "ready" ? info.result.availableStates : undefined;
  },
  attrTypes: [] as ZIdName[],
  setAttrTypes(types: ZIdName[]) {
    this.attrTypes = types;
  },
  get attrTypesMap(): Record<number, string> {
    return this.attrTypes.reduce(
      (acc, { id, name }) => ({ ...acc, [id]: name }),
      {} as Record<number, string>,
    );
  },
  get attrNamesMap(): Record<number, string> | undefined {
    if (this.info.status !== "ready") return undefined;
    return this.info.result.object.attributes.reduce(
      (acc, { id, name }) => ({ ...acc, [id]: name }),
      {} as Record<number, string>,
    );
  },
  get objectId(): number | undefined {
    if (this.info.status !== "ready") return undefined;
    return this.info.result.object.id;
  },

  get canEntityUpdate(): boolean {
    const { info } = this;
    if (info.status !== "ready") return false;
    const { entity } = info.result;
    if (!entity) return true;
    return hasPermissionIn(entity, Permission.entityUpdate);
  },

  get entityName(): string {
    if (this.info.status === "error") {
      return "Ошибка";
    }
    if (this.info.status === "ready") {
      const { object, entity } = this.info.result;
      if (!entity?.id)
        return t("New instance of object", { name: object.name });

      const dict = getAttributesFromObject(object);
      const attrName = Object.values(dict).find(
        ({ fieldKey }) => fieldKey === "NAME",
      );
      const valItem = entity?.attributeValues.find(
        ({ attributeId }) => attributeId === attrName?.id,
      );
      return (
        valItem?.values?.[0] ?? t("Instance of object", { name: object.name })
      );
    }
    return "";
  },

  get objectName(): string {
    if (this.info.status === "error") {
      return "Ошибка";
    }
    if (this.info.status === "ready") {
      const { object } = this.info.result;
      return String(object.name);
    }
    return "";
  },

  formStore: new FormWithBlockStore(),

  async commonInit() {
    this.setAttrTypes(await getIdNames("attrType"));
  },

  async initNew(objectId: string, initial?: Record<string, string[]>) {
    try {
      this.setInfo({ status: "wait" });
      const [object] = await Promise.all([
        loadObject(objectId, {
          stateId: getValidStateId(undefined),
          translate: true,
        }),
        this.commonInit(),
      ]);

      const safeInitial = await validateInitialData(
        object.attributes,
        initial || {},
      );

      this.setInfo({
        status: "ready",
        result: {
          object,
          entity: initData2InitEnt(safeInitial),
        },
      });
    } catch (error) {
      this.setInfo({ status: "error", error });
    }
  },

  async init(entityId: string) {
    try {
      this.setInfo({ status: "wait" });
      await this.commonInit();
      // Здесь пока не ясно, нужен перевод или нет.
      // Основная проблема - переводится всё что нужно и не нужно.
      // Например, вместо "true" может приехать что угодно - "истина", "真的" или что-то ещё.
      // Но это сразу нарушает работу контроллера. И пользователь видит неправильные данные
      const entity = await loadEntity(entityId, { translate: false });
      const { stateId, objectId } = entity;
      const object = await loadObject(objectId, {
        stateId: getValidStateId(entity.stateId),
        translate: true,
      });
      const states = await loadObjectStates(objectId, { translate: true });
      const availableStates = stateId
        ? await loadAvailableStates(objectId, stateId, { translate: true })
        : undefined;
      this.setInfo({
        status: "ready",
        result: { object, entity, availableStates, states },
      });
    } catch (error) {
      this.setInfo({ status: "error", error });
    }
  },

  get stateNames(): Record<number, string> {
    const res: Record<number, string> = {};
    if (this.info.status === "ready") {
      this.info.result.states?.forEach(({ id, name }) => {
        res[id] = name;
      });
    }
    return res;
  },

  get curStateName(): string {
    const { info } = this;
    if (info.status === "ready") {
      return (
        ifDef(
          info.result.entity?.stateId,
          (stateId) => this.stateNames[stateId] ?? `${stateId}`,
        ) ?? ""
      );
    }
    return "";
  },

  get buzy(): boolean {
    return this.formStore.saving || this.deleting;
  },

  get documentTitle(): string {
    if (this.info.status === "ready") {
      return this.entityName;
    }
    return "";
  },

  async changeState(stateId: number | string, form: FormInstance) {
    try {
      this.formStore.setSaving(true);
      const { info } = this;
      if (info.status !== "ready") throw Error("Данные не загружены");
      const { entity } = info.result;
      if (!entity) throw Error("Сущность отсутствует");
      // Проверка полей
      await form.validateFields();
      // Сохранение
      await this.save(form.getFieldsValue());
      // Переключение состояния
      await callChangeEntityState(entity.id, +stateId);
      await this.init(String(entity.id));
    } catch (e) {
      if ("errorFields" in e) {
        // Если ошибка валидации формы, то попытаться установить фокус на ошибочное поле
        if (Array.isArray(e.errorFields)) {
          const name = e.errorFields[0]?.name;
          if (name && this.rootBlock) {
            this.formStore.activate(entityCardFormName, name, this.rootBlock);
          }
        }
        throw Error("Требуется правильно заполнить поля на форме");
      } else {
        throw e;
      }
    } finally {
      this.formStore.setSaving(false);
    }
  },

  async create(values: EdCardValues): Promise<EdCardValues> {
    // После выполнения запросв будет редирект на новую страницу с entityCard/:id
    // Там данные стора загрузятся заново. Поэтому нет смысла их сейчас синхронизировать.
    const { info } = this;
    if (info.status === "ready") {
      const srcEntity = ed2entity(values, 0, info.result.object.id);
      const resEntity = await createEntity(srcEntity);
      return {
        ...this.cvtEntity(resEntity),
        // Небольшой костыль. Т.к для редиректа нужен id сущности, который не является частью формы
        entityId: String(resEntity.id),
      };
    }
    return Promise.reject(
      Error(`Сохранение невозможно. Статус: ${info.status}`),
    );
  },

  async save(values: EdCardValues): Promise<EdCardValues> {
    const { info } = this;
    if (info.status === "ready") {
      const entityId = info.result.entity?.id;
      if (!entityId) throw Error("Cant save empty entity");
      const srcEntity = ed2entity(values, entityId, info.result.object.id);
      const resEntity = await saveEntity(srcEntity);
      this.internalUpdate(resEntity);
      emmitLastObjectUpdateSE(this.objectId);
      this.historyStore?.reloadHistory();
      return this.cvtEntity(resEntity);
    }
    return Promise.reject(
      Error(`Сохранение невозможно. Статус: ${info.status}`),
    );
  },
  internalUpdate(newEntity: ZEntity) {
    if (this.info.status === "ready") {
      this.info.result.entity = newEntity;
    }
  },

  get rootBlock(): FormBlockDef | null {
    if (this.info.status === "ready") {
      return buildMainBlock(this.info.result.object, {
        typesMap: this.attrTypesMap,
        canUpdate: this.canEntityUpdate,
        stateId: this.info.result.entity?.stateId,
      });
    }
    return null;
  },

  get anchors(): AnchorItem[] {
    return anchorList(this.rootBlock);
  },

  // При вызове этой функции нужно не забывать делать catch()
  async doDelete() {
    this.setDeleting(true);
    try {
      const { info } = this;
      if (info.status !== "ready" || !info.result.entity)
        throw Error("Удаление невозможно");
      await deleteEntity(info.result.entity.id);
      emmitLastObjectUpdateSE(this.objectId);
    } finally {
      this.setDeleting(false);
    }
  },
  get availableDelete(): boolean {
    if (this.info.status !== "ready") return false;
    const { entity } = this.info.result;
    if (!entity) return false;
    return hasPermissionIn(entity, Permission.entityDelete);
  },
  deleting: false,
  setDeleting(value: boolean) {
    this.deleting = value;
  },

  get entityId(): number | null {
    if (this.info.status !== "ready") return null;
    return this.info.result.entity?.id ?? null;
  },

  get historyStore(): EntityHistoryStore | null {
    return this.entityId && this.stateNames
      ? new EntityHistoryStore(
          this.entityId,
          this.stateNames,
          this.curStateName,
          this.entityName,
        )
      : null;
  },
});

export type EntityCardStore = typeof entityCardStore;
