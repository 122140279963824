import * as React from "react";
import { Form } from "antd";
import { Separator } from "src/common/attrView/viewFormItems/Separator";
import { observer } from "mobx-react-lite";
import { edAttrField } from "../../../../EdAttribute";
import { SelectGroupFromAttribute } from "./SelectGroupFromAttribute";
import styles from "../EditorInfo.module.less";

const root = edAttrField.viewInfo;

const appearanceColField = (name: string) => [
  root,
  "appearance",
  "column",
  "group",
  name,
];

interface PropsTableSettingsInfo {
  readonly currentObjectId: number | null;
}

export const TableSettingsInfo: React.FC<PropsTableSettingsInfo> = observer(
  ({ currentObjectId }) => {
    if (!currentObjectId) return null;
    return (
      <div className={styles.box}>
        <div className={styles.boxTitle}>Настройка колонок</div>
        <div className={styles.fields}>
          <Form.Item name={appearanceColField("name")}>
            <SelectGroupFromAttribute objectId={String(currentObjectId)} />
          </Form.Item>
          <Separator name={appearanceColField("separator")} />
        </div>
      </div>
    );
  },
);
