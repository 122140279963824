import * as React from "react";
import { Button, Form, Input, Select } from "antd";
import { onError } from "src/common/onError";
import {
  ZTooltipExtFields,
  zTooltipExtFields,
} from "src/components/ExtendedTooltip";
import { TooltipPlacement } from "antd/lib/tooltip";
import { ExtendedTooltip } from "src/components/ExtendedTooltip/ExtendedTooltip";
import { ModalVertFixed } from "src/components/ModalVertFixed/ModalVertFixed";
import styles from "./TooltipExtEdit.module.less";

interface PropsTooltipExtEdit {
  open: boolean;
  onCancel(): void;
  getInitialData(): ZTooltipExtFields;
  onOk(fields: ZTooltipExtFields): void;
}

// Чтобы избежать ошибок в строковых названиях
const field = (name: keyof ZTooltipExtFields) => name;

export const TooltipExtEdit: React.FC<PropsTooltipExtEdit> = (props) => {
  const { open, onCancel, onOk, getInitialData } = props;
  const initialValues: ZTooltipExtFields = React.useMemo(
    () => (open ? getInitialData() : {}),
    [open],
  );
  const [previewData, setPreviewData] = React.useState<ZTooltipExtFields>({});
  React.useEffect(() => {
    if (open) setPreviewData(getInitialData());
  }, [open]);
  type PlacementOption = {
    value: TooltipPlacement;
    label: string;
  };
  const placementOptions: PlacementOption[] = React.useMemo(() => {
    const dict: Record<TooltipPlacement, string> = {
      topLeft: "Сверху слева",
      top: "Сверху",
      topRight: "Сверху справа",
      rightTop: "Справа сверху",
      right: "Справа",
      rightBottom: "Справа снизу",
      bottomRight: "Снизу справа",
      bottom: "Снизу",
      bottomLeft: "Снизу слева",
      leftBottom: "Слева снизу",
      left: "Слева",
      leftTop: "Слева сверху",
    };
    return Object.entries(dict).map(([value, label]) => ({
      value: value as TooltipPlacement,
      label,
    }));
  }, []);

  return (
    <ModalVertFixed
      height="90vh"
      title="Расширенные параметры для всплывающей подсказки"
      open={open}
      onCancel={onCancel}
      okButtonProps={{ htmlType: "submit" }}
      okText="Применить"
      centered
      width="600px"
      /* eslint react/no-unstable-nested-components: "off" */
      modalRender={(content) => (
        <Form
          className={styles.form}
          layout="vertical"
          initialValues={initialValues}
          onFinish={(values) => {
            try {
              onOk(zTooltipExtFields.parse(values));
            } catch (e) {
              onError(e);
            }
          }}
          onValuesChange={(_part, all) => {
            setPreviewData(all);
          }}
        >
          {content}
        </Form>
      )}
    >
      <div className={styles.content}>
        <div className={styles.preview}>
          <ExtendedTooltip fields={previewData}>
            <Button>Просмотр</Button>
          </ExtendedTooltip>
        </div>
        <div className={styles.fields}>
          <Form.Item name={field("placement")} label="Положение">
            <Select options={placementOptions} allowClear />
          </Form.Item>
          <Form.Item name={field("title")} label="Заголовок">
            <Input allowClear />
          </Form.Item>
          <Form.Item name={field("text")} label="Текст">
            <Input.TextArea allowClear />
          </Form.Item>
          <Form.Item name={field("image")} label="Ссылка на изображение">
            <Input allowClear />
          </Form.Item>
          <Form.Item
            name={field("link")}
            label="Адрес ссылки"
            dependencies={[field("linkText")]}
            rules={[
              ({ getFieldValue }) => ({
                validator: async (_rule, value) => {
                  if (!value && getFieldValue(field("linkText")))
                    throw Error(
                      "Если есть текст ссылки, то надо заполнить адрес",
                    );
                },
              }),
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item name={field("linkText")} label="Текст ссылки">
            <Input allowClear />
          </Form.Item>
          <Form.Item
            name={field("download")}
            label="Адрес для скачивания"
            tooltip="Чтобы скачивание правильно работало, нужно чтобы в ответе сервера присутствовал заголовок Content-Disposition"
            dependencies={[field("downloadText")]}
            rules={[
              ({ getFieldValue }) => ({
                validator: async (_rule, value) => {
                  if (!value && getFieldValue(field("downloadText")))
                    throw Error(
                      "Если есть имя для скачивания, то должнен быть адрес для скачивания",
                    );
                },
              }),
            ]}
          >
            <Input allowClear />
          </Form.Item>
          <Form.Item
            name={field("downloadText")}
            label="Под каким именем скачать файл"
          >
            <Input allowClear />
          </Form.Item>
        </div>
      </div>
    </ModalVertFixed>
  );
};
