import * as React from "react";
import {
  CalendarOutlined,
  EnvironmentOutlined,
  FieldNumberOutlined,
  FieldStringOutlined,
  FileImageOutlined,
  FileOutlined,
  TagOutlined,
  TagsOutlined,
} from "@ant-design/icons";
import { AttrTypeName } from "src/types/AttrType";

const icons = new Map<number, React.ReactNode>();

export const getTypeIcon = (typeId: number): React.ReactNode =>
  icons.get(typeId) ?? <EnvironmentOutlined />;

export const initTypeIcons = (typeNames: Record<number, string>) => {
  const iconByName: Partial<Record<AttrTypeName, React.ReactNode>> = {
    [AttrTypeName.dictSingle]: <TagOutlined />,
    [AttrTypeName.dictMulti]: <TagsOutlined />,
    [AttrTypeName.string]: <FieldStringOutlined />,
    [AttrTypeName.number]: <FieldNumberOutlined />,
    [AttrTypeName.int]: <FieldNumberOutlined />,
    [AttrTypeName.date]: <CalendarOutlined />,
    [AttrTypeName.dateTime]: <CalendarOutlined />,
    [AttrTypeName.file]: <FileOutlined />,
    [AttrTypeName.image]: <FileImageOutlined />,
  };
  Object.entries(typeNames).forEach(([typeId, name]) => {
    const icon = iconByName[name as AttrTypeName];
    if (icon) icons.set(+typeId, icon);
  });
};
