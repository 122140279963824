import * as React from "react";
import {
  BlockContext,
  FormBlockDef,
  FormBlockItem,
} from "src/components/FormWithBlocks";
import { DrawItems } from "src/components/DrawItems";
import styles from "./EntityGroupBlock.module.less";
import { blockTitle } from "../blockBuilder/blockTitle";

interface PropsEntityGroupBlock {
  title: string;
  prevId: string;
  ctx: BlockContext;
  items: (FormBlockItem | undefined)[];
  subBlocks: (FormBlockDef | undefined)[];
  id: string;
}

export const EntityGroupBlock: React.FC<PropsEntityGroupBlock> = (props) => {
  const { title, prevId, ctx, items, subBlocks, id } = props;
  const itemsCount = items.filter((item) => !!item).length;
  return (
    <div className={styles.box} id={id}>
      {itemsCount > 0 && (
        <div className={styles.block}>
          <h2 className={styles.title}>{blockTitle(title)}</h2>
          <div className={styles.items}>
            <DrawItems items={items} ctx={ctx} />
          </div>
        </div>
      )}
      {subBlocks.map((block) =>
        block ? (
          <React.Fragment key={block.key}>
            {block.render(prevId, ctx)}
          </React.Fragment>
        ) : null,
      )}
    </div>
  );
};
