import { apiObjUrl } from "src/common/apiUrl";
import { rest } from "src/common/rest";
import {
  FnLoad,
  TableLoadParams,
  TableResponse,
} from "src/components/tables/TableStore";
import { z } from "zod";
import { rangeToTimestamp } from "./rangeToTimestamp";

export type ParamsLoadHistory<TFilters> = {
  page?: number;
  pageSize?: number;
  entityId: number;
  filters?: TFilters;
};

type ApiResponse<TContent> = {
  totalElements: number;
  content: TContent[];
};

type FiltersWithTimestamp = {
  timestamp?: string[];
};

export const apiEntityHistory = async <
  TFilters extends FiltersWithTimestamp,
  TContent,
  TResponse extends ApiResponse<TContent>,
>(
  endpoint: string,
  params: ParamsLoadHistory<TFilters>,
  responseSchema: z.ZodSchema<TResponse>,
): Promise<TableResponse<TContent>> => {
  const { page, pageSize, filters, ...body } = params;
  let postBody = { ...filters, ...body };

  if (filters?.timestamp) {
    const { timestamp, ...otherFilters } = filters;
    postBody = { ...rangeToTimestamp(timestamp), ...otherFilters, ...body };
  }

  const resp = await rest.post(apiObjUrl(endpoint), postBody, {
    params: { page, size: pageSize },
  });

  const { totalElements, content } = responseSchema.parse(resp.data);
  return {
    totalItems: totalElements,
    rows: content,
  };
};

export const historyLoader =
  <TFilters extends object, TRow>(
    entityId: number,
    apiFunction: (
      params: ParamsLoadHistory<TFilters>,
    ) => Promise<TableResponse<TRow>>,
  ): FnLoad<TRow, TFilters> =>
  (params: TableLoadParams<TFilters>): Promise<TableResponse<TRow>> =>
    apiFunction({
      ...params,
      entityId,
    });
