import { ThemeConfig } from "antd/es/config-provider/context";
import { AliasToken } from "antd/es/theme/internal";

const token: Partial<AliasToken> | undefined = {
  borderRadius: 4,
  fontFamily: "Manrope, sans-serif",
  fontWeightStrong: 500,
  colorPrimary: "#E43D00",
  colorPrimaryHover: "#F06326",
  colorPrimaryActive: "#E43D00",
  colorTextPlaceholder: "#0000004D",
  colorTextBase: "#000000d9",
  colorTextDisabled: "#0000004D",
  colorBorder: "#D9D9D9",
  colorError: "#E9033A",
  colorBgContainerDisabled: "#FAFAFA",
  colorLink: "#F06326",
  colorLinkHover: "#F06326",
  colorLinkActive: "#E43D00",
  colorBgLayout: "#FFFFFF",
};

export const themeConfig: ThemeConfig | undefined = {
  token,
  components: {
    Select: { multipleItemHeightLG: 24 },
    Breadcrumb: {
      itemColor: "#0000008C",
      lastItemColor: "#000000D9",
    },
    Form: {
      labelColor: "#0000008C",
      itemMarginBottom: 0,
    },
    Layout: {
      siderBg: "#343A41",
    },
    Menu: {
      itemBg: "#343A41",
      itemColor: "#FFFFFF99",
      itemHoverColor: "#FFFFFF",
      itemSelectedBg: "#BD2C003D",
      itemSelectedColor: "#FFFFFF",
      itemActiveBg: "#BD2C003D",
      popupBg: "#343A41",
    },
    Anchor: {
      linkPaddingBlock: 10,
      linkPaddingInlineStart: 16,
    },
  },
};
