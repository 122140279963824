import * as React from "react";
import { t } from "i18next";
import { Form, Input, InputRef, Radio } from "antd";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { IdLabel } from "src/references/getIdNames";
import { AttrTypeName, isAttrDictionary } from "src/types/AttrType";
import { ZIdName } from "src/types/ZIdName";
import { ZAttribute } from "src/types/ZAttribute";
import { MultiLangFields } from "src/components/MultiLangFields";
import { edAttrField } from "../../../EdAttribute";
import { SelectRoles } from "../SelectRoles";
import { ZRolesGroup } from "../../../roles/roleTypes";
import { SelectDictionary, SelectDictionaryStore } from "../SelectDictionary";
import { SelectObject } from "../SelectObject";
import { EditorInfo, ViewInfo } from "../MetaInfo";
import { TableSettingsInfo } from "../MetaInfo/TableSettingsInfo/TableSettingsInfo";
import styles from "./AttrCommonFields.module.less";
import { validatorOfId } from "../attrValidators";

interface DataSource {
  attrTypesList: IdLabel[];
  attrTypesDict: Record<number, string>;
  roleGroups: ZRolesGroup[];
  selectDictionaryStore: SelectDictionaryStore;
  readonly currentObjectId: number | null;
}

interface PropsAttrCommonFields {
  attrKey: React.Key;
  attrId: number;
  objectId: number;
  disabled: boolean;
  dataSource: DataSource;

  isFieldUnique: (
    field: keyof ZAttribute,
    attrKey: React.Key,
    value: unknown,
  ) => boolean;
  loadObjectOptions(): Promise<ZIdName[]>;
}

export const AttrCommonFields: React.FC<PropsAttrCommonFields> = (props) => {
  const {
    objectId,
    attrId,
    attrKey,
    disabled,
    dataSource,
    isFieldUnique,
    loadObjectOptions,
  } = props;
  const {
    attrTypesList,
    attrTypesDict,
    roleGroups,
    selectDictionaryStore,
    currentObjectId,
  } = dataSource;
  // Установка фокуса на первое поле
  const ref1 = React.useRef<InputRef>(null);
  React.useEffect(() => {
    setTimeout(() => ref1.current?.focus(), 10);
  }, [attrKey]);

  const valueType = Form.useWatch(edAttrField.valueType);
  const typeFilter = (typeId: number): boolean =>
    isAttrDictionary(attrTypesDict[typeId]);
  const dictVisible = typeFilter(valueType);
  const objVisible =
    attrTypesDict[valueType] === AttrTypeName.object ||
    attrTypesDict[valueType] === AttrTypeName.childEntities;
  const roleIdsVisible = attrTypesDict[valueType] === AttrTypeName.usersList;

  return (
    <div className={styles.common}>
      <Form.Item
        name={edAttrField.name}
        label="Название атрибута"
        rules={[
          trimRequired(),
          {
            validator: (_rule, value) =>
              isFieldUnique("name", attrKey, value)
                ? Promise.resolve()
                : Promise.reject(Error("Название уже используется")),
          },
        ]}
      >
        <Input allowClear ref={ref1} disabled={disabled} />
      </Form.Item>
      <MultiLangFields basePath={[edAttrField.translations]} />

      <div className={styles.twoCols}>
        <Form.Item
          className="idAttrType"
          name={edAttrField.valueType}
          label="Тип атрибута"
          rules={[{ required: true }]}
        >
          <TypeSelect types={attrTypesList} disabled={disabled} />
        </Form.Item>

        <div className={styles.blockVert}>
          {roleIdsVisible && (
            <Form.Item
              name={edAttrField.roleIds}
              label="Доступные группы ролей"
            >
              <SelectRoles roleGroups={roleGroups} />
            </Form.Item>
          )}
          {dictVisible && (
            <Form.Item
              name={edAttrField.referenceId}
              label="Справочник"
              rules={[{ required: true }]}
              className="idReferenceToDict"
            >
              <SelectDictionary
                store={selectDictionaryStore}
                disabled={disabled}
              />
            </Form.Item>
          )}
          {objVisible && (
            <Form.Item
              name={edAttrField.referenceId}
              label={t("attrType.OBJECT_ID")}
              rules={[{ required: true }]}
            >
              <SelectObject
                loadObjectOptions={loadObjectOptions}
                disabled={disabled}
              />
            </Form.Item>
          )}
          <EditorInfo
            attrTypesDict={attrTypesDict}
            disabled={disabled}
            objectId={objectId}
            curAttrId={attrId}
          />
          <ViewInfo attrTypesDict={attrTypesDict} />
          <TableSettingsInfo currentObjectId={currentObjectId} />
          <Form.Item
            name={edAttrField.fieldKey}
            label="Идентификатор"
            tooltip="Для программного доступа к атрибуту"
            rules={[
              validatorOfId,
              {
                validator: (_rule, value) =>
                  isFieldUnique("fieldKey", attrKey, value)
                    ? Promise.resolve()
                    : Promise.reject(Error("Идентификатор уже использован")),
              },
            ]}
          >
            <Input allowClear disabled={disabled} />
          </Form.Item>
        </div>
      </div>
    </div>
  );
};

type PropsTypeSelect = {
  types: IdLabel[];
  value?: number;
  onChange?(v: number): void;
  disabled: boolean;
};
const TypeSelect: React.FC<PropsTypeSelect> = (props) => {
  const { types, value, onChange, disabled } = props;
  return (
    <Radio.Group
      disabled={disabled}
      value={value}
      onChange={(e) => {
        onChange?.(e.target.value);
      }}
      className={styles.radioVert}
      options={types.map(({ id, label }) => ({ value: id, label }))}
    />
  );
};
TypeSelect.defaultProps = {
  value: undefined,
  onChange: undefined,
};
