export const apiFilesUrl = (path: string) => `/file-service/api/files${path}`;

export const apiAdminUrl = (path: string) =>
  `/gateway-service/services/admin-service/api${path}`;

export const apiObjUrl = (path: string) =>
  `/gateway-service/services/ms-object/api${path}`;

export const apiI18nUrl = (path: string) => `/i18n/api${path}`;

export const apiAuditUrl = (path: string) => `/audit${path}`;
