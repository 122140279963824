import { Button, Drawer, Space, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { EntityFiltersTable } from "src/pages";
import { observer } from "mobx-react-lite";
import { t } from "i18next";
import { ZAttrViewInfo } from "src/common/attrView";
import { makeAutoObservable } from "mobx";
import { ZEntityRow } from "src/pages/EntityFiltersPage/EntityList/types";
import { AsyncTableView } from "src/pages/EntityFiltersPage/AsyncTableView";
import { ZAttribute } from "src/types/ZAttribute";
import { makeDictNameById } from "src/types/AttrType";
import { getIdNames } from "src/references/getIdNames";
import { ZObjectRefTable } from "src/common/attrEdit/components";
import {
  createColsFromAttrMeta,
  entityList2Rows,
} from "src/pages/EntityFiltersPage/EntityList/compoundEntityTableStore";
import { loadEntity } from "src/pages/EntityCardPage/apiEntityCard";
import {
  loadObjectStates,
  makeObjectStatesMap,
} from "src/pages/ManagementPage/Obj2Tab/roles/rolesApi";
import { ObjectRefTableStore } from "./ObjectRefTableStore";
import styles from "./ObjectRefTable.module.less";
import { TableStore } from "../tables/TableStore";
import { applyParams } from "../tables/singleLoader";

const createStore = () =>
  makeAutoObservable({
    tableStore: null as TableStore<ZEntityRow, {}> | null,
    values: [] as string[],
    setValues(list: string[]) {
      this.values = list;
      this.tableStore?.reload();
    },
    async init(
      objectId: number,
      attrMetaMap: Record<number, ZAttribute>,
      colAtts: ZAttribute[],
      values: string[],
    ) {
      this.tableStore = new TableStore<ZEntityRow, {}>({
        rowKey: "id",
        fnLoad: async (params) => {
          const entityList = await Promise.all(
            this.values.map((id) => loadEntity(Number(id))),
          );
          const rows = entityList2Rows(entityList, attrMetaMap);
          return applyParams(rows, params, () => true);
        },
      });
      const attrTypeMap = makeDictNameById(await getIdNames("attrType"));
      const statesMap = makeObjectStatesMap(
        await loadObjectStates(objectId, { translate: true }),
      );
      /**
       * Столбцы формируются таким же образом, как и для всех таблиц, это значит,
       * что учитываются ВСЕ настройки отображения атрибутов
       */
      const cols = createColsFromAttrMeta(colAtts, attrTypeMap, statesMap, {
        withoutStatus: true,
      });
      this.tableStore.setColumns(cols);
      this.setValues(values);
    },
  });

type PropsObjectRefTable = {
  attrInfo: ZAttribute;
  component: ZObjectRefTable;
  viewInfo: ZAttrViewInfo | undefined;
  value?: string[];
  disabled?: boolean;
  onChange?: (value?: string[]) => void;
  label: string;
};

export const ObjectRefTable: React.FC<PropsObjectRefTable> = observer(
  ({ attrInfo, component, value, disabled, viewInfo, onChange, label }) => {
    const [open, setOpen] = useState(false);
    const cmpStore = useMemo(() => new ObjectRefTableStore(), []);
    const listStore = useMemo(() => createStore(), []);

    const { filtersPageStore } = cmpStore;
    const { tableStore } = listStore;

    const inited = !!filtersPageStore && !!tableStore;

    const init = async () => {
      if (!viewInfo || !component || !attrInfo || !attrInfo.referenceId) return;
      await cmpStore.init(attrInfo, component, viewInfo, value, onChange);
      const { labelAtts } = component;

      const colAtts = labelAtts
        ?.map((id) => cmpStore.attsMetaMap[Number(id)])
        .filter((c) => !!c);

      listStore?.init(
        attrInfo.referenceId,
        cmpStore.attsMetaMap,
        colAtts as ZAttribute[],
        value || [],
      );
    };

    useEffect(() => {
      init();
    }, [attrInfo.referenceId]);

    const syncWithValues = (vals: string[]) => {
      listStore.setValues(vals);
      cmpStore.syncSelected(vals);
    };

    useEffect(() => {
      syncWithValues(value || []);
    }, [value]);

    const handleClose = () => {
      setOpen(false);
      const safeInitVals = cmpStore.initialValues || [];
      onChange?.(safeInitVals);
      syncWithValues(safeInitVals);
    };

    if (!inited) return <Spin>Загрузка...</Spin>;

    return (
      <div className={styles.box}>
        <AsyncTableView<ZEntityRow, {}>
          store={tableStore}
          columns={tableStore.columns}
          useHeader
          usePagination
        />
        <Button
          className={styles.selectBtn}
          onClick={() => {
            setOpen(true);
          }}
        >
          Выбрать
        </Button>
        <Drawer
          width="75vw"
          open={open && !disabled}
          onClose={handleClose}
          className={styles.drawer}
          title={label}
          footer={
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  setOpen(false);
                  cmpStore.setInitialValues(value || []);
                }}
              >
                {t("Save")}
              </Button>
              <Button onClick={handleClose}>{t("Cancel")}</Button>
            </Space>
          }
        >
          <EntityFiltersTable store={filtersPageStore} />
        </Drawer>
      </div>
    );
  },
);

ObjectRefTable.defaultProps = {
  value: undefined,
  onChange: undefined,
};
