import * as React from "react";
import { Button, Tooltip } from "antd";
import { UpCircleOutlined } from "@ant-design/icons";
import { Obj2TabStore } from "../Obj2TabStore";

interface PropsUpToObject {
  store: Obj2TabStore;
}

export const UpToObject: React.FC<PropsUpToObject> = ({ store }) => (
  <Tooltip title="Перейти к объекту-владельцу">
    <Button
      shape="circle"
      icon={<UpCircleOutlined />}
      onClick={() => store.safeUpToObject()}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    />
  </Tooltip>
);
