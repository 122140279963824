import { FileImageFilled } from "@ant-design/icons";
import { DefaultOptionType } from "antd/es/select";
import { makeAutoObservable } from "mobx";
import React from "react";
import { ZAttrViewInfo } from "src/common/attrView";
import { composeEntityLabel } from "src/common/entity/composeEntityLabel/composeEntityLabel";
import { getEntityCachedRequest } from "src/common/entity/getEntityCachedRequest";
import { onError } from "src/common/onError";
import { EntityFiltersPageStore } from "src/pages/EntityFiltersPage/EntityFiltersPageStore";
import {
  loadObjectAttrinbutesReduced,
  makeObjectAttributesMap,
} from "src/pages/ManagementPage/objectsApi";
import { AttrTypeName } from "src/types/AttrType";
import { ZAttribute } from "src/types/ZAttribute";
import { ZEntity } from "src/types/ZEntity";
import { ObjectRefSelectorLabel } from "./ObjectRefSelectorLabel";
import { selectOnRowClick } from "../tables/selectOnRowClick";

export class ObjectRefSelectorStore {
  filterStore: EntityFiltersPageStore | null = null;

  options: DefaultOptionType[] = [];

  setOptions(list: DefaultOptionType[]) {
    this.options = list;
  }

  async loadOptions() {
    try {
      if (!this.viewInfo) return;
      this.setLoading(true);
      const opts = await Promise.all(
        this.entities.map(async (e) => {
          const label = await composeEntityLabel(
            e,
            this.labelIds || [],
            this.viewInfo!,
            undefined,
            {
              separator: "comma",
              replace: { [AttrTypeName.image]: () => <FileImageFilled /> },
            },
          );
          return {
            label: <ObjectRefSelectorLabel label={label} entityId={e.id} />,
            value: String(e.id),
          };
        }),
      );
      this.setOptions(opts);
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
    }
  }

  entities: ZEntity[] = [];

  setEntities(list: ZEntity[]) {
    this.entities = list;
  }

  initialValues: string[] | null = null;

  setInitialValues(list: string[]) {
    this.initialValues = list;
  }

  viewInfo: ZAttrViewInfo | null = null;

  setViewInfo(data: ZAttrViewInfo | undefined) {
    this.viewInfo = data ?? null;
  }

  loading = false;

  setLoading(flag: boolean) {
    this.loading = flag;
  }

  attsMetaMap: Record<number, ZAttribute> = {};

  setAttsMetaMap(map: Record<number, ZAttribute>) {
    this.attsMetaMap = map;
  }

  labelIds: string[] | null = null;

  setLabelIds(list: string[]) {
    this.labelIds = list;
  }

  async init(
    objectId: number,
    value?: string[],
    labelAtts?: string[] | null,
    multiple?: boolean,
    onChange?: (value?: string[]) => void,
  ) {
    try {
      this.setLoading(true);
      this.filterStore = new EntityFiltersPageStore({
        objectId,
        selectionSettings: {
          keepSelected: true,
          onSelect(rows) {
            onChange?.(rows.map((row) => String(row.id)));
          },
          selectionType: multiple ? "checkbox" : "radio",
        },
        onLoad: () => {
          // После первой загрузки нужно установить значения из атрибута, как выбранные
          const selectedKeys = new Set(
            this.filterStore?.tableStore?.selected.map((r) => r.id),
          );
          const notSelectedKeys = new Set(
            this.entities
              .map((e) => e.id)
              .filter((id) => !selectedKeys.has(id)),
          );
          const notSelected =
            this.filterStore?.tableStore?.rows.filter((r) =>
              notSelectedKeys.has(r.id),
            ) || [];
          if (notSelected.length === 0) return;
          this.filterStore?.tableStore?.safeSelect(notSelected);
        },
        canCreate: false,
        onRowClick: (row) => {
          const store = this.filterStore?.tableStore;
          if (store) {
            selectOnRowClick(row, store);
          }
        },
      });

      const safeValues = value || [];
      this.setInitialValues(safeValues);
      this.setLabelIds(labelAtts || []);
      const attsMetaMap = makeObjectAttributesMap(
        await loadObjectAttrinbutesReduced(String(objectId)),
      );
      this.setAttsMetaMap(attsMetaMap);
      await this.refresh(safeValues);
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
    }
  }

  async refresh(values: string[]) {
    try {
      this.setLoading(true);
      const entities = await Promise.all(
        values.map(async (entityId) =>
          getEntityCachedRequest(Number(entityId)),
        ),
      );
      this.setEntities(entities);
      await this.loadOptions();
    } catch (error) {
      onError(error);
    } finally {
      this.setLoading(false);
    }
  }

  onSelect(changed: string[]) {
    const keys = new Set<string>(changed);
    const newSelected =
      this.filterStore?.tableStore?.rows.filter((row) =>
        keys.has(String(row.id)),
      ) || [];
    this.filterStore?.tableStore?.safeSelect(newSelected);
  }

  constructor() {
    makeAutoObservable(this);
  }
}
