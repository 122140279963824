import * as React from "react";
import { observer } from "mobx-react-lite";
import { SettingsIconStd, TemplatesIconStd } from "src/common/icons";
import { Button, Form, Select, Space, Spin } from "antd";
import { classNames } from "src/common/classNames";
import { t } from "i18next";
import styles from "./EntityFiltersTable.module.less";
import { AttsHierarchy } from "./AttsHierarchy/AttsHierarchy";
import { AttsTree } from "./AttsTree/AttsTree";
import { EntityFiltersPageStore } from "./EntityFiltersPageStore";
import { AttsTreeSelectedPath } from "./AttsTree/AttsTreeSelectedPath";
import { EntityList } from "./EntityList/EntityList";
import { EntityCount } from "./EntityList/EntityCount";
import { SlideMenuAttrList } from "./SlideMenu/SlideMenuAttrList";

type MenuItemKey = "atts" | "templates";

type PropsEntityFiltersTable = {
  store: EntityFiltersPageStore;
};
export const EntityFiltersTable: React.FC<PropsEntityFiltersTable> = observer(
  ({ store }) => {
    const [menuKey, setMenuKey] = React.useState<MenuItemKey | null>(null);
    const openAttsMenu = () => setMenuKey("atts");
    const openTemplatesMenu = () => setMenuKey("templates");
    const closeMenu = () => setMenuKey(null);
    const objectId = store.currObjId;

    React.useEffect(() => {
      if (!objectId) return;
      store.init(objectId);
    }, [objectId]);

    if (!objectId) return null;

    return (
      <div className={classNames([styles.lWSider, styles.pageInner])}>
        <div className={styles.sider}>
          {store.tableStore && <EntityCount store={store.tableStore} />}

          <div className={styles.siderHeader}>
            <div className={styles.headerButtons}>
              <Button
                type="primary"
                size="large"
                onClick={() => openAttsMenu()}
                icon={<SettingsIconStd />}
              >
                {t("all attributes")}
              </Button>
              <Button
                size="large"
                onClick={() => openTemplatesMenu()}
                disabled
                icon={<TemplatesIconStd />}
              >
                {t("templates")}
              </Button>
            </div>
          </div>
          <div className={styles.siderContent}>
            <div className={styles.siderBox}>
              <Space
                direction="vertical"
                size="large"
                style={{ width: "100%" }}
              >
                <Form.Item label={t("Status")}>
                  <Select
                    allowClear
                    value={store.tableStore?.filters?.stateId}
                    options={store.objectStatesOptions}
                    style={{ width: "100%" }}
                    onChange={(value) => {
                      store.tableStore?.setFilters({
                        ...store.tableStore.filters,
                        stateId: value,
                      });
                    }}
                  />
                </Form.Item>

                <AttsHierarchy store={store} />
                <AttsTreeSelectedPath store={store} objectId={objectId} />
                <AttsTree store={store} />
              </Space>
            </div>
          </div>
        </div>
        <div className={styles.lWSiderMainContent}>
          <SlideMenuAttrList
            onClose={closeMenu}
            open={menuKey === "atts"}
            store={store}
          />
          {store.loading ? (
            <div className={styles.loaderBox}>
              <Spin spinning={store.loading} />
            </div>
          ) : (
            <EntityList
              store={store}
              onRowClick={(row, index) => {
                store.onRowClick?.(row, index);
              }}
            />
          )}
        </div>
      </div>
    );
  },
);
