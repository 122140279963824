/**
 * Форма редактирования узла на вкладке Объектов
 * Верхнеуровневый компонент, который в зависимости от типа подставляет уже нужные подробности
 */
import * as React from "react";
import { ModelessForm } from "src/components/ModelessForm";
import { observer } from "mobx-react-lite";
import { Obj2TabStore } from "./Obj2TabStore";
import { CommonNodeO2, visitO2 } from "./Obj2Nodes";
import { ObjectForm2 } from "./forms/ObjectForm2";
import { AttrForm2 } from "./forms/AttrForm2/AttrForm2";
import { GroupForm2 } from "./forms/GroupForm2/GroupForm2";
import { ValueForm2 } from "./forms/ValueForm2";

interface PropsObjNodeEditor {
  curNode: CommonNodeO2;
  store: Obj2TabStore;
}

export const ObjNodeEditor: React.FC<PropsObjNodeEditor> = observer((props) => {
  const { store, curNode } = props;
  const content = visitO2(curNode, {
    obj: (it) => <ObjectForm2 store={store} objectNode={it} />,
    attr: (it) => <AttrForm2 store={store} attrNode={it} />,
    group: (it) => <GroupForm2 store={store} groupNode={it} />,
    value: (it) => <ValueForm2 store={store} valueNode={it} />,
  });
  return (
    <ModelessForm store={store.formStore} submit="Сохранить" name="curItem">
      {content}
    </ModelessForm>
  );
});
