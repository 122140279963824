/**
 * Главная часть экрана в том случае, когда в левом дереве выбран узел-объект
 * Предполагает табы с разной функциональностью
 */
import * as React from "react";
import { Tabs, TabsProps } from "antd";
import { ObjectO2 } from "./Obj2Nodes";
import { Obj2TabStore } from "./Obj2TabStore";
import { ObjNodeEditor } from "./ObjNodeEditor";
import { Obj2Orders } from "./Obj2Orders";

interface PropsObj2Content {
  node: ObjectO2;
  store: Obj2TabStore;
}

export const Obj2Content: React.FC<PropsObj2Content> = ({ node, store }) => {
  const items: TabsProps["items"] = [
    {
      key: "objFields",
      label: "Управление",
      children: <ObjNodeEditor curNode={node} store={store} />,
    },
    {
      key: "objOrders",
      label: "Порядок групп и атрибутов",
      children: <Obj2Orders objNode={node} store={store} />,
    },
  ];
  const [active, setActive] = React.useState("objFields");
  return (
    <Tabs
      items={items}
      activeKey={active}
      onChange={(key) => store.formStore.safeAction(() => setActive(key))}
    />
  );
};
