import * as React from "react";
import dayjs from "dayjs";
import { z } from "zod";
import { FormRule } from "antd";
import { ItemProps } from "src/components/FormWithBlocks";
import { safeStr2Regexp } from "src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/MetaInfo/RegexpInput";
import { createTooltipProperty } from "src/components/ExtendedTooltip/createTooltipProperty";
import { AttrTypeName } from "src/types/AttrType";
import {
  zAttrCheckbox,
  zAttrDatePicker,
  zAttrInput,
  zAttrInputNumber,
  zAttrSelect,
  zAttrSwitch,
  zAttrTextArea,
  zAttrTimePicker,
  zAttrUploader,
  zObjectRefSelector,
} from "./components";
import { zAttrMaskedInput } from "./components/ZAttrMaskedInput";
import { zAttrPersonSelect } from "./components/ZAttrPersonSelect";
import { zChildEntities } from "./components/ZChildEntities";
import { zObjectRefTable } from "./components/ZObjectRefTable";

// Расширенные параметры для тултипа
export const zTooltipExt = z.object({
  placement: z.string().optional(), // TODO возможно потребуется более точный тип
  title: z.string().nullable().optional(),
  link: z.string().nullable().optional(),
  linkText: z.string().nullable().optional(),
  image: z.string().nullable().optional(),
  download: z.string().nullable().optional(),
  downloadText: z.string().nullable().optional(), // под каким именем сохранять файл
});

// Операции сравнения
export const zCmpOp = z.enum(["GT", "GE", "LE", "LT", "NE"]);
export type ZCmpOp = z.infer<typeof zCmpOp>;

// value opCode otherAttr value
export const zRelativeCmpRule = z.object({
  opCode: zCmpOp,
  otherAttrId: z.number(),
  message: z.string(),
});
export type ZRelativeCmpRule = z.infer<typeof zRelativeCmpRule>;

export const zAttrItemProps = z.object({
  required: z.boolean().optional(),
  pattern: z.string().optional(), // Регулярное выражение в виде строки для правила pattern
  todayRule: zCmpOp.optional(), // Правило для ограничения дат относительно сегодня
  relativeCmpRule: zRelativeCmpRule.optional(), // Правило для сравнения с другим полем
  tooltip: z.string().optional(),
  tooltipExt: zTooltipExt.nullable().optional(),
  // Параметры расположения на форме
  colspan: z.number().nullable().optional(),
  rowspan: z.number().nullable().optional(),
  newLine: z.boolean().nullable().optional(),
});
export type ZAttrItemProps = z.infer<typeof zAttrItemProps>;

export const zAttrComponentEditor = z.discriminatedUnion("editor", [
  zAttrCheckbox,
  zAttrSwitch,
  zAttrDatePicker,
  zAttrInput,
  zAttrInputNumber,
  zAttrSelect,
  zAttrTextArea,
  zObjectRefSelector,
  zAttrTimePicker,
  zAttrUploader,
  zAttrMaskedInput,
  zAttrPersonSelect,
  zChildEntities,
  zObjectRefTable,
]);
export type ZAttrComponentEditor = z.infer<typeof zAttrComponentEditor>;

export type AttrEditorCompName = ZAttrComponentEditor["editor"];

export const zAttrEditInfo = z.object({
  ver: z.literal(1),
  itemProps: zAttrItemProps.optional(),
  component: zAttrComponentEditor.optional(),
});
export type ZAttrEditInfo = z.infer<typeof zAttrEditInfo>;

export const cvtItemProps = (
  src: ZAttrItemProps,
  typeName: AttrTypeName | undefined,
): ItemProps => {
  const dst = {
    tooltip: createTooltipProperty(src),
    rules: [] as FormRule[],
    style: {} as React.CSSProperties,
    dependencies: [] as string[],
  } satisfies ItemProps;
  if (src.required) dst.rules.push(valuesRequired(typeName));
  const pattern = safeStr2Regexp(src.pattern);
  if (pattern) dst.rules.push(valuesPattern(pattern));

  const { todayRule, relativeCmpRule } = src;
  if (
    (typeName === AttrTypeName.date || typeName === AttrTypeName.dateTime) &&
    todayRule
  ) {
    dst.rules.push(checkTodayRule(todayRule));
  }
  if (relativeCmpRule && typeName) {
    const { otherAttrId } = relativeCmpRule;
    dst.dependencies.push(String(otherAttrId));
    dst.rules.push(relativeCmpValidator(relativeCmpRule, typeName));
  }

  let gridColumn = "";
  if (src.colspan && src.colspan > 1) {
    gridColumn = `span ${src.colspan}`;
  }
  if (src.rowspan) {
    dst.style.gridRow = `span ${src.rowspan}`;
  }
  if (src.newLine) {
    gridColumn = gridColumn ? `1 / ${gridColumn}` : "1";
  }
  if (gridColumn) {
    dst.style.gridColumn = gridColumn;
  }
  return dst;
};

const isEmty = (
  value: string[] | null,
  typeName: AttrTypeName | undefined,
): boolean => {
  if (!value) return true;
  if (Array.isArray(value)) {
    if (value.length === 0) return true;
    if (!value[0]) return true;
    if (typeName === AttrTypeName.boolean && value[0] === "false") return true;
  }
  return false;
};

// Специальное правило для values, соответствующее required
const valuesRequired = (typeName: AttrTypeName | undefined): FormRule => ({
  required: true,
  validator: (_rule, value: string[] | null) =>
    isEmty(value, typeName)
      ? Promise.reject(Error("Необходимо заполнить поле"))
      : Promise.resolve(),
});

// просто pattern не подходит потому что value - это массив
const valuesPattern = (pattern: RegExp): FormRule => ({
  validator: async (_rule, value: string[] | null) =>
    !Array.isArray(value) ||
    value.length !== 1 ||
    !value[0] ||
    pattern.test(value[0])
      ? Promise.resolve()
      : Promise.reject(new Error("Поле заполнено неправильно")),
});

const cmpOpDict: Record<
  ZCmpOp,
  (left: number | string, right: number | string) => boolean
> = {
  GT: (left, right) => left > right,
  GE: (left, right) => left >= right,
  LE: (left, right) => left <= right,
  LT: (left, right) => left < right,
  NE: (left, right) => left !== right,
};

export const dateCmpOpLabel: Record<ZCmpOp, string> = {
  GT: "Дата должна быть позже, чем сегодня",
  GE: "Дата не должна быть раньше, чем сегодня",
  LE: "Дата не должна быть позже, чем сегодня",
  LT: "Дата должна быть раньше, чем сегодня",
  NE: "Нельзя использовать сегодняшнюю дату",
};

const checkTodayRule = (cmpOp: ZCmpOp): FormRule => ({
  validator: (_rule, value: string[] | undefined) => {
    const v0 = value?.[0];
    if (typeof v0 === "string") {
      const res = /^(\d{4}-\d{2}-\d{2})/.exec(v0);
      if (res) {
        const date = res[0];
        const today = dayjs().format("YYYY-MM-DD");
        const op = cmpOpDict[cmpOp];
        if (op && !op(date, today))
          return Promise.reject(Error(dateCmpOpLabel[cmpOp]));
      }
    }
    return Promise.resolve();
  },
});

// Валидатор для сравнения значения текущего поля с другим полем
const relativeCmpValidator =
  (rule: ZRelativeCmpRule, typeName: AttrTypeName): FormRule =>
  ({ getFieldValue }) => ({
    validator: async (_, value: string[] | null): Promise<void> => {
      const isValid = (): boolean => {
        const otherValue: string[] | null = getFieldValue(rule.otherAttrId);
        const op = cmpOpDict[rule.opCode];
        if (!op) return true;
        const cvtType = (src: string[] | null): number | string | null => {
          if (!Array.isArray(src) || src.length !== 1) return null;
          const strValue = src[0]!;
          if (strValue === "") return null;
          if (
            typeName === AttrTypeName.number ||
            typeName === AttrTypeName.int
          ) {
            const numValue = +strValue;
            return Number.isNaN(numValue) ? null : numValue;
          }
          return strValue;
        };
        const left = cvtType(value);
        const right = cvtType(otherValue);
        if (left === null || right === null) return true;
        return op(left, right);
      };
      return isValid()
        ? Promise.resolve()
        : Promise.reject(Error(rule.message));
    },
  });
