// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrForm2-module__twoCols--zZeHm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrForm2-module__blockVert--c8ovG {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/AttrForm2.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;AACJ;AAEA;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AAAJ","sourcesContent":[".twoCols {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    column-gap: 16px;\n}\n\n.blockVert {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"twoCols": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrForm2-module__twoCols--zZeHm`,
	"blockVert": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrForm2-module__blockVert--c8ovG`
};
export default ___CSS_LOADER_EXPORT___;
