/* eslint-disable react/jsx-props-no-spreading */
import React from "react";
import Icon from "@ant-design/icons";
import type { CustomIconComponentProps } from "@ant-design/icons/lib/components/Icon";

const BellIconStdSvg: React.FC<Partial<CustomIconComponentProps>> = (props) => (
  <svg
    viewBox="0 0 20 22"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    width="20"
    height="22"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8508 1.24062C14.756 2.01256 16.9509 4.55818 17.2622 7.65648C17.4082 9.11 17.5795 10.6049 17.7667 11.8515C17.971 13.212 18.6511 14.9734 19.3547 16.5136C20.2685 18.514 18.8825 21 16.5566 21H11.5C11.5 21.5523 11.0523 22 10.5 22H9.49998C8.9477 22 8.49998 21.5523 8.49998 21H3.44338C1.11748 21 -0.268568 18.514 0.645265 16.5136C1.34889 14.9734 2.02901 13.212 2.23329 11.8515C2.42047 10.6049 2.5918 9.11 2.73782 7.65648C3.04906 4.55819 5.24396 2.01256 8.14919 1.24062C8.4482 0.512637 9.16422 0 9.99998 0C10.8357 0 11.5518 0.512636 11.8508 1.24062ZM9.99999 3C7.27975 3 5.00351 5.11179 4.7278 7.85639C4.58044 9.32332 4.40533 10.8551 4.21112 12.1485C3.96257 13.8038 3.17899 15.7805 2.46443 17.3447C2.10262 18.1367 2.69932 19 3.44338 19H16.5566C17.3007 19 17.8974 18.1367 17.5355 17.3447C16.821 15.7805 16.0374 13.8038 15.7888 12.1485C15.5946 10.8551 15.4195 9.32332 15.2722 7.85639C14.9965 5.11179 12.7202 3 9.99999 3Z"
      fill="#343A41"
    />
  </svg>
);
/**
 * используется в левом меню для пункта "справочник категорий" в активном состонии
 */
export const BellIconStd: React.FC<Partial<CustomIconComponentProps>> = (
  props,
) => <Icon component={BellIconStdSvg} {...props} />;
