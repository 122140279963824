import { z } from "zod";

export const zUserInfo = z.object({
  // position: z.string(),
  firstName: z.string(),
  secondName: z.string(),
  userName: z.string(), //  "a.kirillov@omnidata.ru"
});

export type ZUserInfo = z.infer<typeof zUserInfo>;
