import { TableResponse } from "src/components/tables/TableStore";
import { apiAuditUrl } from "src/common/apiUrl";
import {
  AuditEntityStateFilters,
  ZAuditEntityState,
  zAuditEntityStateResponse,
} from "./EntityStatesHistory.types";
import { ParamsLoadHistory, apiEntityHistory } from "../apiEntityHistory";

export const apiEntityStatesHistory = async (
  params: ParamsLoadHistory<AuditEntityStateFilters>,
): Promise<TableResponse<ZAuditEntityState>> =>
  apiEntityHistory(
    apiAuditUrl("/entity-states"),
    params,
    zAuditEntityStateResponse,
  );
