import { PlusCircleFilled } from "@ant-design/icons";
import { Button, Input, Space } from "antd";
import { DefaultOptionType } from "antd/es/select";
import { Select, SelectProps } from "antd/lib";
import React, { useEffect, useState } from "react";
import { getViewInfo } from "src/common/attrView";
import { onError } from "src/common/onError";
import { loadObjectAttrinbutesReduced } from "src/pages/ManagementPage/objectsApi";

type PropsSelectGroupFromAttribute = SelectProps & { objectId?: string };

export const SelectGroupFromAttribute: React.FC<
  PropsSelectGroupFromAttribute
> = ({ objectId, ...props }) => {
  const [options, setOptions] = useState<DefaultOptionType[]>([]);
  const [name, setName] = useState("");

  const addNewGroup = () => {
    try {
      const exist = options.find((opt) => opt.value === name);
      if (!name) throw new Error("Заполните название группы");
      if (exist) throw new Error("Уже существует");
      setOptions([{ label: name, value: name }, ...options]);
    } catch (error) {
      onError(error);
    }
  };

  const init = async () => {
    if (!objectId) return;
    try {
      const atts = await loadObjectAttrinbutesReduced(objectId);
      const viewInfoList = atts.map((attr) => getViewInfo(attr.viewType));
      const groupNameMap = viewInfoList.reduce((acc, info) => {
        const key = info?.appearance?.column?.group.name;
        if (key && !acc.has(key)) acc.add(key);
        return acc;
      }, new Set<string>());

      setOptions(
        Array.from(groupNameMap).map((value) => ({ label: value, value })),
      );
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    init();
  }, [objectId]);

  return (
    <Space direction="vertical">
      <Space>
        <Space direction="vertical">
          <Input
            placeholder="Название группы"
            onChange={(e) => setName(e.target.value)}
          />
        </Space>
        <Button onClick={addNewGroup}>
          <PlusCircleFilled />
        </Button>
      </Space>

      <Select
        {...props}
        options={options}
        style={{ width: "100%" }}
        allowClear
      />
    </Space>
  );
};

SelectGroupFromAttribute.defaultProps = { objectId: undefined };
