import { z } from "zod";
import { onError } from "../onError";

const zInitialEntityParams = z.record(z.string(), z.string().array());
type ZInitialEntityParams = z.infer<typeof zInitialEntityParams>;

const queryKey = "initial";

export const makeInintialEntityParams = (
  url: string,
  params: ZInitialEntityParams,
) => {
  try {
    const paramsPart = `${queryKey}=${encodeURIComponent(JSON.stringify(params))}`;
    const hasParams = url.includes("?");
    return `${url}${hasParams ? "&" : "?"}${paramsPart}`;
  } catch (error) {
    onError(error);
    return url;
  }
};

export const parseInitialEntityParams = (params: URLSearchParams) => {
  try {
    const initial = zInitialEntityParams.parse(
      JSON.parse(decodeURIComponent(params.get(queryKey) || "{}")),
    );
    return initial;
  } catch (error) {
    onError(error);
    return {};
  }
};
