// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-EntityFiltersPage-EntityList-EntityList-module__header--GCSpX {
  padding: 8px 24px;
  display: flex;
  justify-content: end;
  border-bottom: 1px solid #e1e3e6;
  height: 60px;
}
.src-pages-EntityFiltersPage-EntityList-EntityList-module__headerInner--Gnj1U {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  gap: 10px;
}
.src-pages-EntityFiltersPage-EntityList-EntityList-module__content--CiIKX {
  height: 100%;
}
.src-pages-EntityFiltersPage-EntityList-EntityList-module__contentEmpty--vlW37 {
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
.src-pages-EntityFiltersPage-EntityList-EntityList-module__entityCount--ra1Xt {
  font-size: 14px;
  font-weight: 500;
  color: #767c84;
  padding: 22px 24px;
  border-bottom: 1px solid #e1e3e6;
  height: 60px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/EntityFiltersPage/EntityList/EntityList.module.less"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,aAAA;EACA,oBAAA;EACA,gCAAA;EACA,YAAA;AACF;AACA;EACE,aAAA;EACA,8BAAA;EACA,WAAA;EACA,mBAAA;EACA,SAAA;AACF;AACA;EACE,YAAA;AACF;AAEA;EACE,aAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,YAAA;AAAF;AAEA;EACE,eAAA;EACA,gBAAA;EACA,cAAA;EACA,kBAAA;EACA,gCAAA;EACA,YAAA;AAAF","sourcesContent":[".header {\n  padding: 8px 24px;\n  display: flex;\n  justify-content: end;\n  border-bottom: 1px solid #e1e3e6;\n  height: 60px;\n}\n.headerInner {\n  display: flex;\n  justify-content: space-between;\n  width: 100%;\n  align-items: center;\n  gap: 10px;\n}\n.content {\n  height: 100%;\n}\n\n.contentEmpty {\n  padding: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  height: 100%;\n}\n.entityCount {\n  font-size: 14px;\n  font-weight: 500;\n  color: #767c84;\n  padding: 22px 24px;\n  border-bottom: 1px solid #e1e3e6;\n  height: 60px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": `src-pages-EntityFiltersPage-EntityList-EntityList-module__header--GCSpX`,
	"headerInner": `src-pages-EntityFiltersPage-EntityList-EntityList-module__headerInner--Gnj1U`,
	"content": `src-pages-EntityFiltersPage-EntityList-EntityList-module__content--CiIKX`,
	"contentEmpty": `src-pages-EntityFiltersPage-EntityList-EntityList-module__contentEmpty--vlW37`,
	"entityCount": `src-pages-EntityFiltersPage-EntityList-EntityList-module__entityCount--ra1Xt`
};
export default ___CSS_LOADER_EXPORT___;
