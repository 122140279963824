import { ColorPicker, Form, Radio } from "antd";
import React from "react";
import { AttrViewCompName } from "src/common/attrView";
import { AttrTypeName } from "src/types/AttrType";
import { Separator } from "src/common/attrView/viewFormItems/Separator";
import { ZTagModeDictRef } from "src/common/attrView/componentsDefs";
import { obj2LabelValue } from "src/common/attrView/obj2LabelValue";
import { SelectAttributeTagColor } from "./SelectAttributeTagColor";

const labelMap: Record<ZTagModeDictRef, string> = {
  standart: "Обычный",
  tag: "Единый тэг",
  tagsMulti: "Тэги",
};

export const options = obj2LabelValue(labelMap);

type PropsDictRefViewInfo = {
  curView: AttrViewCompName | undefined;
  typeName: string;
  refId: string;
  stylesNameFn: (name: string) => string[];
  appearanceNameFn: (name: string) => string[];
};

export const DictRefViewInfo: React.FC<PropsDictRefViewInfo> = ({
  curView,
  typeName,
  refId,
  stylesNameFn,
  appearanceNameFn,
}) => {
  const mode: ZTagModeDictRef = Form.useWatch(stylesNameFn("mode"));
  const isDictRef = curView === "DictRef";
  const isDictRefMulti =
    curView === "DictRef" && typeName === AttrTypeName.dictMulti;

  if (!isDictRef) return null;

  const filtredOpt = !isDictRefMulti
    ? options.filter(({ value }) => value !== "tagsMulti")
    : options;
  return (
    <div>
      <Form.Item label="Мод" name={stylesNameFn("mode")}>
        <Radio.Group>
          {filtredOpt.map(({ label, value }) => (
            <Radio.Button key={value} value={value}>
              {label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Form.Item>
      {mode === "tag" && (
        <Form.Item
          label="цвет тэга"
          name={[...stylesNameFn("modeMeta"), "tagColor"]}
          getValueFromEvent={(e) => `#${e.toHex()}`}
        >
          <ColorPicker format="hex" />
        </Form.Item>
      )}
      {mode === "tagsMulti" && (
        <SelectAttributeTagColor
          refId={refId}
          name={[...stylesNameFn("modeMeta"), "tagsColorMap"]}
        />
      )}

      {isDictRefMulti && (
        <Separator name={[...appearanceNameFn("view"), "separator"]} />
      )}
    </div>
  );
};
