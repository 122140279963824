// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__box--WpT43 {
  border: thin solid #d9d9d9;
  border-radius: 4px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__box--WpT43 .src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__boxTitle--lMriO {
  padding: 4px 16px;
  background-color: #F8F8F8;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__box--WpT43 .src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__fields--pdNsq {
  padding: 8px 16px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__box--WpT43 .ant-form-item {
  margin-bottom: 0 !important;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__box--WpT43 .ant-form-item-label {
  padding: 0;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__editor--yihi5,
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__editor--yihi5 .src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__divider--t_3Co > span {
  background-color: #FFFFF0;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__editor--yihi5 > .src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__boxTitle--lMriO {
  background-color: #f9e2a7;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__twoCols--HKBHk {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__threeCols--TnZxY {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 10px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__divider--t_3Co {
  border-top: thin solid gray;
  font-size: 12px;
  color: gray;
  position: relative;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__divider--t_3Co > * {
  position: absolute;
  top: -11px;
  margin: 0 20px;
  padding: 0 6px;
  background: white;
  z-index: 1;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/MetaInfo/EditorInfo.module.less"],"names":[],"mappings":"AAAA;EACI,0BAAA;EACA,kBAAA;AACJ;AAHA;EAIQ,iBAAA;EACA,yBAAA;AAER;AAPA;EAQQ,iBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AAER;AAbA;EAeQ,2BAAA;AACR;AAhBA;EAmBQ,UAAA;AAAR;AAGA;;EAAkC,yBAAA;AAClC;AAAA;EAAqB,yBAAA;AAGrB;AADA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;AAGJ;AADA;EACI,aAAA;EACA,kCAAA;EACA,gBAAA;AAGJ;AAAA;EACI,2BAAA;EACA,eAAA;EACA,WAAA;EACA,kBAAA;AAEJ;AADI;EACI,kBAAA;EACA,UAAA;EACA,cAAA;EACA,cAAA;EACA,iBAAA;EACA,UAAA;AAGR","sourcesContent":[".box {\n    border: thin solid #d9d9d9;\n    border-radius: 4px;\n    .boxTitle {\n        padding: 4px 16px;\n        background-color: #F8F8F8;\n    }\n    .fields {\n        padding: 8px 16px;\n        display: flex;\n        flex-direction: column;\n        gap: 12px;\n    }\n    // Убрать пустое место под элементом формы. Вместо него gap\n    :global(.ant-form-item) {\n        margin-bottom: 0 !important;\n    }\n    // Это тоже лишнее пустое пространство\n    :global(.ant-form-item-label) {\n        padding: 0;\n    }\n}\n.editor, .editor .divider > span {background-color: #FFFFF0;}\n.editor > .boxTitle {background-color: #f9e2a7;}\n\n.twoCols {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    column-gap: 16px;\n}\n.threeCols {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr;\n    column-gap: 10px;\n}\n\n.divider {\n    border-top: thin solid gray;\n    font-size: 12px;\n    color: gray;\n    position: relative;\n    & > * {\n        position: absolute;\n        top: -11px;\n        margin: 0 20px;\n        padding: 0 6px;\n        background: white;\n        z-index: 1;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__box--WpT43`,
	"boxTitle": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__boxTitle--lMriO`,
	"fields": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__fields--pdNsq`,
	"editor": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__editor--yihi5`,
	"divider": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__divider--t_3Co`,
	"twoCols": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__twoCols--HKBHk`,
	"threeCols": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-MetaInfo-EditorInfo-module__threeCols--TnZxY`
};
export default ___CSS_LOADER_EXPORT___;
