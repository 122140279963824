import { makeAutoObservable } from "mobx";
import { TableStore } from "src/components/tables/TableStore";
import {
  ZAuditEntityState,
  AuditEntityStateFilters,
} from "./EntityStatesHistory.types";
import { apiEntityStatesHistory } from "./apiEntityStatesHistory";
import { historyLoader } from "../apiEntityHistory";

export class EntityStatesHistoryStore {
  constructor(
    public readonly entityId: number,
    public readonly stateNames: Record<number, string>,
  ) {
    makeAutoObservable(this);
  }

  tableStore = new TableStore<ZAuditEntityState, AuditEntityStateFilters>({
    rowKey: "timestamp",
    fnLoad: historyLoader(this.entityId, apiEntityStatesHistory),
  });
}
