import * as React from "react";
import i18, { t } from "i18next";
import { observer } from "mobx-react-lite";
import { getGlobalParams } from "src/common/globalParams";
import { Alert, Button, Form, Input, Row, Select, notification } from "antd";
import { appStore as store } from "src/appStore";
import { getErrorMessage } from "src/common/onError";
import { langList } from "src/lang/langList";
import styles from "./LoginPage.module.less";
import commonStyles from "../common.module.less";

const makeRef = (href: string, lang: string): string => {
  const onLang = href.includes(`/${i18.language}`);
  return onLang
    ? href.replace(`/${i18.language}`, `/${lang}`)
    : `${href}${lang}`;
};

const onNonImpl = () => notification.warning({ message: "Пока не готово" });

export const LoginPage: React.FC = observer(() => {
  const [touched, setTouched] = React.useState(false);
  const firstField = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    firstField.current?.focus();
    store.setDocumentTitle(getGlobalParams().title);
    setTouched(false);
  }, []);

  return (
    <div className={commonStyles.loginPage}>
      <div className={commonStyles.formContainer}>
        <div>
          <div className={commonStyles.langDiv}>
            <Select
              className={commonStyles.langSelect}
              defaultValue={i18.language}
              options={langList}
              onChange={(langOption) => {
                window.location.href = makeRef(
                  window.location.href,
                  langOption,
                );
              }}
            />
          </div>
          <Form
            name="login"
            onFinish={({ login, password }) => {
              store.login(login, password);
              setTouched(true);
            }}
            autoComplete="off"
            layout="vertical"
            scrollToFirstError
            size="large"
          >
            <Form.Item>
              <h2>{getGlobalParams()?.title || ""}</h2>
            </Form.Item>
            <Form.Item
              label={t("Enter email")}
              name="login"
              rules={[
                {
                  required: true,
                  message: t("The field is mandatory") || "",
                },
                {
                  pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: t("Invalid email format") || "",
                },
              ]}
              normalize={(value) => value.trim()}
            >
              <Input placeholder="Email" allowClear />
            </Form.Item>

            <Form.Item
              label={t("Password")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("The field is mandatory") || "",
                },
              ]}
            >
              <Input.Password placeholder={t("Password") || ""} />
            </Form.Item>

            {touched && store.userData.status === "error" && (
              <Form.Item>
                <Alert
                  type="error"
                  showIcon
                  {...getErrorMessage(store.userData.error)}
                />
              </Form.Item>
            )}

            <Form.Item>
              <Button
                type="link"
                size="small"
                className={styles.resetPasswordLink}
                onClick={onNonImpl}
              >
                {t("Restore password")}
              </Button>
            </Form.Item>

            <Row justify="space-between" align="middle">
              <Form.Item>
                <Button
                  disabled={store.userData.status === "wait"}
                  onClick={onNonImpl}
                >
                  {t("Register")}
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  loading={store.userData.status === "wait"}
                  htmlType="submit"
                  type="primary"
                >
                  {t("Sign In")}
                </Button>
              </Form.Item>
            </Row>
          </Form>
        </div>
        <div>
          <div className={commonStyles.loginCopyrightLabel}>
            {/* <LocalizationHref
                href="https://d4r.atlassian.net/servicedesk/customer/portal/3"
                text={t("Registration.Support")}
              /> */}
          </div>
          <div className={commonStyles.loginCopyrightLabel}>
            {getGlobalParams()?.copyright || ""}
          </div>
        </div>
      </div>
      <div className={commonStyles.loginBackground}>
        <div className={commonStyles.loginBackgroundInfo}>
          {/* <Logo /> */}
          <p className={commonStyles.loginBackgroundInfoLabel}>
            {t("Background.Header")}
          </p>
          <p className={commonStyles.loginBackgroundInfoLabelSmall}>
            {t("Background.Description")}
          </p>
          <ul className={commonStyles.loginBackgroundUl}>
            <li>{t("Background.CommOffer")}</li>
            <li>{t("Background.Revaluation")}</li>
            <li>{t("Background.Promo")}</li>
          </ul>
        </div>
      </div>
    </div>
  );
});
