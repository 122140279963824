import { ReactNode } from "react";
import i18 from "i18next";
import axios from "axios";
import { z } from "zod";
import { makeAutoObservable } from "mobx";
import { ZUserInfo, zUserInfo } from "src/types/ZUserInfo";
import { RemoteData } from "src/common/RemoteData";
import enRes from "src/lang/en";
import ruRes from "src/lang/ru";
import { detectLang } from "./common/detectLang";
import { rest } from "./common/rest";
import { apiI18nUrl } from "./common/apiUrl";
import { ZLanguageProps, zLanguageProps } from "./types/ZLanguageProps";
import { onError } from "./common/onError";
import { makeDictionary } from "./common/makeDictionary";

export type OverlayPage = "login";

const urlRefresh = "/api/auth/refresh";
const urlCurrent = "/api/auth/current";
const urlLogin = "/api/auth/login";
const urlLogout = "/api/auth/logout";

const keyRefreshToken = "refreshToken";
const setRefreshToken = (value?: string) => {
  try {
    if (value) {
      localStorage.setItem(keyRefreshToken, value);
    } else {
      localStorage.removeItem(keyRefreshToken);
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};
const getRefreshToken = (): string =>
  localStorage.getItem(keyRefreshToken) || "";

let authWait: Promise<Response> | null = null;

export type AuthTaskResult = { status: number };

export type Breadcrumb = {
  title: string;
  href?: string;
};

export const authTask = async <R extends AuthTaskResult>(
  task: () => Promise<R>,
): Promise<R> => {
  let resp: R;
  try {
    resp = await task();
  } catch (e) {
    if ("response" in e) {
      resp = e.response;
      if (resp.status !== 401) throw e;
    } else {
      throw e;
    }
  }
  if (resp.status === 401) {
    authWait =
      authWait ||
      fetch(urlRefresh, {
        method: "post",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({ refresh: getRefreshToken() }),
      });
    const resp1 = await authWait;
    authWait = null;
    if (resp1.status !== 200) {
      const error = new Error("Authorization required");
      if (appStore.userData.status !== "error") {
        appStore.setUserData({ status: "error", error });
      }
      appStore.setOverlayType("login");
      throw error;
    }
    resp = await task();
  }
  return resp;
};

export const appStore = makeAutoObservable({
  userData: {} as RemoteData<ZUserInfo>,
  setUserData(data: RemoteData<ZUserInfo>) {
    this.userData = data;
    this.setCurrentCompanyId(0); // TODO: Пока нет такого поля...
  },
  get userInfo(): ZUserInfo {
    if (this.userData.status !== "ready") throw Error("Empty user data");
    return this.userData.result;
  },
  currentCompanyId: 0,
  setCurrentCompanyId(id: number) {
    this.currentCompanyId = id;
  },

  overlayType: null as OverlayPage | null,
  setOverlayType(newType: OverlayPage | null) {
    this.overlayType = newType;
  },

  contentLanguages: [] as ZLanguageProps[],
  setContentLanguages(list: ZLanguageProps[]) {
    this.contentLanguages = list;
  },
  get contentLangMap(): Record<string, ZLanguageProps> {
    return makeDictionary(this.contentLanguages, ({ code }) => code);
  },

  async init() {
    this.setUserData({ status: "wait" });

    try {
      const resp = await authTask(() => fetch(urlCurrent));
      if (resp.status !== 200) throw Error(`Status ${resp.status}`);
      const rawData = await resp.json();
      this.setUserData({ status: "ready", result: zUserInfo.parse(rawData) });
    } catch (error) {
      this.setUserData({ status: "error", error });
    }

    // Список языков контента.
    // Будем считать, что он не является жизненно необходимым для работы приложения
    rest
      .get(apiI18nUrl("/languages"))
      .then((langResp) =>
        this.setContentLanguages(zLanguageProps.array().parse(langResp.data)),
      )
      .catch(onError);
  },

  async login(login: string, password: string) {
    try {
      this.setUserData({ status: "wait" });
      const respLogin = await axios.post(urlLogin, { login, password });
      const zLogin = z.object({
        refreshToken: z.string(),
      });
      const loginResult = zLogin.parse(respLogin.data);
      setRefreshToken(loginResult.refreshToken);

      const respCurrent = await axios.get(urlCurrent);
      const currentResult: ZUserInfo = zUserInfo.parse(respCurrent.data);
      this.setUserData({ status: "ready", result: currentResult });
      this.setOverlayType(null);
    } catch (e) {
      const error =
        e.response?.status === 401
          ? new Error("Нет пользователя с указанными данными")
          : e;
      this.setUserData({ status: "error", error });
    }
  },

  async logout() {
    setRefreshToken(undefined);
    this.setOverlayType("login");
    axios.get(urlLogout); // Не ждём ответа, т.к. он не нужен.
  },

  pageTitle: "" as ReactNode,
  setPageTitle(title: ReactNode) {
    this.pageTitle = title;
  },

  breadcrumb: [] as Breadcrumb[],
  setBreadcrumb(breadcrumb: Breadcrumb[]) {
    this.breadcrumb = breadcrumb;
  },

  setDocumentTitle(documentTitle: string) {
    document.title = documentTitle;
  },

  get curLang(): string {
    return i18.language;
  },
});

const curLang = detectLang();

i18.init({
  lng: curLang,
  debug: window.location.href.startsWith("http://localhost"),
  resources: {
    en: enRes,
    ru: ruRes,
  },
});
