import * as React from "react";
import {
  FilterFieldsDict,
  FiltersForm,
} from "src/components/tables/FiltersForm";
import { observer } from "mobx-react-lite";
import { TableStore } from "src/components/tables/TableStore";
import { Pagination, PaginationProps } from "antd";
import { AsyncTimeline } from "../AsyncTimeline";
import styles from "./TimelineFacade.module.less";

interface PropsTimelineFacade<TRow, TFilters extends object> {
  store: TableStore<TRow, TFilters>;
  filterItems?: FilterFieldsDict<TFilters>;
  title?: string;
  renderRow: (row: TRow) => React.ReactNode;
}

export const TimelineFacade = observer(
  <TRow extends {}, TFilters extends object = {}>(
    props: PropsTimelineFacade<TRow, TFilters>,
  ): React.ReactElement => {
    const { store, filterItems, renderRow, title } = props;

    const pagination: PaginationProps = {
      current: store.page + 1,
      pageSize: store.pageSize,
      showSizeChanger: true,
      total: store.totalItems,
      onShowSizeChange: (current, size) => store.setPageSize(size),
      onChange: (page) => store.setPage(page - 1),
    };

    return (
      <div className={styles.container}>
        <div className={styles.header}>
          {!!title && <div className={styles.title}>{title}</div>}
          {!!filterItems && (
            <FiltersForm<TFilters>
              store={store}
              items={filterItems}
              formClassName={styles.filters}
            />
          )}
        </div>
        <div className={styles.timeline}>
          <AsyncTimeline<TRow, TFilters> store={store} renderRow={renderRow} />
        </div>
        <Pagination className={styles.pagination} {...pagination} />
      </div>
    );
  },
);
