import { DefaultOptionType } from "antd/es/select";
import { Select, SelectProps } from "antd/lib";
import React, { useEffect, useState } from "react";
import { onError } from "src/common/onError";
import { ZAttribute } from "src/types/ZAttribute";

type PropsSelectAttributeFlat = SelectProps & {
  objectId: string;
  loader: (objectId: string) => Promise<ZAttribute[]>;
};

export const SelectAttributeFlat: React.FC<PropsSelectAttributeFlat> = ({
  objectId,
  loader,
  ...props
}) => {
  const [options, setOptions] = useState<DefaultOptionType[]>([]);

  const init = async () => {
    if (!objectId) return;
    try {
      const atts = await loader(objectId);
      setOptions(
        atts.map((attr) => ({ label: attr.name, value: String(attr.id) })),
      );
    } catch (error) {
      onError(error);
    }
  };

  useEffect(() => {
    init();
  }, [objectId]);

  return <Select {...props} options={options} style={{ width: "100%" }} />;
};
