import * as React from "react";
import { Button, Form, FormRule, Input, InputRef } from "antd";
import { DeleteOutlined, SisternodeOutlined } from "@ant-design/icons";
import { delay } from "src/common/delay";
import { ZGroup } from "src/types/ZGroup";
import { trimRequired } from "src/common/validationRules/trimRequired";
import { Dict2TabStore } from "../Dict2TabStore";
import { FieldsWithTools } from "../../FieldsWithTools";

interface PropsGroupFields2 {
  store: Dict2TabStore;
}

export const GroupFields2: React.FC<PropsGroupFields2> = ({ store }) => {
  const ref1 = React.useRef<InputRef>(null);
  React.useEffect(() => {
    delay(10).then(() => ref1.current?.focus());
  }, [store.currentItem]);
  return (
    <FieldsWithTools
      tools={
        <>
          <Button
            icon={<SisternodeOutlined />}
            onClick={() => store.safeAddAttr()}
          >
            Добавить справочник
          </Button>

          <Button
            icon={<DeleteOutlined />}
            danger
            onClick={() => {
              store.startDeleteCurNode();
            }}
          >
            Удалить группу
          </Button>
        </>
      }
    >
      <Form.Item
        name="name"
        label="Название группы"
        rules={[
          trimRequired(),
          validateUniqueName(store.groupsList, store.currentItem?.id),
        ]}
      >
        <Input ref={ref1} allowClear />
      </Form.Item>
    </FieldsWithTools>
  );
};

const validateUniqueName = (
  groupsList: ZGroup[],
  currentId: number | undefined,
): FormRule => ({
  validator: (rule, value) => {
    if (
      value &&
      groupsList.find(({ id, name }) => id !== currentId && name === value)
    ) {
      return Promise.reject(Error("Название уже использовано"));
    }
    return Promise.resolve();
  },
});
