import React from "react";
import { ZSeparator } from "src/common/attrView/viewFormItems/Separator";
import { AttrTypeName } from "src/types/AttrType";

export type LabelComposeConfig = {
  separator?: ZSeparator;
  replace?: Partial<
    Record<AttrTypeName, (content: React.ReactNode) => React.ReactNode>
  >;
};

export const getActualComposeView = (
  valueType: AttrTypeName,
  view: React.ReactNode,
  config?: LabelComposeConfig,
) => {
  const { replace } = config || {};
  const instead = replace?.[valueType];
  if (!instead) return view;
  return instead(view);
};
