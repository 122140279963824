import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { authTask, AuthTaskResult } from "src/appStore";

export const rest = {
  get<T = unknown, R extends AuthTaskResult = AxiosResponse<T>, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return authTask(() => axios.get(url, config));
  },
  post<T = unknown, R extends AuthTaskResult = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return authTask(() => axios.post(url, data, config));
  },
  put<T = unknown, R extends AuthTaskResult = AxiosResponse<T>, D = unknown>(
    url: string,
    data?: D,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return authTask(() => axios.put(url, data, config));
  },
  delete<T = void, R extends AuthTaskResult = AxiosResponse<T>, D = unknown>(
    url: string,
    config?: AxiosRequestConfig<D>,
  ): Promise<R> {
    return authTask(() => axios.delete(url, config));
  },
};
