import { observer } from "mobx-react-lite";
import React from "react";
import { appStore } from "src/appStore";
import { classNames } from "src/common/classNames";
import { LangSwitcher } from "src/components/LangSwitcher";
import { BellIconStd } from "src/common/icons";
import { UserMenu } from "src/components/UserMenu";
import { Breadcrumb } from "antd";
import styles from "./Header.module.less";

export const Header: React.FC = observer(() => (
  <div className={styles.header}>
    {appStore.breadcrumb.length !== 0 ? (
      <Breadcrumb
        className={classNames([styles.headerItem, styles.headerBreadcrumb])}
        separator="/"
        items={appStore.breadcrumb}
      />
    ) : (
      <h1 className={classNames([styles.headerItem, styles.headerTitle])}>
        {appStore.pageTitle}
      </h1>
    )}
    <div className={styles.headerItem}>
      <BellIconStd />
    </div>
    <div className={styles.headerItem}>
      <UserMenu />
    </div>
    <div className={styles.headerItem}>
      <LangSwitcher />
    </div>
  </div>
));
