import * as React from "react";
import { SearchOutlined } from "@ant-design/icons";
import { Input, Spin, Tree } from "antd";
import { observer } from "mobx-react-lite";
import { SelectDictionaryStore } from "./SelectDictionaryStore";

/* eslint react/require-default-props: "off" */

interface PropsSelectDictionary {
  value?: number;
  onChange?(value?: number): void;
  store: SelectDictionaryStore;
  disabled?: boolean;
}

export const SelectDictionary: React.FC<PropsSelectDictionary> = observer(
  (props) => {
    const { value, onChange, store, disabled } = props;

    const onSelect = (keys: React.Key[]) => {
      const attr = store.onSelect(keys);
      onChange?.(attr ? attr.id : undefined);
    };

    const onSearch: React.ChangeEventHandler<HTMLInputElement> = (e) => {
      store.onSearch(e.target.value);
    };

    // Внешнее изменение значения (например, выбран другой атрибут)
    React.useEffect(() => {
      store.onNewValue(value);
    }, [value]);

    React.useEffect(() => {
      store.init();
    }, []);

    return (
      <Spin spinning={store.wait}>
        <div>
          <Input
            placeholder="Поиск по названию справочника"
            value={store.searchText}
            onChange={onSearch}
            prefix={<SearchOutlined />}
            disabled={disabled}
            className="idSearch"
          />
          <Tree
            treeData={store.treeData}
            onSelect={onSelect}
            selectedKeys={store.selected}
            onExpand={(keys) => store.onExpand(keys)}
            expandedKeys={store.expandedKeys}
            disabled={disabled}
          />
        </div>
      </Spin>
    );
  },
);
