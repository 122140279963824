import { observer } from "mobx-react-lite";
import * as React from "react";
import { Select } from "antd";
import { FilterFieldsDict } from "src/components/tables/FiltersForm";
import dayjs from "dayjs";
import { dateTimeViewFormat } from "src/common/formats";
import {
  AuditEntityStateFilters,
  ZAuditEntityState,
} from "./EntityStatesHistory.types";
import { TimelineFacade } from "../TimelineFacade/TimelineFacade";
import { EntityStatesHistoryStore } from "./EntityStatesHistoryStore";
import { RangePickerString } from "../RangePickerString";
import styles from "./EntityStatesHistory.module.less";

interface PropsEntityStatesHistory {
  store: EntityStatesHistoryStore;
}

export const EntityStatesHistory: React.FC<PropsEntityStatesHistory> = observer(
  ({ store }) => {
    const renderRow = (row: ZAuditEntityState): React.ReactNode => (
      <div className={styles.timelineChild}>
        <div className={styles.state}>
          {store.stateNames[row.stateId] ?? "Состояние было удалено"}
        </div>
        <div className={styles.changeInfo}>
          <span className={styles.timestamp}>
            {dayjs(row.timestamp).format(dateTimeViewFormat())}
          </span>
          {/* <span className={styles.fio}>
              <EditFilled />
              {row.fio ?? "N/A"}
            </span> */}
        </div>
      </div>
    );

    const filterItems: FilterFieldsDict<AuditEntityStateFilters> = {
      timestamp: {
        render: () => <RangePickerString />,
        itemProps: { label: "Показать за период" },
        defaultValue: null,
      },
      // userId: {
      //   render: () => <Select placeholder="Выбрать" />,
      //   itemProps: { label: "Пользователь" },
      //   defaultValue: null,
      // },
      stateId: {
        render: () => (
          <Select
            options={Object.keys(store.stateNames).map((stateKey) => {
              const state = Number(stateKey);
              return {
                label: store.stateNames[state],
                value: state,
              };
            })}
            placeholder="Выбрать"
          />
        ),
        itemProps: { label: "Состояние" },
        defaultValue: null,
      },
    };

    return (
      <TimelineFacade
        store={store.tableStore}
        renderRow={renderRow}
        filterItems={filterItems}
        title="Изменение состояния экземпляра"
      />
    );
  },
);
