// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-ModelessForm-ModelessForm-module__common--oIEQK {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.src-components-ModelessForm-ModelessForm-module__statusBox--mFhiE {
  display: flex;
  flex-direction: row;
  gap: 8px;
}
.src-components-ModelessForm-ModelessForm-module__status--yZJ8B {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}
.src-components-ModelessForm-ModelessForm-module__formBox--mr_Rq {
  flex: 1;
}
.src-components-ModelessForm-ModelessForm-module__form--k3svX {
  max-width: 900px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ModelessForm/ModelessForm.module.less"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AACJ;AACA;EACI,aAAA;EACA,mBAAA;EACA,QAAA;AACJ;AACA;EACI,OAAA;EACA,aAAA;EACA,mBAAA;EACA,QAAA;EACA,mBAAA;AACJ;AACA;EACI,OAAA;AACJ;AACA;EACI,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,SAAA;AACJ","sourcesContent":[".common {\n    display: flex;\n    flex-direction: column;\n    gap: 8px;\n}\n.statusBox {\n    display: flex;\n    flex-direction: row;\n    gap: 8px;\n}\n.status {\n    flex: 1;\n    display: flex;\n    flex-direction: row;\n    gap: 8px;\n    align-items: center;\n}\n.formBox {\n    flex: 1;\n}\n.form {\n    max-width: 900px;\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"common": `src-components-ModelessForm-ModelessForm-module__common--oIEQK`,
	"statusBox": `src-components-ModelessForm-ModelessForm-module__statusBox--mFhiE`,
	"status": `src-components-ModelessForm-ModelessForm-module__status--yZJ8B`,
	"formBox": `src-components-ModelessForm-ModelessForm-module__formBox--mr_Rq`,
	"form": `src-components-ModelessForm-ModelessForm-module__form--k3svX`
};
export default ___CSS_LOADER_EXPORT___;
