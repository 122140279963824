import * as React from "react";
import { DatePicker } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { RangePickerDateProps } from "antd/es/date-picker/generatePicker";
import { dateTimeViewFormat, dateViewFormat } from "src/common/formats";

/* eslint react/jsx-props-no-spreading: "off" */

export type PropsRangePickerString = Omit<
  RangePickerDateProps<Dayjs>,
  "onChange" | "value"
> & {
  value?: string[];
  onChange?(newValue?: string[]): void;
  srcFormat?: string;
};

type RangeValue = [Dayjs | null, Dayjs | null] | null;

const { RangePicker } = DatePicker;

/**
 * Компонент для выбора интервала дат (и времени), который использует строковый формат данных
 * @param props
 */
export const RangePickerString: React.FC<PropsRangePickerString> = (
  props: PropsRangePickerString,
) => {
  const { value, onChange, srcFormat, format, showTime } = props;
  const valueFormat = srcFormat || `YYYY-MM-DD${showTime ? "THH:mm" : ""}`;
  const dayjsValue: [Dayjs, Dayjs] | undefined = value
    ? [dayjs(value[0]), dayjs(value[1])]
    : undefined;
  const dstFormat =
    format || (showTime ? dateTimeViewFormat() : dateViewFormat());

  const onChangeProxy = (dates: RangeValue): void => {
    if (!dates) {
      onChange?.(undefined);
    } else {
      const formattedDates = dates.map(
        (date) => date?.format(valueFormat) || "",
      );
      onChange?.(formattedDates);
    }
  };

  return (
    <RangePicker
      {...props}
      placeholder={["От", "До"]}
      value={dayjsValue}
      onChange={onChangeProxy}
      format={dstFormat}
      style={{ width: "100%" }}
    />
  );
};

RangePickerString.defaultProps = {
  value: undefined,
  onChange: undefined,
  srcFormat: undefined,
};
