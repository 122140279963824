import * as React from "react";
import { Button, FormInstance, Radio, notification } from "antd";
import { onError } from "src/common/onError";
import { t } from "i18next";
import styles from "./EntityStates.module.less";
import { ZEntityAvailableState } from "../../EntityPage.types";

interface PropsEntityStates {
  currentState: React.ReactNode;
  availableStates: ZEntityAvailableState[] | undefined;
  onChangeState:
    | ((stateId: number | string, form: FormInstance) => Promise<void>)
    | undefined;
  form: FormInstance;
}

export const EntityStates: React.FC<PropsEntityStates> = (props) => {
  const { currentState, availableStates = [], onChangeState, form } = props;
  const options = React.useMemo(
    () =>
      availableStates.map(({ stateId, workflowName }) => ({
        value: stateId,
        label: workflowName,
      })),
    [availableStates],
  );
  const [selected, setSelected] = React.useState<number | undefined>();
  const [wait, setWait] = React.useState(false);
  const onSend = () => {
    if (selected) {
      setWait(true);
      onChangeState?.(selected, form)
        .then(() =>
          notification.success({
            message: "Выполнено изменение состояния",
          }),
        )
        .catch(onError)
        .finally(() => setWait(false));
    }
  };
  return (
    <div className={styles.box}>
      <div>
        <div className={styles.label}>{t("Current state")}:</div>
        {currentState}
      </div>
      {availableStates.length > 0 && (
        <div>
          <div className={styles.label}>{t("Next state")}:</div>
          <div className={styles.form}>
            <Radio.Group
              options={options}
              value={selected}
              onChange={(e) => setSelected(e.target.value)}
            />
            <Button
              type="primary"
              size="small"
              disabled={!selected}
              onClick={onSend}
              loading={wait}
            >
              {t("Send")}
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
