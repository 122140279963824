import { ZValueRestriction } from "src/types/ZRestriction";

export type AttrRestrictions = Record<number, number[]>; // primaryId => secondaryId[]

export const cvtRestrictionsFlatToDict = (
  pairs: ZValueRestriction[],
): AttrRestrictions => {
  const dict: AttrRestrictions = {};
  pairs.forEach(({ sourceId, restrictionId }) => {
    dict[restrictionId] = dict[restrictionId] ?? [];
    dict[restrictionId]?.push(sourceId);
  });
  return dict;
};

export const cvtRestrictionsDictToFlat = (
  dict: AttrRestrictions,
): ZValueRestriction[] => {
  const pairs: ZValueRestriction[] = [];
  Object.entries(dict).forEach(([key, row]) => {
    const restrictionId = +key;
    row.forEach((sourceId) => {
      pairs.push({ restrictionId, sourceId });
    });
  });
  return pairs;
};

export const isRestrictionDictEmpty = (dict: AttrRestrictions): boolean => {
  const nonEmpty = Object.values(dict).find(({ length }) => length > 0);
  return !nonEmpty;
};
