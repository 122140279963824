import { PlusCircleFilled } from "@ant-design/icons";
import { Button, Drawer, Select, Space, Spin } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { EntityFiltersTable } from "src/pages";
import { observer } from "mobx-react-lite";
import { t } from "i18next";
import { ZAttrViewInfo } from "src/common/attrView";
import { ObjectRefSelectorStore } from "./ObjectRefSelectorStore";
import styles from "./ObjectRefSelector.module.less";
import { SelectedTags } from "./SelectedTags";

type PropsObjectRefSelector = {
  objectId: string;
  multiple: boolean;
  viewInfo: ZAttrViewInfo | undefined;
  labelAtts?: string[] | null;
  value?: string[];
  disabled?: boolean;
  onChange?: (value?: string[]) => void;
  label: string;
};

export const ObjectRefSelector: React.FC<PropsObjectRefSelector> = observer(
  ({
    objectId,
    multiple,
    labelAtts,
    value,
    disabled,
    viewInfo,
    onChange,
    label,
  }) => {
    const [open, setOpen] = useState(false);
    const store = useMemo(() => new ObjectRefSelectorStore(), []);
    const { filterStore } = store;
    const objectIdNum = Number(objectId);

    useEffect(() => {
      if (!objectId) return;
      store.setViewInfo(viewInfo);
      store.init(objectIdNum, value, labelAtts, multiple, onChange);
    }, [objectId]);

    useEffect(() => {
      if (!objectId) return;
      store.refresh(value || []);
    }, [value]);

    const handleClose = () => {
      setOpen(false);
      onChange?.(store.initialValues ?? undefined);
    };

    if (!filterStore) return null;

    return (
      <Spin spinning={store.loading}>
        <Select
          disabled={disabled}
          className={styles.select}
          open={false}
          value={value}
          loading={store.loading}
          mode={multiple ? "tags" : undefined}
          options={store.options}
          suffixIcon={
            !disabled && (
              <PlusCircleFilled
                onClick={() => {
                  setOpen(true);
                }}
              />
            )
          }
          onChange={(list) => {
            onChange?.(list);
            store.onSelect(list);
          }}
        />
        <Drawer
          width="75vw"
          open={open && !disabled}
          onClose={handleClose}
          className={styles.drawer}
          title={label}
          footer={
            <Space>
              <Button
                type="primary"
                onClick={() => {
                  setOpen(false);
                  store.setInitialValues(value || []);
                }}
              >
                {t("Save")}
              </Button>
              <Button onClick={handleClose}>{t("Cancel")}</Button>
            </Space>
          }
        >
          <SelectedTags store={store} onChange={onChange} />
          <EntityFiltersTable store={filterStore} />
        </Drawer>
      </Spin>
    );
  },
);

ObjectRefSelector.defaultProps = {
  value: undefined,
  onChange: undefined,
};
