import * as React from "react";
import { ZIdName } from "src/types/ZIdName";
import { Form, FormRule, Spin } from "antd";
import { edAttrField } from "../../EdAttribute";
import { PrimaryDirectionMode, RestrictionsTable } from "../RestrictionsTable";
import { isRestrictionDictEmpty } from "../RestrictionsTable/restrictionUtils";
import { loadDict, loadFromAttr } from "../../../objectsApi";

interface PropsRestrictionsTableField {
  disabled?: boolean;
}

export const RestrictionsTableField: React.FC<PropsRestrictionsTableField> = ({
  disabled,
}) => {
  const slaveDictId = Form.useWatch(edAttrField.referenceId);
  const masterAttrId = Form.useWatch(edAttrField.masterAttrId);

  const [lockCounter, setLockCounter] = React.useState(0);
  const changeLock = (step: -1 | 1) =>
    setLockCounter((prevCounter) => prevCounter + step);
  const lock = () => changeLock(1);
  const unlock = () => changeLock(-1);

  // Управление режимом для направления первичного словаря
  const [direction, setDirection] =
    React.useState<PrimaryDirectionMode>("column");

  const [masterDict, setMasterDict] = React.useState<ZIdName[]>([]);
  React.useEffect(() => {
    if (masterAttrId) {
      lock();
      loadFromAttr(masterAttrId)
        .then((list) => setMasterDict(list))
        .finally(unlock);
    } else {
      setMasterDict([]);
    }
  }, [masterAttrId]);

  const [slaveDict, setSlaveDict] = React.useState<ZIdName[]>([]);
  React.useEffect(() => {
    if (slaveDictId) {
      lock();
      loadDict(slaveDictId)
        .then((list) => setSlaveDict(list))
        .finally(unlock);
    } else {
      setSlaveDict([]);
    }
  }, [slaveDictId]);

  if (masterDict.length === 0 || slaveDict.length === 0) {
    return <div>Не указаны подходящие справочники</div>;
  }

  return (
    <Spin spinning={lockCounter !== 0}>
      <Form.Item
        name={edAttrField.restrictions}
        rules={[ruleNonEmptyRestrictions]}
      >
        <RestrictionsTable
          primaryDictionary={masterDict}
          secondaryDictionary={slaveDict}
          direction={direction}
          onChangeDirection={(mode) => setDirection(mode)}
          disabled={disabled}
        />
      </Form.Item>
    </Spin>
  );
};

RestrictionsTableField.defaultProps = {
  disabled: false,
};

const ruleNonEmptyRestrictions: FormRule = {
  validator: (rule, value) => {
    if (value && isRestrictionDictEmpty(value)) {
      return Promise.reject(Error("Не указано ни одной зависимости"));
    }
    return Promise.resolve();
  },
};
