import { ZMenuItem } from "src/components/MainFrame/LeftMenu/ZMenuItem";
import { MenuTreeItem } from "../Menu2TreeItem";

export const createTreeData = (menuItems: ZMenuItem[]): MenuTreeItem[] =>
  menuItems.map((menuItem) => createTreeItem(menuItem));

export const createTreeItem = (menuItem: ZMenuItem): MenuTreeItem => {
  const { elements, ...menuItemLight } = menuItem;
  return {
    key: menuItemLight.id,
    title: menuItemLight.name.length === 0 ? "Новый пункт меню" : menuItem.name,
    ...menuItemLight,
    ...(elements?.length && { children: createTreeData(elements) }),
  };
};
