import * as React from "react";
import { ZObjectItem } from "src/types/ZObjectItem";
import {
  LoadingOutlined,
  LockOutlined,
  NodeIndexOutlined,
  ProductOutlined,
} from "@ant-design/icons";
import { ZAttribute } from "src/types/ZAttribute";
import { GroupType, ZGroup } from "src/types/ZGroup";
import { ZIdName } from "src/types/ZIdName";
import { onError } from "src/common/onError";
import { Permission, hasPermissionIn } from "src/types/Permission";
import { loadFromAttr, loadValueThings } from "../../objectsApi";
import {
  Actions,
  AttributeO2,
  CommonNodeO2,
  GroupO2,
  ValueO2,
  ZLightGroup,
  ZLightObject,
  makeAttrKey2,
  makeGroupKey2,
  makeObjKey2,
  makeValueKey2,
} from "../Obj2Nodes";
import { iconGroupDict, iconGroupMnemonic } from "./itemIcons";
import { getTypeIcon } from "./typeIcons";

/* eslint no-param-reassign: "off" */

const mergeActions = (a1: Actions, a2: Actions): Actions => ({
  update: a1.update && a2.update,
  delete: a1.delete && a2.delete,
});

export const createMainTree = (objects: ZObjectItem[]): CommonNodeO2[] =>
  objects.map(createObjNode2);

const makeTitle = (
  name: string,
  id: number,
  disabled: boolean,
  newName: string,
): React.ReactNode => {
  if (!name) return <i>{newName}</i>;
  const extName = `${name} (${id})`;
  if (disabled)
    return (
      <span style={{ color: "#999" }}>
        {extName} <LockOutlined />
      </span>
    );
  return extName;
};

export const createObjNode2 = (object: ZObjectItem): CommonNodeO2 => {
  const key = makeObjKey2(object.id);
  const actions: Actions = {
    update: hasPermissionIn(object, Permission.objUpdate),
    delete: hasPermissionIn(object, Permission.objDelete),
  };
  const { attributes, groups, ...fields } = object;
  const children: CommonNodeO2[] = [
    ...onlyOwnerAttrs(attributes, actions, String(key)),
    ...onlyOwnerGroups(groups, actions),
  ];
  return {
    key,
    get title(): React.ReactNode {
      return makeTitle(
        this.object.name,
        this.object.id,
        !this.actions.update,
        "Новый объект",
      );
    },
    type: "obj",
    object: fields,
    icon: <ProductOutlined />,
    children,
    isLeaf: children.length === 0,
    actions,
  };
};

export const updateObjNode2 = (
  node: CommonNodeO2 | undefined,
  object: ZLightObject,
) => {
  if (node && node.type === "obj") {
    // node.title = object.name || <i>Новый объект</i>;
    node.object = object;
    node.key = makeObjKey2(object.id);
    node.isLeaf = !node.children?.length;
  }
};

const onlyOwnerAttrs = (
  attributes: ZAttribute[] | null | undefined,
  ownerActions: Actions,
  ownerKey: string,
): AttributeO2[] =>
  (attributes ?? [])
    .filter(({ valueId }) => !valueId)
    .map((a) => createAttrNode2(a, ownerActions, ownerKey));

const onlyOwnerGroups = (
  groups: ZGroup[] | null | undefined,
  ownerActions: Actions,
): GroupO2[] =>
  (groups ?? [])
    .filter(({ valueId }) => !valueId)
    .map((g) => createGroupNode2(g, ownerActions));

export const createAttrNode2 = (
  srcAttr: ZAttribute,
  ownerActions: Actions,
  ownerKey: string,
): AttributeO2 => {
  const actions = mergeActions(ownerActions, {
    update: hasPermissionIn(srcAttr, Permission.attrUpdate),
    delete: hasPermissionIn(srcAttr, Permission.attrDelete),
  });
  return {
    key: makeAttrKey2(srcAttr.id, ownerKey),
    get title(): React.ReactNode {
      return makeTitle(
        this.attr.name,
        this.attr.id,
        !this.actions.update,
        "Новый атрибут",
      );
    },
    type: "attr",
    attr: srcAttr,
    icon: getTypeIcon(srcAttr.valueType),
    isLeaf: true,
    actions,
  };
};

export const updateAttrNode2 = (
  node: AttributeO2,
  newAttr: ZAttribute,
  ownerKey: React.Key,
) => {
  // Тут мы получаеи warning:
  // Since strict-mode is enabled, changing (observed) observable values without using an action is not allowed.
  // Но пока оно работает нормально - не будем усложнять
  node.attr = newAttr;
  node.key = makeAttrKey2(newAttr.id, ownerKey);
  node.icon = getTypeIcon(newAttr.valueType);
};

export const updateAllAttrNodes = (
  newAttr: ZAttribute,
  nodes: CommonNodeO2[] | undefined,
  ownerKey: React.Key,
) => {
  nodes?.forEach((node) => {
    if (node.type === "attr" && node.attr.id === newAttr.id) {
      updateAttrNode2(node, newAttr, ownerKey);
    }
    updateAllAttrNodes(newAttr, node.children, node.key);
  });
};

export const updateGroupNode2 = (node: GroupO2, newGroup: ZLightGroup) => {
  node.group = newGroup;
  node.key = makeGroupKey2(newGroup.id);
  node.isLeaf = false; // !node.children?.length;
};

export const loadValues = async (self: GroupO2) => {
  if (self.group.groupType.name === GroupType.Dictionary) {
    self.icon = <LoadingOutlined />;
    try {
      const { attributeId } = self.group;
      if (!attributeId) {
        self.children = undefined;
      } else {
        const values = await loadFromAttr(attributeId);
        self.children = values.map((v) =>
          createValueNodeO2(self.group.id, v, self.actions),
        );
      }
    } finally {
      self.icon = iconGroupDict;
    }
  }
};

export const createGroupNode2 = (
  group: ZGroup,
  ownerActions: Actions,
): GroupO2 => {
  const key = makeGroupKey2(group.id);
  const { attributes, groups, ...lightGroup } = group;
  const actions: Actions = mergeActions(ownerActions, {
    update: true,
    delete: true,
  });
  if (group.groupType?.name === GroupType.Dictionary) {
    // При создании условной группы мы не загружаем сразу подчинённые значения
    // Загрузка происходит по событию onExpand
    const node: GroupO2 = {
      key,
      get title(): React.ReactNode {
        return makeTitle(
          this.group.name,
          this.group.id,
          !this.actions.update,
          "Новая группа",
        );
      },
      type: "group",
      group: lightGroup,
      icon: iconGroupDict,
      // Если есть ссылка на атрибут, значит не лист.
      isLeaf: !lightGroup.attributeId,
      async onExpand(self: GroupO2) {
        if (!!self.children || !self.group.attributeId) return;
        await loadValues(self);
      },
      actions,
    };
    return node;
  }
  const children = [
    ...onlyOwnerGroups(groups, actions),
    ...onlyOwnerAttrs(attributes, actions, String(key)),
  ];
  return {
    key,
    get title(): React.ReactNode {
      return makeTitle(
        this.group.name,
        this.group.id,
        !this.actions.update,
        "Новая группа",
      );
    },
    type: "group",
    group: lightGroup,
    icon: iconGroupMnemonic,
    children,
    isLeaf: children.length === 0,
    actions,
  };
};

const iconValue = <NodeIndexOutlined />;

const createValueNodeO2 = (
  groupId: number,
  value: ZIdName,
  ownerActions: Actions,
): ValueO2 => ({
  key: makeValueKey2(groupId, value.id),
  title: makeTitle(value.name, value.id, false, ""),
  icon: iconValue,
  isLeaf: false,
  type: "value",
  value,
  groupId,
  things: { status: "none" },
  actions: ownerActions,
});

export const addNodeToValue = (node: CommonNodeO2, valueNode: ValueO2) => {
  valueNode.children = [...(valueNode.children ?? []), node];
  valueNode.isLeaf = false;
};

export const activateValueNodeO2 = async (valueNode: ValueO2) => {
  const actions: Actions = mergeActions(valueNode.actions, {
    update: true,
    delete: true,
  });
  const { things } = valueNode;
  if (things.status !== "none") return;
  try {
    valueNode.things = { status: "wait" };
    valueNode.icon = <LoadingOutlined />;
    const result = await loadValueThings(valueNode.groupId, valueNode.value.id);
    valueNode.children = [
      ...result.groups.map((g) => createGroupNode2(g, actions)),
      ...result.attributes.map((a) =>
        createAttrNode2(a, actions, String(valueNode.key)),
      ),
    ];
    valueNode.isLeaf = valueNode.children.length === 0;
    valueNode.things = { status: "ready", result };
  } catch (e) {
    onError(e);
    valueNode.things = { status: "none" };
  } finally {
    valueNode.icon = iconValue;
  }
};
