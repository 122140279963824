import React, { useEffect, useState } from "react";
import { ZAttribute } from "src/types/ZAttribute";
import {
  ZSeparator,
  seedSeparators,
} from "src/common/attrView/viewFormItems/Separator";
import { loadDict } from "src/pages/ManagementPage/objectsApi";
import { getViewInfo } from "src/common/attrView";
import { ZEntityField } from "src/types/ZEntity";

const getDictAttrLabel = async (
  objectAttr: ZAttribute,
  entityAttr: ZEntityField,
  separator?: ZSeparator,
) => {
  if (!objectAttr.referenceId) return null;
  const dict = await loadDict(objectAttr.referenceId);
  const keySet = new Set(entityAttr.values);
  const viewInfo = getViewInfo(objectAttr.viewType);
  const actualSeparator = separator || viewInfo?.appearance?.view?.separator;
  const values = seedSeparators(
    dict
      .filter((item) => keySet.has(String(item.id)))
      .map((item) => ({ content: item.name, key: item.id })),
    actualSeparator,
  );
  return values;
};

type PropsDictRefInner = {
  objectAttr: ZAttribute;
  entityAttr: ZEntityField;
  separator?: ZSeparator;
};

export const DictRefInner: React.FC<PropsDictRefInner> = ({
  entityAttr,
  objectAttr,
  separator,
}) => {
  const [label, setLabel] = useState<React.ReactNode[] | null>(null);
  const init = async () => {
    setLabel(await getDictAttrLabel(objectAttr, entityAttr, separator));
  };

  useEffect(() => {
    init();
  }, []);

  return label;
};

DictRefInner.defaultProps = { separator: undefined };
