import { Tag } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import styles from "./EntityHistory.module.less";
import { EntityStatesHistory } from "./EntityStatesHistory";
import { EntityHistoryStore } from "./EntityHistoryStore";

interface PropsEntityHistory {
  store: EntityHistoryStore;
}

export const EntityHistory: React.FC<PropsEntityHistory> = observer(
  ({ store }) => {
    const curStateView = store.curStateName ? (
      <Tag color="gold">{store.curStateName}</Tag>
    ) : undefined;

    return (
      <div className={styles.frame}>
        <div className={styles.header}>
          <div className={styles.title}>{store.entityName}</div>
          {curStateView}
        </div>
        <div className={styles.mainPart}>
          <EntityStatesHistory store={store.statesStore} />
        </div>
      </div>
    );
  },
);
