import React, { Fragment } from "react";
import {
  loadObjectAttrinbutesAll,
  makeObjectAttributesMap,
} from "src/pages/ManagementPage/objectsApi";
import { getIdNames } from "src/references/getIdNames";
import { AttrTypeName, makeDictNameById } from "src/types/AttrType";
import { ZAttribute } from "src/types/ZAttribute";
import { ZEntity, ZEntityField } from "src/types/ZEntity";
import {
  ZSeparator,
  seedSeparators,
} from "../../attrView/viewFormItems/Separator";
import { ZAttrViewInfo, getViewInfo } from "../../attrView";
import { byAttrValType } from "../../attributes/byAttrValType";
import { DictRefInner, ImgListInner, ObjectRefInner } from "./innerViews";
import { LabelComposeConfig, getActualComposeView } from "./getActualView";

const getSimpleLabel = async (
  entity: ZEntity,
  objectAttr: ZAttribute,
  entityAttr: ZEntityField,
  labelKeys: Set<number>,
  separator?: ZSeparator,
) => {
  const labelAtts = entity.attributeValues.find(
    ({ attributeId }) =>
      labelKeys.has(attributeId) && attributeId === entityAttr.attributeId,
  );
  const viewInfo = getViewInfo(objectAttr.viewType);
  const actualSeparator = separator || viewInfo?.appearance?.view?.separator;
  const label = seedSeparators(
    labelAtts?.values?.map((item) => ({ content: item, key: item })) || [],
    actualSeparator,
  );
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{label}</>;
};

const requestCache: Record<string, Promise<ZAttribute[]>> = {};

export const composeEntityLabel = async (
  entity: ZEntity,
  attrLabelsIds: string[],
  viewInfo: ZAttrViewInfo,
  // для отслеживания рекурсии
  pathRegister?: number[],
  config?: LabelComposeConfig,
) => {
  if (!requestCache[entity.objectId]) {
    requestCache[entity.objectId] = loadObjectAttrinbutesAll(
      String(entity.objectId),
    );
  }
  const objectAttsMap = makeObjectAttributesMap(
    (await requestCache[entity.objectId]) || [],
  );
  const attrTypeMap = makeDictNameById(await getIdNames("attrType"));
  const labelKeys = new Set(attrLabelsIds.map(Number));
  const labelAtts = entity.attributeValues.filter((attr) =>
    labelKeys.has(attr.attributeId),
  );
  const { separator } = config || {};
  const actualSeparator =
    separator || viewInfo.appearance?.view?.separator || "comma";
  const valuesAsync = labelAtts.map((entityAttr) => {
    const objectAttr = objectAttsMap[entityAttr.attributeId];
    if (!objectAttr?.valueType) throw new Error("system error");
    return byAttrValType(
      objectAttr,
      attrTypeMap,
      {
        [AttrTypeName.dictMulti]: async () =>
          getActualComposeView(
            AttrTypeName.dictMulti,
            <DictRefInner
              entityAttr={entityAttr}
              objectAttr={objectAttr}
              separator={actualSeparator}
            />,
            config,
          ),
        [AttrTypeName.dictSingle]: async () =>
          getActualComposeView(
            AttrTypeName.dictSingle,
            <DictRefInner
              entityAttr={entityAttr}
              objectAttr={objectAttr}
              separator={actualSeparator}
            />,
            config,
          ),
        [AttrTypeName.object]: async () =>
          getActualComposeView(
            AttrTypeName.object,
            <ObjectRefInner
              entity={entity}
              attribute={objectAttr}
              entityIdList={entityAttr.values || []}
              typeMap={attrTypeMap}
              pathRegister={pathRegister || []}
              config={config}
            />,
            config,
          ),
        [AttrTypeName.image]: async () =>
          getActualComposeView(
            AttrTypeName.image,
            <ImgListInner imgUuidList={entityAttr.values || []} />,
            config,
          ),
      },
      () =>
        getSimpleLabel(
          entity,
          objectAttr,
          entityAttr,
          labelKeys,
          actualSeparator,
        ),
    );
  });

  const valuesResp = await Promise.all(valuesAsync);
  const withSep = seedSeparators(
    valuesResp.map((v, i) => ({ content: v, key: i })),
    actualSeparator,
  );

  return withSep.length > 0 ? (
    withSep
  ) : (
    <Fragment key={entity.id}>{entity.id}</Fragment>
  );
};
