import { observer } from "mobx-react-lite";
import * as React from "react";
import { Button, Form, Input, InputRef } from "antd";
import { delay } from "src/common/delay";
import { DeleteOutlined, UserAddOutlined } from "@ant-design/icons";
import { TopGroupRoleNode } from "../nodes/RoleNode";
import { RolesTabStore } from "../RolesTabStore";
import { FieldsWithTools } from "../../FieldsWithTools";

interface PropsRoleGroupFields {
  node: TopGroupRoleNode;
  store: RolesTabStore;
}

export const RoleGroupFields: React.FC<PropsRoleGroupFields> = observer(
  ({ node, store }) => {
    const ref1 = React.createRef<InputRef>();
    React.useEffect(() => {
      delay(10).then(() => ref1.current?.focus());
    }, [node.group.groupId]);
    // const isNew = node.group.groupId === newItemId;
    const canDelele = true; // Пока нет пермишенов
    return (
      <FieldsWithTools
        tools={
          <>
            <Button
              icon={<UserAddOutlined />}
              onClick={() => store.safeAddRole()}
            >
              Добавить роль
            </Button>
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={() => {
                store.safeDeleteCurNode();
              }}
              disabled={!canDelele}
            >
              Удалить группу
            </Button>
          </>
        }
      >
        <div>
          <Form.Item
            name="groupName"
            label="Название группы ролей"
            rules={[{ required: true }]}
          >
            <Input ref={ref1} allowClear />
          </Form.Item>
        </div>
      </FieldsWithTools>
    );
  },
);
