import * as React from "react";
import { observer } from "mobx-react-lite";
import {
  Alert,
  AlertProps,
  Form,
  Modal,
  Spin,
  FormInstance,
  Button,
} from "antd";
import { getErrorMessage } from "src/common/onError";
import { FormStatus, ModelessFormStore } from "./ModelessFormStore";
import styles from "./ModelessForm.module.less";

interface PropsModelessForm {
  store: ModelessFormStore;
  children?: React.ReactNode;
  formInstance?: FormInstance;
  submit?: string;
  name?: string;
}

export const ModelessForm: React.FC<PropsModelessForm> = observer(
  (props: PropsModelessForm) => {
    const { store, children, formInstance, submit, name } = props;
    const form: FormInstance = formInstance ?? Form.useForm()[0];

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [vals, setVals] = React.useState("");

    React.useEffect(() => {
      store.initForm(store.formStatus === "new" ? "new" : "ok");
      form.resetFields();
      form.setFieldsValue(store.initialValues);
    }, [store.initialValues]);

    let msg: React.ReactNode = statusMsg[store.formStatus];
    if (store.formStatus === "error" && store.formError) {
      const errMsg = getErrorMessage(store.formError);
      msg = errMsg.description || errMsg.message;
    }

    if (store.initialValues === null) return null;

    return (
      <div className={styles.common}>
        {!submit && (
          <div className={styles.status}>
            {store.isFormBusy && <Spin size="small" />}
            <Alert type={statusDict[store.formStatus]} message={msg} showIcon />
          </div>
        )}
        <div className={styles.formBox}>
          <Form
            layout="vertical"
            name={name}
            form={form}
            onValuesChange={(_, values) => {
              if (submit) {
                store.setFormStatus("changed");
              } else {
                store.tryToSave(values, form);
              }
              setVals(JSON.stringify(values, null, "  "));
            }}
            className={styles.form}
            onFinish={(values) => store.submit(values)}
          >
            {children}
            {!!submit && (
              <div>
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={!store.canSubmit}
                  loading={store.formStatus === "saving"}
                >
                  {submit}
                </Button>
              </div>
            )}
          </Form>
        </div>
        <ConfirmationTask store={store} />
        {/* <pre>{vals}</pre> */}
      </div>
    );
  },
);

const statusDict: Record<FormStatus, AlertProps["type"]> = {
  ok: "success",
  validating: "warning",
  invalid: "warning",
  saving: "warning",
  error: "error",
  new: "info",
  changed: "info",
};
const statusMsg: Record<FormStatus, string> = {
  ok: "Данные сохранены",
  validating: "Проверка...",
  invalid: "Данные заполнены некорректно",
  saving: "Сохранение...",
  error: "Ошибка при сохранении",
  new: "Новые данные",
  changed: "Внесены изменения",
};

interface PropsConfirmationTask {
  store: ModelessFormStore;
  text?: string;
}

const ConfirmationTask: React.FC<PropsConfirmationTask> = observer(
  ({ store, text }: PropsConfirmationTask) => {
    React.useEffect(() => {
      if (store.formStatus === "ok") store.confirmResolver?.resolve();
    }, [store.formStatus]);

    React.useEffect(() => {
      store.setUseExternalConfirm(true);
      return () => {
        store.setUseExternalConfirm(false);
      };
    }, []);

    return (
      <Modal
        open={!!store.confirmResolver}
        onOk={() => store.confirmResolver?.resolve()}
        onCancel={() => store.confirmResolver?.reject()}
        okText="Продолжить"
        okButtonProps={{ danger: true }}
      >
        <div>
          {text ||
            "Форма содержит несохранённые данные. Продолжить с потерей данных?"}
        </div>
      </Modal>
    );
  },
);
