// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-tables-ColumnsSettings-ColumnsSettings-module__sortableContainer--EbIbM {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 0;
  background: white;
  box-shadow: 0px 2px 10px rgba(43, 43, 43, 0.11);
  border-radius: 4px;
}
.src-components-tables-ColumnsSettings-ColumnsSettings-module__sortableContainer--EbIbM li {
  background: white;
  list-style-type: none;
}
.src-components-tables-ColumnsSettings-ColumnsSettings-module__sortableContainer--EbIbM .src-components-tables-ColumnsSettings-ColumnsSettings-module__checkBoxWrapper--iXT9n {
  display: flex;
  flex-direction: row;
  gap: 15px;
}
.src-components-tables-ColumnsSettings-ColumnsSettings-module__sortableContainer--EbIbM .src-components-tables-ColumnsSettings-ColumnsSettings-module__columnInfo--iSEqf {
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: row;
  gap: 15px;
  cursor: move;
  padding: 8px 24px;
}
.src-components-tables-ColumnsSettings-ColumnsSettings-module__sortableContainer--EbIbM .src-components-tables-ColumnsSettings-ColumnsSettings-module__columnInfo--iSEqf:hover {
  background: #f0f0f0;
}
.src-components-tables-ColumnsSettings-ColumnsSettings-module__sortableContainer--EbIbM .src-components-tables-ColumnsSettings-ColumnsSettings-module__menu--G1p1e {
  max-height: 300px;
  overflow: auto;
}
.src-components-tables-ColumnsSettings-ColumnsSettings-module__trigger--p25tx {
  padding: 5px;
  cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/components/tables/ColumnsSettings/ColumnsSettings.module.less"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,eAAA;EACA,iBAAA;EACA,+CAAA;EACA,kBAAA;AACF;AARA;EAWI,iBAAA;EACA,qBAAA;AAAJ;AAZA;EAgBI,aAAA;EACA,mBAAA;EACA,SAAA;AADJ;AAjBA;EAsBI,aAAA;EACA,8BAAA;EACA,WAAA;EACA,mBAAA;EACA,SAAA;EACA,YAAA;EACA,iBAAA;AAFJ;AAGI;EACE,mBAAA;AADN;AA7BA;EAmCI,iBAAA;EACA,cAAA;AAHJ;AAOA;EACE,YAAA;EACA,eAAA;AALF","sourcesContent":[".sortableContainer {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  padding: 16px 0;\n  background: white;\n  box-shadow: 0px 2px 10px rgba(43, 43, 43, 0.11);\n  border-radius: 4px;\n\n\n  li {\n    background: white;\n    list-style-type: none;\n  }\n\n  .checkBoxWrapper {\n    display: flex;\n    flex-direction: row;\n    gap: 15px\n  }\n\n  .columnInfo {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    flex-direction: row;\n    gap: 15px;\n    cursor: move;\n    padding: 8px 24px;\n    &:hover {\n      background: #f0f0f0;\n    }\n  }\n\n  .menu {\n    max-height: 300px;\n    overflow: auto;\n  }\n}\n\n.trigger {\n  padding: 5px;\n  cursor: pointer;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sortableContainer": `src-components-tables-ColumnsSettings-ColumnsSettings-module__sortableContainer--EbIbM`,
	"checkBoxWrapper": `src-components-tables-ColumnsSettings-ColumnsSettings-module__checkBoxWrapper--iXT9n`,
	"columnInfo": `src-components-tables-ColumnsSettings-ColumnsSettings-module__columnInfo--iSEqf`,
	"menu": `src-components-tables-ColumnsSettings-ColumnsSettings-module__menu--G1p1e`,
	"trigger": `src-components-tables-ColumnsSettings-ColumnsSettings-module__trigger--p25tx`
};
export default ___CSS_LOADER_EXPORT___;
