import { FormRule } from "antd";

export const trimRequired = (message?: string): FormRule => ({
  required: true,
  validator: (rule, value) => {
    if (typeof value === "string" && value.trim() === "") {
      return Promise.reject(Error(message ?? "Необходимо заполнить поле"));
    }
    return Promise.resolve();
  },
});
