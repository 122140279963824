import { z } from "zod";
import { zPermissionsList } from "./Permission";

export const zAttributeValue = z.object({
  id: z.number(),
  value: z.string().nullable(),
});
export type ZAttributeValue = z.infer<typeof zAttributeValue>;

export const zAttribute = z.object({
  id: z.number(),
  name: z.string(),
  valueType: z.number(),
  fieldKey: z.string().nullable().optional(),
  referenceId: z.number().nullable().optional(), // id справочника, если valueType - Справочник
  values: zAttributeValue.array().nullable().optional(),
  valueId: z.number().nullable().optional(), // Ссылка на значение справочника, если атрибут входит в Dictionary-группу
  roleIds: z.number().array().nullable().optional(), // LPLM-665
  // Свойства компонента на карточке сущности. Название неудачное, но и этого пришлось неделю ждать.
  viewStyles: z.string().nullable().optional(),
  viewType: z.string().nullable().optional(),
  permissions: zPermissionsList.nullable().optional(),
});
export type ZAttribute = z.infer<typeof zAttribute>;
