import * as React from "react";
import { ZAttribute } from "src/types/ZAttribute";

export const search = (
  source: string,
  subString: string,
): [number, number] | undefined => {
  if (!subString) return undefined;
  const pos = source.toLowerCase().indexOf(subString.toLowerCase());
  return pos < 0 ? undefined : [pos, pos + subString.length];
};

export const makeTitle = (
  name: string,
  subString?: string,
): React.ReactNode => {
  const selection = subString ? search(name, subString) : undefined;
  return selection ? (
    <span>
      {name.slice(0, selection[0])}
      <span style={{ color: "red" }}>
        {name.slice(selection[0], selection[1])}
      </span>
      {name.slice(selection[1])}
    </span>
  ) : (
    <span>{name}</span>
  );
};

export const dictionaryTitle = (
  { name, id }: ZAttribute,
  subString?: string,
): React.ReactNode => (
  <span data-dict-id={id}>{makeTitle(`${name} (${id})`, subString)}</span>
);
