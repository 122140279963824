import { z } from "zod";

export const zAttrPersonSelect = z.object({
  editor: z.literal("PersonSelect"),
  multiple: z.boolean().optional(),
});

export type ZAttrPersonSelect = z.infer<typeof zAttrPersonSelect>;

export type AttrPersonSelectProps = Omit<ZAttrPersonSelect, "editor">;

export const makeAttrPersonSelectProps = (
  src: ZAttrPersonSelect,
): AttrPersonSelectProps => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { editor, ...rest } = src;
  return rest;
};
