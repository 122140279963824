export default {
  translation: {
    "Action is irreversible": "Это действие необратимо",
    Add: "Добавить",
    "all attributes": "все атрибуты",
    "Appeal to technical support is registered":
      "Обращение в техподдержку зарегистрировано",
    "Are you sure to delete {{count}} persons?":
      "Удалить {{count}} пользователей?",
    "Are you sure to delete {{count}} persons?_one":
      "Удалить {{count}} пользователя?",
    "Attached files": "Вложенные файлы",
    "Attention!": "Внимание!",
    Attributes: "Атрибуты",
    "Bussiness dictionaries": "Бизнес-справочники",
    Cancel: "Отмена",
    Close: "Закрыть",
    "Contacting tech support": "Обращение в техподдержку",
    "Current state": "Текущее состояние",
    DateFormat: "DD.MM.YYYY",
    DateTimeFormat: "DD.MM.YYYY HH:mm",
    Delete: "Удалить",
    "Describe the question": "Опишите вопрос",
    Dictionaries: "Справочники",
    "Do you want to delete the current entry?":
      "Выполнить удаление текущей записи?",
    "Enter a name": "Введите имя",
    "Enter email": "Введите email",
    Entity_one: "Сущность",
    Entity_few: "Сущности",
    "Error when accessing the server": "Ошибка при обращении к серверу",
    "Format:": "Формат: {{value}}",
    "Full name": "Ф.И.О.",
    "General information": "Общая информация",
    "Instance of object": "Экземпляр объекта {{name}}",
    "Invalid email format": "Неверный формат email",
    "Invalid page URL": "Неверный URL-адрес страницы",
    "History of changes": "История изменений",
    "Groups and roles": "Группы и роли",
    Logout: "Выйти",
    Main: "Главное",
    Management: "Управление",
    "Maximum number: count": "Максимальное количество: {{count}}",
    "Maximum total size:": "Максимальный общий размер: {{size}} Мб",
    "Menu settings": "Настройка меню",
    Models: "Модели",
    "New instance": "Новый экземпляр",
    "New instance of object": "Новый экземпляр объекта {{name}}",
    "Next state": "Следующее состояние",
    "No match for path {path}": "Нет соответствия пути {path}",
    "Objects and attributes": "Объекты и атрибуты",
    Password: "Пароль",
    Persons: "Пользователи",
    Question: "Вопрос",
    Register: "Зарегистрироваться",
    Repeat: "Повторить",
    "Restore password": "Восстановить пароль",
    Save: "Сохранить",
    Search: "Поиск",
    Send: "Отправить",
    Setting: "Настройки",
    "Selected entities": "Выбранные сущности",
    "Sign In": "Войти",
    "Specify the topic": "Укажите тему",
    Status: "Статус",
    Subject: "Тема",
    Submit: "Отправить",
    templates: "шаблоны",
    "The field is mandatory": "Поле является обязательным",
    "Total files size exceeds": "Общий размер файлов превышает {{size}} Мб",
    "Total models: {{count}}": "Всего моделей: {{count}}",
    "Total information": "Суммарная информация",
    "Total instances count": "Всего cущностей: {{count}}",
    "Total persons count": "Всего {{count}} пользователей",
    "Total persons count_one": "Всего {{count}} пользователь",
    "Total persons count_few": "Всего {{count}} пользователя",
    Upload: "Загрузить",
    "Your request has been registered by the technical support service":
      "Ваше обращение зарегистрировано службой технической поддержки",

    actionType: {
      // see ActionTypeName
      ATTRIBUTE: "Атрибут",
      OBJECT: "Объект",
      ENTITY: "Экземпляр",
    },
    attrType: {
      BOOLEAN: "Логическое значение",
      CHILD_OBJECT_ID: "Список зависимых объектов",
      DATE: "Дата",
      DATE_WITH_TIME: "Дата и время",
      OBJECT_ID: "Ссылка на другой объект",
      ONE_CHOICE_DICTIONARY: "Справочник",
      FILE: "Файл",
      FORMULA: "Формула",
      IMAGE: "Изображение",
      INT: "Целое число",
      LINK_TO_EXTERNAL_SOURCE: "Ссылка на внешний ресурс",
      MULTIPLE_CHOICE_DICTIONARY: "Множественный выбор из справочника",
      NUMERIC: "Дробное число",
      STRING: "Текст",
      TIME: "Время",
      USER_FILTER: "Список пользователей",
    },
    groupType: {
      Dictionary: "На основе справочника",
      Mnemonic: "Мнемоническая",
    },
    management: {
      AddGroup: "Добавить группу",
      "Confirm deletion of dictionary group":
        "Вы действительно хотите удалить группу справочников?",
      "Confirm deletion of dictionary":
        "Вы действительно хотите удалить справочник?",
      "Deleting a dictionary group": "Удаление группы справочников",
      "Group name": "Название группы",
      "New group of dictionaries": "Новая группа справочников",
      "Wrong group type": "Неверный формат группы",
    },
    Pages: {
      Plans: "Планы",
      Projects: "Проекты",
      Reports: "Отчёты",
    },
    Background: {
      CommOffer: "Согласование коммерческих предложений",
      Description:
        "Автоматизация процессов взаимодействия производителей, дистрибьютеров и поставщиков с торговыми сетями",
      Header: "Автоматизация процессов",
      Promo: "Календарь промо",
      Revaluation: "Заявки на переоценку",
    },
  },
};
