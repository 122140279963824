// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__common--A8yZK,
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__blockVert--lic41 {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__twoCols--qYDQT {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 16px;
}
.src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__radioVert--j3b4j {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Obj2Tab/forms/AttrForm2/AttrCommonFields/AttrCommonFields.module.less"],"names":[],"mappings":"AAAA;;EACI,aAAA;EACA,sBAAA;EACA,SAAA;AAEJ;AACA;EACI,aAAA;EACA,8BAAA;EACA,gBAAA;AACJ;AAEA;EACI,aAAA;EACA,sBAAA;EACA,QAAA;AAAJ","sourcesContent":[".common, .blockVert {\n    display: flex;\n    flex-direction: column;\n    gap: 16px;\n}\n\n.twoCols {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    column-gap: 16px;\n}\n\n.radioVert {\n    display: flex;\n    flex-direction: column;\n    gap: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"common": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__common--A8yZK`,
	"blockVert": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__blockVert--lic41`,
	"twoCols": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__twoCols--qYDQT`,
	"radioVert": `src-pages-ManagementPage-Obj2Tab-forms-AttrForm2-AttrCommonFields-AttrCommonFields-module__radioVert--j3b4j`
};
export default ___CSS_LOADER_EXPORT___;
