import { Spin } from "antd";
import React, { useEffect, useState } from "react";
import { composeEntityLabel } from "src/common/entity/composeEntityLabel/composeEntityLabel";
import { onError } from "src/common/onError";
import { getEntityCachedRequest } from "src/common/entity/getEntityCachedRequest";
import { SimpleText } from "../SimpleText/SimpleText";
import { ZAttrViewInfo } from "../../zAttrViewInfo";
import styles from "./ObjectRef.module.less";
import { seedSeparators } from "../../viewFormItems/Separator";

type PropsObjectRef = {
  objectId: number | null;
  labelAtts: string[];
  values?: string[];
  viewInfo?: ZAttrViewInfo;
};

export const ObjectRef: React.FC<PropsObjectRef> = ({
  objectId,
  labelAtts,
  values,
  viewInfo,
}) => {
  const [labelList, setLabelList] = useState<React.ReactNode[]>([]);
  const [loading, setLoading] = useState(false);
  const init = async () => {
    try {
      const entityIdList = values || [];
      if (!entityIdList || !viewInfo || !objectId) return;
      setLoading(true);

      const result = await Promise.all(
        entityIdList.map(async (id) => {
          const refEnt = await getEntityCachedRequest(Number(id));
          const labelsResp = await composeEntityLabel(
            refEnt,
            labelAtts || [],
            viewInfo,
          );
          return { labelsResp, id };
        }),
      );
      const labelDefResult = result.map(({ labelsResp, id }) => ({
        content: labelsResp,
        key: id,
      }));
      setLabelList(seedSeparators(labelDefResult, "newLine"));
    } catch (error) {
      onError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    init();
  }, []);

  return loading ? (
    <Spin spinning={loading}>...</Spin>
  ) : (
    <SimpleText className={styles.box} values={labelList} viewInfo={viewInfo} />
  );
};

ObjectRef.defaultProps = { viewInfo: undefined, values: undefined };
