import * as React from "react";
import { Button } from "antd";
import { observer } from "mobx-react-lite";
import { Obj2TabStore } from "../Obj2TabStore";
import { FieldsWithTools } from "../../FieldsWithTools";
import { ValueO2 } from "../Obj2Nodes";
import { AddGroup } from "./AddGroup";
import { AddExistingAttribute } from "./AddExistingAttribute";
import { UpToObject } from "./UpToObject";

interface PropsValueForm2 {
  store: Obj2TabStore;
  valueNode: ValueO2;
}

export const ValueForm2: React.FC<PropsValueForm2> = observer((props) => {
  const { store, valueNode } = props;
  const { value, things } = valueNode;
  const noUpdate = things.status !== "ready" || !valueNode.actions.update;
  return (
    <FieldsWithTools
      tools={
        <>
          <UpToObject store={store} />
          <Button
            id="addAttrToValue"
            disabled={noUpdate}
            onClick={() => store.safeAddAttr()}
          >
            Добавить атрибут
          </Button>
          <AddExistingAttribute store={store} valueNode={valueNode} />
          <AddGroup store={store} disabled={noUpdate} />
        </>
      }
    >
      <div>{value.name}</div>
    </FieldsWithTools>
  );
});
