import { ZAttrItemProps } from "src/common/attrEdit";

export type TooltipMode = "ext" | "none" | "text";
export const getTooltipMode = (itemProps: ZAttrItemProps): TooltipMode => {
  const hasExtData = Object.values(itemProps.tooltipExt ?? {}).find(
    (value) => !!value,
  );
  if (hasExtData) return "ext";
  return itemProps.tooltip ? "text" : "none";
};
