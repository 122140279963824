import * as React from "react";
import { classNames } from "src/common/classNames";
import { Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import styles from "./ExtendedTooltipInner.module.less";
import { ZTooltipExtFields } from "./ZTooltipExtFields";

interface PropsExtendedTooltipInner {
  tooltipData: ZTooltipExtFields;
}

export const ExtendedTooltipInner: React.FC<PropsExtendedTooltipInner> = ({
  tooltipData,
}) => {
  const { title, text, image, link, linkText, download, downloadText } =
    tooltipData;
  return (
    <div className={styles.box}>
      {!!title && <div className={styles.title}>{title}</div>}
      {(!!text || !!image) && (
        <div
          className={classNames([
            styles.middle,
            [!!text && !!image, styles.twoCols],
          ])}
        >
          {text && <div className={styles.text}>{text}</div>}
          {image && <img className={styles.image} src={image} alt="" />}
        </div>
      )}
      {link && (
        <div className={styles.link}>
          <a href={link} target="_blank" rel="noreferrer">
            {linkText || link}
          </a>
        </div>
      )}
      {download && (
        <div className={styles.download}>
          <Button
            type="primary"
            icon={<DownloadOutlined />}
            href={download}
            target="_blank"
            download={downloadText}
          >
            Скачать
          </Button>
        </div>
      )}
    </div>
  );
};
