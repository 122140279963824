import { AxiosError } from "axios";
import { ArgsProps } from "antd/lib/notification";
import { ZodError } from "zod";
import { notification } from "antd";
import { t } from "i18next";

export const getErrorMessage = (e: Error): ArgsProps => {
  if (e instanceof ZodError) {
    // Если возникла ошибка в схеме данных, то выдать ее в консоль, чтобы можно было понять причину.
    // eslint-disable-next-line no-console
    console.error("issues:", e.issues);
    return {
      message: "Ошибочный формат полученных данных",
    };
  }
  // eslint-disable-next-line no-console
  console.error(e); // чтобы проще найти место ошибки
  const axiosError = e as AxiosError;
  if (axiosError?.isAxiosError) {
    const info = axiosError.response?.data;
    let description: string = `Статус: ${axiosError.response?.status}`;

    if (info && typeof info === "object") {
      if ("message" in info) {
        const { message } = info;
        if (typeof message === "string") description = message;
      }
    }
    if (axiosError?.response?.status === 400) {
      return { message: t("Attention!"), description, type: "warning" };
    }
    if (axiosError?.response?.status === 403) {
      return { message: "Доступ запрещен" };
    }
    return { message: "Ошибка сервера", description };
  }
  return { message: e.message };
};

export const onError = (e: Error): void => {
  const argProps: ArgsProps = { type: "error", ...getErrorMessage(e) };

  // В отладочных целях сохранить сообщение об ошибке в специальный невидимый контейнер
  const id = "hiddenLastErrorInfo";
  let lastErrInfo = document.getElementById(id);
  if (!lastErrInfo) {
    lastErrInfo = document.createElement("div");
    lastErrInfo.setAttribute("id", id);
    lastErrInfo.style.display = "none";
    document.body.appendChild(lastErrInfo);
  }
  const text =
    argProps.description?.toString() ?? argProps.message?.toString() ?? "";
  lastErrInfo.innerText = text;

  notification.open(argProps);
};
