import { Spin, Timeline } from "antd";
import { observer } from "mobx-react-lite";
import * as React from "react";
import { TableStore } from "src/components/tables/TableStore";
import styles from "./AsyncTimeline.module.less";

interface PropsAsyncTimeline<TRow, TFilters extends object> {
  store: TableStore<TRow, TFilters>;
  renderRow: (row: TRow) => React.ReactNode;
}

export const AsyncTimeline = observer(
  <TRow extends {}, TFilters extends object>(
    props: PropsAsyncTimeline<TRow, TFilters>,
  ): React.ReactElement => {
    const { store, renderRow } = props;

    React.useEffect(() => {
      store.init([]);
    }, [store]);

    return store.loading ? (
      <div className={styles.loaderBox}>
        <Spin spinning={store.loading} size="large" />
      </div>
    ) : (
      <Timeline
        items={store.rows.map((row) => ({
          children: renderRow(row),
        }))}
      />
    );
  },
);
