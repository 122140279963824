// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__addButton--lAyL7 {
  width: 100%;
  background-color: #595959;
  color: white;
}
.src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__tree--U71gG {
  padding: 4px 24px 24px;
}
.src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__tree--U71gG .ant-tree-switcher {
  display: none;
}
.src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__tree--U71gG .ant-tree-node-content-wrapper {
  transition: none;
}
.src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__tree--U71gG.src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__treeNewItem--ZCo4X .ant-tree-node-content-wrapper {
  padding-left: 28px;
}
.src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__iconSelect--KyNBC .ant-select-selection-item {
  display: flex;
  align-items: center;
}
.src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__iconOption--yzIjG .ant-space-item {
  display: flex;
  align-items: center;
}
.src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__iconOption--yzIjG .anticon {
  height: 16px;
  width: 16px;
}
.src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__radioGroup--ziPuD {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/ManagementPage/Menu2Tab/Menu2Tab.module.less"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,yBAAA;EACA,YAAA;AACJ;AAEA;EACI,sBAAA;AAAJ;AADA;EAIQ,aAAA;AAAR;AAJA;EAQQ,gBAAA;AADR;AAII;EAEQ,kBAAA;AAHZ;AAQA;EAEQ,aAAA;EACA,mBAAA;AAPR;AAWA;EAEQ,aAAA;EACA,mBAAA;AAVR;AAOA;EAOQ,YAAA;EACA,WAAA;AAXR;AAeA;EACI,aAAA;EACA,sBAAA;EACA,YAAA;AAbJ","sourcesContent":[".addButton {\n    width: 100%;\n    background-color: #595959;\n    color: white;\n}\n\n.tree {\n    padding: 4px 24px 24px;\n\n    :global(.ant-tree-switcher) {\n        display: none;\n    }\n\n    :global(.ant-tree-node-content-wrapper) {\n        transition: none;\n    }\n\n    &.treeNewItem {\n        :global(.ant-tree-node-content-wrapper) {\n            padding-left: 28px;\n        }\n    }\n}\n\n.iconSelect {\n    :global(.ant-select-selection-item) {\n        display: flex;\n        align-items: center;\n    }\n}\n\n.iconOption {\n    :global(.ant-space-item) {\n        display: flex;\n        align-items: center;\n    }\n\n    :global(.anticon) {\n        height: 16px;\n        width: 16px;\n    }\n}\n\n.radioGroup {\n    display: flex;\n    flex-direction: column;\n    row-gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addButton": `src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__addButton--lAyL7`,
	"tree": `src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__tree--U71gG`,
	"treeNewItem": `src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__treeNewItem--ZCo4X`,
	"iconSelect": `src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__iconSelect--KyNBC`,
	"iconOption": `src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__iconOption--yzIjG`,
	"radioGroup": `src-pages-ManagementPage-Menu2Tab-Menu2Tab-module__radioGroup--ziPuD`
};
export default ___CSS_LOADER_EXPORT___;
