import { TableResponse } from "src/components/tables/TableStore";
import { ZPersonRow, zPersonListResponse } from "src/types/ZPersonRow";
import { rest } from "./rest";
import { apiAdminUrl } from "./apiUrl";

type ParamsLoadPersonList = {
  page?: number;
  pageSize?: number;
  roleIds: number[];
  userIds?: string[];
};

export const apiLoadPersonList = async (
  params: ParamsLoadPersonList,
): Promise<TableResponse<ZPersonRow>> => {
  const { page, pageSize, roleIds, userIds } = params;
  const body = { roleIds, userIds };
  const resp = await rest.post(apiAdminUrl("/users/list"), body, {
    params: { page, size: pageSize },
  });
  const { totalElements, content } = zPersonListResponse.parse(resp.data);
  return {
    totalItems: totalElements,
    rows: content,
  };
};
