import * as React from "react";
import { observer } from "mobx-react-lite";
import { Button, Form, Input, InputRef } from "antd";
import { DeleteOutlined, SisternodeOutlined } from "@ant-design/icons";
import { RolesTabStore } from "../RolesTabStore";
import { ObjRoleNode, newItemId } from "../nodes/RoleNode";
import { FieldsWithTools } from "../../FieldsWithTools";

interface PropsRoleObjectField {
  store: RolesTabStore;
  node: ObjRoleNode;
}

export const RoleObjectField: React.FC<PropsRoleObjectField> = observer(
  ({ store, node }) => {
    const ref1 = React.createRef<InputRef>();
    React.useEffect(() => {
      setTimeout(() => ref1.current?.focus(), 10);
    }, [node.obj.id]);
    const isNew = node.obj.id === newItemId;
    const canAddAttr = !isNew;
    const canDelete = true;
    //   && !node.obj.permissions?.includes(Permission.objDelete)
    // но на момент разработки permissions=null, то есть неизвестно ничего о ролевой модели для данного случая
    return (
      <FieldsWithTools
        tools={
          <>
            <Button
              icon={<SisternodeOutlined />}
              onClick={() => store.safeAddAttr()}
              disabled={!canAddAttr}
              id="addAttrToRole"
            >
              Добавить атрибут
            </Button>
            <Button
              icon={<DeleteOutlined />}
              danger
              onClick={() => {
                store.safeDeleteCurNode();
              }}
              disabled={!canDelete}
            >
              Удалить роль
            </Button>
          </>
        }
      >
        <div>
          <Form.Item
            name="name"
            label="Название роли"
            rules={[{ required: true }]}
          >
            <Input ref={ref1} allowClear />
          </Form.Item>
        </div>
      </FieldsWithTools>
    );
  },
);
