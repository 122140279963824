import * as React from "react";
import { Alert, notification } from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { PageLayout } from "src/components/PageLayout";
import { observer } from "mobx-react-lite";
import { LoaderBox } from "src/components/LoaderBox";
import { PageUrl } from "src/routes/PageUrl";
import { breadItem } from "src/common/breadItem";
import { makeUrl } from "src/routes/makeUrl";
import { emmitLastObjectUpdateSE } from "src/common/storageEventUtils";
import { parseInitialEntityParams } from "src/common/entity/makeInintialEntityParams";
import { entityCardStore as pageStore } from "./EntityCardStore";
import { EdCardValues } from "./apiEntityCard";
import { EntityFormWithAnchor2 } from "./EntityFormWithAnchor";
import styles from "./EntityCardPage.module.less";

export const NewEntityCardPage: React.FC = observer(() => {
  const { objectId } = useParams();
  const [searchParams] = useSearchParams();
  React.useEffect(() => {
    if (objectId)
      pageStore.initNew(objectId, parseInitialEntityParams(searchParams));
  }, [objectId]);
  if (!objectId) {
    return <Alert type="error" message="Не указан объект" showIcon />;
  }

  const navigate = useNavigate();
  const onSuccess = (values: EdCardValues) => {
    const { entityId } = values;
    if (!entityId) {
      notification.warning({ message: "Не получено значение entityId" });
    } else {
      notification.success({ message: "Выполнено создание сущности" });
      emmitLastObjectUpdateSE(objectId);
      navigate(makeUrl(PageUrl.entityCard, { id: entityId }), {
        replace: true,
      });
    }
  };

  const { rootBlock } = pageStore;
  const Inner: React.FC = observer(() => {
    if (!rootBlock) return null;
    return (
      <EntityFormWithAnchor2
        title={pageStore.entityName}
        store={pageStore}
        submitText="Создать"
        onSubmit={(values) => pageStore.create(values)}
        onSuccess={onSuccess}
        disabled={false}
        availableStates={undefined}
      />
    );
  });
  return (
    <PageLayout
      documentTitle={pageStore.documentTitle}
      breadcrumb={[
        breadItem(pageStore.objectName, PageUrl.entities, {
          objectId: pageStore.objectId,
        }),
        breadItem(pageStore.entityName),
      ]}
    >
      <div className={styles.newEntityContent}>
        <LoaderBox remoteData={pageStore.info} drawReady={() => <Inner />} />
      </div>
    </PageLayout>
  );
});
