import * as React from "react";
import { z } from "zod";
import { ZAttribute } from "src/types/ZAttribute";
import { zObjectItem } from "src/types/ZObjectItem";
import { zGroup } from "src/types/ZGroup";
import { ZIdName } from "src/types/ZIdName";
import { RemoteData } from "src/common/RemoteData";
import { ZValueThings } from "src/types/ZValueThings";
import { ZObjState } from "src/types/ZObjState";
import { EdObject } from "./EdObject";

export const newItemId = -1;

export const zLightObject = zObjectItem.omit({
  attributes: true,
  groups: true,
});
export type ZLightObject = z.infer<typeof zLightObject>;

export const zLightGroup = zGroup.omit({ attributes: true, groups: true });
export type ZLightGroup = z.infer<typeof zLightGroup>;

export type Actions = {
  update: boolean;
  delete: boolean;
};

interface BaseNodeO2 {
  key: React.Key;
  readonly title: React.ReactNode;
  children?: CommonNodeO2[];
  icon: React.ReactNode;
  isLeaf: boolean;
  actions: Actions;
}

export interface ObjectO2 extends BaseNodeO2 {
  type: "obj";
  object: EdObject;
}

export interface GroupO2 extends BaseNodeO2 {
  type: "group";
  group: ZLightGroup;
  onExpand?(self: GroupO2): Promise<void>;
}

export interface AttributeO2 extends BaseNodeO2 {
  type: "attr";
  attr: ZAttribute;
  states?: ZObjState[]; // Состояния для объекта-владельца. Не могут изменяться в процессе редактирования.
}

export interface ValueO2 extends BaseNodeO2 {
  type: "value";
  value: ZIdName;
  groupId: number;
  things: RemoteData<ZValueThings>;
}

export type CommonNodeO2 = AttributeO2 | GroupO2 | ObjectO2 | ValueO2;

export const makeObjKey2 = (objectId: number): React.Key => `obj-${objectId}`;
export const makeAttrKey2 = (attrId: number, ownerKey: React.Key): React.Key =>
  `attr-${attrId}/${ownerKey}`;
export const makeGroupKey2 = (groupId: number): React.Key => `group-${groupId}`;
export const makeValueKey2 = (groupId: number, valueId: number): React.Key =>
  `val-${groupId}-${valueId}`;

interface VisitorO2<Res = void> {
  obj(it: ObjectO2): Res;
  attr(it: AttributeO2): Res;
  group(it: GroupO2): Res;
  value(it: ValueO2): Res;
}

export const visitO2 = <Res = void>(
  node: CommonNodeO2,
  visitor: VisitorO2<Res>,
): Res => {
  switch (node.type) {
    case "obj":
      return visitor.obj(node);
    case "attr":
      return visitor.attr(node);
    case "group":
      return visitor.group(node);
    case "value":
      return visitor.value(node);
    default:
      throw Error(`Invalid node type`);
  }
};

export const getNodeName = (node: CommonNodeO2): string =>
  visitO2(node, {
    obj: (it) => it.object.name,
    attr: (it) => it.attr.name,
    group: (it) => it.group.name,
    value: (it) => it.value.name,
  });
