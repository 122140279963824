import React, { useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router-dom";
import { makeUrl } from "src/routes/makeUrl";
import { PageUrl } from "src/routes/PageUrl";
import { AsyncTableView } from "src/pages/EntityFiltersPage/AsyncTableView";
import {
  ZEntityRow,
  ZEntityFilters,
} from "src/pages/EntityFiltersPage/EntityList/types";
import { makeAutoObservable } from "mobx";
import { compoundEntityTableStore } from "src/pages/EntityFiltersPage/EntityList/compoundEntityTableStore";
import { InputSearch } from "src/pages/EntityFiltersPage/EntityList/InputSearch";
import { Button, Spin } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import { onError } from "src/common/onError";
import { ZChildEntities } from "src/common/attrEdit/components/ZChildEntities";
import { makeInintialEntityParams } from "src/common/entity/makeInintialEntityParams";
import styles from "./ChildEntities.module.less";
import { TableStore } from "../tables/TableStore";
import { ColumnsSettings } from "../tables/ColumnsSettings";
import {
  bindEventOnStorage,
  createStorageEventAction,
  unbindEventOnStorage,
} from "../../common/storageEventUtils";

export type ZEntityFiltersChild = ZEntityFilters & {
  parentEntityId?: number;
  parentLinkAttributeId?: number;
};

const createStore = () =>
  makeAutoObservable({
    tableStore: null as TableStore<ZEntityRow, ZEntityFiltersChild> | null,
    async init(
      objectId: number,
      parentEntityId: number,
      parentLinkAttributeId: number,
    ) {
      this.tableStore = await compoundEntityTableStore<
        ZEntityRow,
        ZEntityFiltersChild
      >(objectId, "id", {
        filters: { objectId, parentEntityId, parentLinkAttributeId },
      });
    },
  });

type PropsChildEntities = {
  parentObjId: number;
  editorInfo: ZChildEntities | undefined;
  disabled?: boolean;
};

export const ChildEntities: React.FC<PropsChildEntities> = observer(
  ({ parentObjId, editorInfo, disabled }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const childEntitiesStore = useMemo(() => createStore(), []);

    const init = async () => {
      if (!id) return;
      try {
        await childEntitiesStore.init(
          parentObjId,
          Number(id),
          Number(editorInfo?.attrId),
        );
      } catch (error) {
        onError(error);
      }
    };

    useEffect(() => {
      init();
      const handleReloadTable = createStorageEventAction(
        "lastUpdatedObject",
        (e: StorageEvent) => {
          try {
            const objId = Number(JSON.parse(e.newValue || '""'));
            if (objId === parentObjId) {
              childEntitiesStore.tableStore?.reload();
            }
          } catch (error) {
            onError(error);
          }
        },
      );
      bindEventOnStorage(handleReloadTable);
      return () => unbindEventOnStorage(handleReloadTable);
    }, []);

    if (!id || !editorInfo)
      return <div>Отображение доступно только для созданных сущностей</div>;

    const { tableStore } = childEntitiesStore;
    if (!tableStore) return <Spin spinning>загрузка</Spin>;

    const newParentUrl = makeInintialEntityParams(
      makeUrl(PageUrl.entityNew, {
        objectId: parentObjId,
      }),
      { [editorInfo.attrId]: [String(id)] },
    );

    return (
      <div className={styles.box}>
        <div className={styles.header}>
          <div className={styles.search}>
            <InputSearch store={tableStore} disabled={disabled} />
          </div>
          <div className={styles.toolbar}>
            <ColumnsSettings store={tableStore} />
            <Button
              href={newParentUrl}
              target="_blank"
              type="primary"
              size="middle"
              disabled={disabled}
            >
              <PlusCircleOutlined /> Создать
            </Button>
          </div>
        </div>

        <AsyncTableView<ZEntityRow, ZEntityFiltersChild>
          store={tableStore}
          columns={tableStore.columns}
          onRowClick={(row) => {
            if (!disabled)
              navigate(makeUrl(PageUrl.entityCard, { id: row.id }));
          }}
          useHeader
          usePagination
        />
      </div>
    );
  },
);
