// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.src-components-LayoutWithSider-LayoutWithSider-module__layout--yF4Y2 {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  height: 100%;
}
.src-components-LayoutWithSider-LayoutWithSider-module__sider--L32ng {
  display: flex;
  flex-direction: column;
  min-width: 336px;
  height: 100%;
  border-right: thin solid #e1e3e6;
}
.src-components-LayoutWithSider-LayoutWithSider-module__siderHeader--sg79C {
  padding: 8px 16px;
  display: flex;
}
.src-components-LayoutWithSider-LayoutWithSider-module__siderContent--TI08T {
  min-height: 40px;
  flex: 1;
  overflow-y: auto;
}
.src-components-LayoutWithSider-LayoutWithSider-module__siderFooter--dzk38 {
  display: flex;
}
.src-components-LayoutWithSider-LayoutWithSider-module__main--fbpkr {
  width: 100%;
  height: 100%;
  overflow-y: auto;
}
`, "",{"version":3,"sources":["webpack://./src/components/LayoutWithSider/LayoutWithSider.module.less"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;EACA,gBAAA;EACA,YAAA;AADJ;AAIA;EACI,aAAA;EACA,sBAAA;EACA,gBAAA;EACA,YAAA;EACA,gCAAA;AAFJ;AAKA;EACI,iBAAA;EACA,aAAA;AAHJ;AAMA;EACI,gBAAA;EACA,OAAA;EACA,gBAAA;AAJJ;AAOA;EACI,aAAA;AALJ;AAQA;EACI,WAAA;EACA,YAAA;EACA,gBAAA;AANJ","sourcesContent":["// layout with sider\n\n.layout {\n    display: flex;\n    flex-direction: row;\n    overflow: hidden;\n    height: 100%;\n}\n    \n.sider {\n    display: flex;\n    flex-direction: column;\n    min-width: 336px;\n    height: 100%;\n    border-right: thin solid #e1e3e6;\n}\n\n.siderHeader {\n    padding: 8px 16px;\n    display: flex;\n}\n\n.siderContent {\n    min-height: 40px;\n    flex: 1;\n    overflow-y: auto;\n}\n\n.siderFooter {\n    display: flex;\n}\n\n.main {\n    width: 100%;\n    height: 100%;\n    overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"layout": `src-components-LayoutWithSider-LayoutWithSider-module__layout--yF4Y2`,
	"sider": `src-components-LayoutWithSider-LayoutWithSider-module__sider--L32ng`,
	"siderHeader": `src-components-LayoutWithSider-LayoutWithSider-module__siderHeader--sg79C`,
	"siderContent": `src-components-LayoutWithSider-LayoutWithSider-module__siderContent--TI08T`,
	"siderFooter": `src-components-LayoutWithSider-LayoutWithSider-module__siderFooter--dzk38`,
	"main": `src-components-LayoutWithSider-LayoutWithSider-module__main--fbpkr`
};
export default ___CSS_LOADER_EXPORT___;
