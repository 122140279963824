import * as React from "react";
import { Form, Radio, Select, Spin } from "antd";
import { onError } from "src/common/onError";
import { rest } from "src/common/rest";
import {
  FormBlockItem,
  ItemProps,
  blockItem,
} from "src/components/FormWithBlocks";
import { loadRestrictions } from "src/pages/ManagementPage/objectsApi";
import { ZAttribute, zAttribute } from "src/types/ZAttribute";
import { ZOption } from "src/types/ZOption";
import { ZRestriction } from "src/types/ZRestriction";
import { AttrSelectProps } from "src/common/attrEdit/components";
import { ifDef } from "src/common/ifDef";
import { apiObjUrl } from "src/common/apiUrl";
import { fieldName } from "./fieldName";

/**
 * Компонент для выбора значения из справочника.
 * Поддерживает ограничения значений, в зависимости от другого поля.
 */

export const buildReferenceItem = (
  attr: ZAttribute,
  isMulti: boolean,
  itemProps: ItemProps,
  extraProps: AttrSelectProps,
): FormBlockItem =>
  blockItem(
    fieldName(attr),
    attr.name,
    ReferenceItem,
    {
      attr,
      isMulti,
      extraProps,
    },
    itemProps,
  );

// Используется декомпозиция компонентов по той причине, что ограницения по значению являются опциональными
// Если отслеживать нестатическое поле, то будут ошибки.
// Поэтому поле отслеживается внутри отдельного компонента RefItemConditional, где оно уже безусловное.

interface PropsReferenceItem {
  value?: string[];
  onChange?(newValue?: unknown): void;
  attr: ZAttribute;
  isMulti: boolean;
  extraProps: AttrSelectProps;
}

export const ReferenceItem: React.FC<PropsReferenceItem> = (props) => {
  const { attr, value, onChange, isMulti, extraProps } = props;
  const { referenceId, viewType } = attr;
  const [wait, setWait] = React.useState(false);
  const [allOptions, setAllOptions] = React.useState<ZOption[]>([]);
  const [restriction, setRestriction] = React.useState<ZRestriction | null>(
    null,
  );
  const onInit = async () => {
    const resp = await rest.get(apiObjUrl(`/attributes/${referenceId}`));
    const ref = zAttribute.parse(resp.data);
    const optList = ref.values?.map((a) => ({
      label: a.value || String(a.id),
      value: String(a.id),
    })); // строковые значения
    setAllOptions(optList ?? []);
    // загрузка возможных ограничений
    setRestriction(await loadRestrictions(attr.id));
  };
  React.useEffect(() => {
    setWait(true);
    onInit()
      .catch(onError)
      .finally(() => setWait(false));
  }, [referenceId]);

  // Пока идет загрузка можно показать крутилку
  if (wait) return <Spin size="small" />;

  if (restriction) {
    return (
      <RefItemConditional
        restriction={restriction}
        allOptions={allOptions}
        isMulti={isMulti}
        value={value}
        onChange={onChange}
        viewType={viewType}
        extraProps={extraProps}
      />
    );
  }

  // Нет ограничений по значению.
  return (
    <ReferenceItemBottom
      value={value}
      onChange={onChange}
      isMulti={isMulti}
      options={allOptions}
      viewType={viewType}
      extraProps={extraProps}
    />
  );
};
ReferenceItem.defaultProps = {
  value: undefined,
  onChange: undefined,
};

// ----------
interface PropsRefItemConditional {
  value: string[] | undefined;
  onChange: ((newValue?: unknown) => void) | undefined;
  restriction: ZRestriction;
  allOptions: ZOption[];
  isMulti: boolean;
  viewType: string | null | undefined;
  extraProps: AttrSelectProps;
}

const RefItemConditional: React.FC<PropsRefItemConditional> = (props) => {
  const {
    restriction,
    allOptions,
    isMulti,
    viewType,
    value,
    onChange,
    extraProps,
  } = props;
  const restrictionValueRaw = Form.useWatch(
    String(restriction?.restrictionAttributeId ?? ""),
  );
  const restrictionValue =
    Array.isArray(restrictionValueRaw) && restrictionValueRaw.length === 1
      ? restrictionValueRaw[0]!
      : undefined;
  const options: ZOption[] = React.useMemo(() => {
    // Если нет зависимостей, то options = allOptions
    if (!restriction) return allOptions;
    // Составить множество доступных значений. Если таких нет, то в итоге будет пустой список.
    let list: ZOption[] = [];
    if (restrictionValue) {
      const filtered = restriction.values.filter(
        ({ restrictionId }) => String(restrictionId) === restrictionValue,
      );
      const allowed = new Set<string>(
        filtered.map(({ sourceId }) => String(sourceId)),
      );
      list = allOptions.filter((opt) => allowed.has(opt.value));
    }
    return list;
  }, [allOptions, restriction, restrictionValue]);

  // Если меняется список опций и в нём уже нет того значения, которое было выбрано ранее, тогда нужно очистить значение
  React.useEffect(() => {
    // Путём эксперимента выяснено, что сначала всегда приходит restrictionValueRaw=undefined
    // Это некорректное значение, т.к. пустое значение соответствует []. Поэтому игнорируем undefined
    if (restrictionValueRaw && value && onChange) {
      const validValues = new Set<string>(
        options.map((option) => option.value),
      );
      onChange(value.filter((singleValue) => validValues.has(singleValue)));
    }
  }, [restrictionValueRaw]);

  return (
    <ReferenceItemBottom
      options={options}
      isMulti={isMulti}
      viewType={viewType}
      value={value}
      onChange={onChange}
      extraProps={extraProps}
    />
  );
};

// -----------

interface PropsReferenceItemBottom {
  value: string[] | undefined;
  onChange: ((newValue?: unknown) => void) | undefined;
  isMulti: boolean;
  viewType: string | null | undefined;
  options: ZOption[];
  extraProps: AttrSelectProps;
}

const ReferenceItemBottom: React.FC<PropsReferenceItemBottom> = (props) => {
  const { value, onChange, isMulti, options, viewType, extraProps } = props;
  if (!isMulti && (viewType === "RadioGroup" || viewType === "RadioButtons")) {
    const optionType = viewType === "RadioButtons" ? "button" : undefined;
    return (
      <Radio.Group
        value={value}
        onChange={onChange}
        optionType={optionType}
        options={options}
      />
    );
  }
  return (
    <Select
      value={value}
      onChange={(newValue) =>
        onChange?.(
          ifDef(newValue, (it) => (Array.isArray(it) ? it : [it])) ?? [],
        )
      }
      options={options}
      mode={isMulti ? "tags" : undefined}
      allowClear
      {...extraProps}
    />
  );
};
