import React from "react";
import { AttrTypeName } from "src/types/AttrType";
import {
  makeObectRefProps,
  makeSimpleTextProps,
  makeDictRefProps,
} from "src/common/attrView/componentsDefs";
import { ZAttrMeta } from "src/types/ZAttrMeta";
import {
  ZAttrViewInfo,
  viewsByType,
  zAttrComponentView,
} from "src/common/attrView";
import { defineAttrView } from "./defineView";
import {
  DictRef,
  SimpleText,
  ObjectRef,
  ImgList,
  LinkStd,
  FileList,
  Tag,
} from "../components";
import { makeImgListProps } from "../componentsDefs/ZImgList";
import { makeLinkStdProps } from "../componentsDefs/ZLinkStd";
import { makeTagProps } from "../componentsDefs/ZTag";
import { makeFileListProps } from "../componentsDefs/ZFileList";
import { PersonCellInfo } from "../components/PersonCellInfo";

export const createItemView = (
  attrMeta: ZAttrMeta,
  typesMap: Record<number, string>,
  values: string[],
  viewInfo: ZAttrViewInfo | undefined,
): React.ReactNode =>
  createItemViewOpt(attrMeta, typesMap, values, viewInfo) ?? values;

const createItemViewOpt = (
  attrMeta: ZAttrMeta,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  typesMap: Record<number, string>,
  values: string[],
  viewInfo: ZAttrViewInfo | undefined,
): React.ReactNode | undefined => {
  let component = viewInfo?.component;
  if (!component) {
    const typeName = typesMap[attrMeta.valueType];
    const list = viewsByType[typeName as AttrTypeName];
    const view = list?.[0];
    if (view) {
      try {
        component = zAttrComponentView.parse({ view });
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e); // для отладки
      }
    }
  }
  if (!component) return undefined;
  switch (component.view) {
    case "SimpleText":
      return defineAttrView({
        Component: SimpleText,
        cmpProps: makeSimpleTextProps(values, viewInfo),
      });
    case "ObectRef":
      return defineAttrView({
        Component: ObjectRef,
        cmpProps: makeObectRefProps(attrMeta, component, values, viewInfo),
      });
    case "DictRef":
      return defineAttrView({
        Component: DictRef,
        cmpProps: makeDictRefProps(component, attrMeta, values, viewInfo),
      });
    case "ImgList":
      return defineAttrView({
        Component: ImgList,
        cmpProps: makeImgListProps(values),
      });
    case "LinkStd":
      return defineAttrView({
        Component: LinkStd,
        cmpProps: makeLinkStdProps(values, viewInfo, attrMeta),
      });
    case "Tag":
      return defineAttrView({
        Component: Tag,
        cmpProps: makeTagProps(component, values, viewInfo),
      });
    case "PersonCellInfo":
      return defineAttrView({
        Component: PersonCellInfo,
        cmpProps: { values },
      });
    case "FileList":
      return defineAttrView({
        Component: FileList,
        cmpProps: makeFileListProps(values, viewInfo),
      });
    default:
      return undefined;
  }
};
