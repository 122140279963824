import * as React from "react";
import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { buildMainBlock } from "src/pages/EntityCardPage/blockBuilder/buildMainBlock";
import { ZObjectItem } from "src/types/ZObjectItem";
import { ZAttribute } from "src/types/ZAttribute";
import {
  FormBlockDef,
  FormWithBlockStore,
} from "src/components/FormWithBlocks";
import { ZGroup } from "src/types/ZGroup";
import { findNodeByKey } from "../../../../common/findNodeByKey";
import { loadObjects } from "../../objectsApi";
import { activateValueNodeO2, createObjNode2 } from "../utils/createMainTree";
import { CommonNodeO2, GroupO2, ObjectO2 } from "../Obj2Nodes";
import { splitOrders } from "./splitOrders";
import { apiGroupOrders, apiObjectOrders, apiValueOrders } from "./apiOrders";
import { findNodeOwnerByKey } from "../../../../common/findNodeOwnerByKey";

type Level = {
  levelNodes: CommonNodeO2[];
  index: number;
  node: CommonNodeO2;
  owner: CommonNodeO2 | null;
};

type DropResult = {
  ownerKey: React.Key;
  level: CommonNodeO2[];
  dragIndex: number;
  dropIndex: number;
};

type SrcObject = {
  key: React.Key;
  id: number;
};

export class Obj2OrdersStore {
  constructor() {
    this.formStore = new FormWithBlockStore();
    makeAutoObservable(this);
  }

  formStore: FormWithBlockStore;

  typesMap: Record<number, string> = {};

  buzy = false;

  setBuzy(flag: boolean) {
    this.buzy = flag;
  }

  async init(objNode: ObjectO2, typesMap: Record<number, string>) {
    this.typesMap = typesMap;
    try {
      this.setBuzy(true);
      this.setSrcObject({
        key: objNode.key,
        id: objNode.object.id,
      });
      this.setTreeData([]);
      const srcObjects = await loadObjects();
      const srcObj = srcObjects.find(({ id }) => objNode.object.id === id);
      if (!srcObj) throw Error("Объект более не существует");
      const dstObjNode = createObjNode2(srcObj);
      this.setTreeData(dstObjNode.children ?? []);
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(false);
    }
  }

  srcObject: SrcObject | null = null;

  setSrcObject(def: SrcObject) {
    this.srcObject = def;
  }

  async onExpand(srcNode: CommonNodeO2, expanded: boolean) {
    try {
      const dstNode = findNodeByKey(srcNode.key, this.treeData);
      if (expanded && dstNode) {
        if (dstNode.type === "group") {
          await dstNode.onExpand?.(dstNode);
          this.refreshTree();
        } else if (dstNode.type === "value") {
          await activateValueNodeO2(dstNode);
          this.refreshTree();
        }
      }
    } catch (e) {
      onError(e);
    }
  }

  treeData: CommonNodeO2[] = [];

  setTreeData(data: CommonNodeO2[]) {
    this.treeData = data;
  }

  refreshTree() {
    this.setTreeData([...this.treeData]);
  }

  msg: string = "";

  setMsg(s: string) {
    this.msg = s;
  }

  async finish(
    syncWithMainTree: (ownerKey: React.Key, order: React.Key[]) => void,
  ) {
    if (!this.dropRes) return;
    try {
      this.setBuzy(true);
      const { level, dragIndex, dropIndex, ownerKey } = this.dropRes;
      const newLevel = [...level];
      const dragNode = newLevel[dragIndex];
      if (!dragNode) return;
      if (dragIndex < dropIndex) {
        newLevel.splice(dropIndex, 0, dragNode);
        newLevel.splice(dragIndex, 1);
      } else {
        newLevel.splice(dragIndex, 1);
        newLevel.splice(dropIndex, 0, dragNode);
      }

      await this.saveOrders(newLevel, ownerKey);

      // Здесь важно внести изменения именно в тот массив, который используется для отрисовки дерева
      level.length = 0;
      newLevel.forEach((node, i) => {
        level[i] = node;
      });

      this.refreshTree();
      syncWithMainTree(
        ownerKey,
        newLevel.map(({ key }) => key),
      );
    } catch (e) {
      onError(e);
    } finally {
      this.setBuzy(false);
    }
  }

  async saveOrders(nodes: CommonNodeO2[], ownerKey: React.Key) {
    const data = splitOrders(nodes);
    if (ownerKey === this.srcObject?.key) {
      const objectId = this.srcObject?.id;
      if (!objectId) throw Error("Отсутствует id объекта");
      await apiObjectOrders(objectId, data);
    } else {
      const owner = findNodeByKey(ownerKey, this.treeData);
      if (!owner) throw Error(`Не найден узел ${ownerKey}`);
      if (owner.type === "group") {
        await apiGroupOrders(owner.group.id, data);
      } else if (owner.type === "value") {
        const valueOwner = findNodeOwnerByKey(owner.key, this.treeData);
        if (!valueOwner)
          throw Error(`Не найдена группа для значения "${owner.value.name}"`);
        if (valueOwner.owner.type !== "group") {
          throw Error(
            `Значение "${owner.value.name}" должно входить в группу, а не в "${valueOwner.owner.type}"`,
          );
        }
        await apiValueOrders(valueOwner.owner.group.id, owner.value.id, data);
      } else {
        throw Error(`Нет функции для сохранения порядка типа ${owner.type}`);
      }
    }
  }

  dropRes: DropResult | null = null;

  setDropRes(res: DropResult | null) {
    this.dropRes = res;
  }

  allowDrop(
    dragNodeKey: React.Key,
    dropNodeKey: React.Key,
    pos: number,
  ): boolean {
    // Если pos < 0, это значит что drag позиционируется перед drop. Иначе - после
    // мы имеем смесь атрибутов и групп на разных уровнях
    // нужно отличать одно от другого.
    // Предполагается, что первыми идут все атрибуты а дальше - все группы
    this.setDropRes(null);
    const dragRes = findLevel(dragNodeKey, this.treeData, null);
    if (!dragRes) return false;
    const { levelNodes, node: dragNode, owner } = dragRes;
    const ownerKey = owner?.key ?? this.srcObject?.key;
    if (!ownerKey) return false;
    const dropIndex = levelNodes.findIndex(({ key }) => key === dropNodeKey);
    const dropNode = levelNodes[dropIndex];
    if (dragNode.type === "attr") {
      if (dropNode?.type === "attr") {
        this.setDropRes({
          ownerKey,
          level: levelNodes,
          dragIndex: dragRes.index,
          dropIndex: dropIndex + (pos < 0 ? 0 : 1),
        });
        return true;
      }
      // Чтобы перенести атрибут внутри группы на первую позицию, drop будет указывать на владельца
      if (dropNodeKey === owner?.key) {
        this.setDropRes({
          ownerKey,
          level: levelNodes,
          dragIndex: dragRes.index,
          dropIndex: 0,
        });
        return true;
      }
    } else if (dragNode.type === "group") {
      // Самый простой случай - если группа на группу
      if (dropNode?.type === "group") {
        this.setDropRes({
          ownerKey,
          level: levelNodes,
          dragIndex: dragRes.index,
          dropIndex: dropIndex + (pos < 0 ? 0 : 1),
        });
        return true;
      }
      // Можно если drop=attr, а за ним идёт group. Тогда drop сановится первой группой в списке.
      if (
        dropNode?.type === "attr" &&
        levelNodes[dropIndex + 1]?.type === "group"
      ) {
        this.setDropRes({
          ownerKey,
          level: levelNodes,
          dragIndex: dragRes.index,
          dropIndex: dropIndex + 1,
        });
        return true;
      }
      // Можно если drop=owner. Это значит что атрибутов нет. А drop становится первым в группе
      if (dropNodeKey === owner?.key) {
        this.setDropRes({
          ownerKey,
          level: levelNodes,
          dragIndex: dragRes.index,
          dropIndex: 0,
        });
        return true;
      }
    }
    return false;
  }

  get rootBlock(): FormBlockDef | null {
    const attributes: ZAttribute[] = [];
    const groups: ZGroup[] = [];
    const createGroup = (node: GroupO2): ZGroup => ({
      ...node.group,
      attributes: node.children?.reduce(
        (acc, subNode) =>
          subNode.type === "attr" ? [...acc, subNode.attr] : acc,
        [] as ZAttribute[],
      ),
      groups: node.children?.reduce(
        (acc, subNode) =>
          subNode.type === "group" ? [...acc, createGroup(subNode)] : acc,
        [] as ZGroup[],
      ),
    });
    this.treeData.forEach((node) => {
      if (node.type === "attr") {
        attributes.push(node.attr);
      } else if (node.type === "group") {
        groups.push(createGroup(node));
      }
    });
    if (attributes.length === 0 && groups.length === 0) return null;
    const obj: ZObjectItem = {
      id: 1,
      name: "Test",
      attributes,
      groups,
    };
    return buildMainBlock(obj, {
      typesMap: this.typesMap,
      canUpdate: false,
    });
  }
}

const findLevel = (
  needKey: React.Key,
  levelNodes: CommonNodeO2[],
  owner: CommonNodeO2 | null,
): Level | undefined => {
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < levelNodes.length; index++) {
    const node = levelNodes[index];
    if (node) {
      if (node.key === needKey)
        return {
          levelNodes,
          index,
          node,
          owner,
        };
      if (node.children) {
        const res = findLevel(needKey, node.children, node);
        if (res) return res;
      }
    }
  }
  return undefined;
};
