import * as React from "react";
import { SaveOutlined } from "@ant-design/icons";
import { observer } from "mobx-react-lite";
import { TableStore } from "src/components/tables/TableStore";
import { Button } from "antd";
import {
  ZEntityFilters,
  ZEntityRow,
} from "src/pages/EntityFiltersPage/EntityList/types";
import axios from "axios";
import { apiObjUrl } from "src/common/apiUrl";
import { formatSortParam } from "../utils/formatParams";

interface PropsEntityExportButton {
  store: TableStore<ZEntityRow, ZEntityFilters>;
  objectName: string;
}

const createExportUrl = (
  store: TableStore<ZEntityRow, ZEntityFilters>,
): string => {
  const columnIds = store.finalColumns.map((col) => Number(col.key));
  const params: ZEntityFilters & {
    columnIds: number[];
    sorting?: string[];
  } = {
    objectId: store.filters?.objectId,
    stateId: store.filters?.stateId,
    dictionaryValueIds: store.filters?.dictionaryValueIds || [],
    query: store.filters?.query,
    sorting: formatSortParam(store.params) ?? undefined,
    columnIds,
  };

  return axios.getUri({
    url: apiObjUrl("/entities/export"),
    params,
    paramsSerializer: {
      indexes: null,
    },
  });
};

export const EntityExportButton: React.FC<PropsEntityExportButton> = observer(
  ({ store, objectName }) => (
    <Button
      type="link"
      icon={<SaveOutlined />}
      download={`${objectName}.xlsx`}
      href={createExportUrl(store)}
    />
  ),
);
