import * as React from "react";
import { notification } from "antd";
import { makeAutoObservable } from "mobx";
import { onError } from "src/common/onError";
import { rest } from "src/common/rest";
import { ZGroup, zGroup } from "src/types/ZGroup";
import { DebounceCounter, debounce } from "src/common/debounce";
import { apiObjUrl } from "src/common/apiUrl";
import { t } from "i18next";
import { dictionaryTitle, search } from "./makeTitle";

interface TreeItem {
  key: React.Key;
  title: React.ReactNode;
  children?: TreeItem[];
  selectable: boolean;
  id: number;
  name: string;
}

export class SelectDictionaryStore {
  constructor() {
    makeAutoObservable(this);
  }

  wait = false;

  setWait(flag: boolean) {
    this.wait = flag;
  }

  groups: ZGroup[] = [];

  setGroups(list: ZGroup[]) {
    this.groups = list;
  }

  async init() {
    try {
      this.setWait(true);
      const resp = await rest.get(apiObjUrl("/groups"), {
        params: { groupTypeId: 1 },
      });
      const newGroups = zGroup.array().parse(resp.data);
      this.setGroups(newGroups);
      checkGroups(newGroups);
      this.onNewValue(this.value);
    } catch (e) {
      onError(e);
    } finally {
      this.setWait(false);
    }
  }

  onNewValue(value: number | null | undefined) {
    this.setValue(value);
    this.setTreeData(createTree(this.groups, ""));
    const attr =
      typeof value === "number"
        ? findAttrByField("id", value, this.treeData)
        : undefined;
    this.setSelected(attr ? [attr.key] : []);
    if (attr) {
      const ownerGroup = this.treeData.find((g) =>
        g.children?.find((a) => a.id === value),
      );
      if (ownerGroup) {
        this.setExpandedKeys([ownerGroup.key]);
      }
    }
    this.setSearchText("");
  }

  value: number | null = null;

  setValue(value: number | null | undefined) {
    this.value = value ?? null;
  }

  selected: React.Key[] = [];

  setSelected(keys: React.Key[]) {
    this.selected = keys;
  }

  expandedKeys: React.Key[] = [];

  setExpandedKeys(keys: React.Key[]) {
    this.expandedKeys = keys;
  }

  onExpand(keys: React.Key[]) {
    this.setExpandedKeys(keys);
  }

  onSelect(keys: React.Key[]): TreeItem | undefined {
    const key0 = keys[0];
    const attr: TreeItem | undefined =
      typeof key0 === "string"
        ? findAttrByField("key", key0, this.treeData)
        : undefined;
    this.setSelected(attr ? [key0!] : []);
    return attr;
  }

  // Поиск
  searchText = ""; // Это текст в строке поиска, который обновляется моментально

  setSearchText(text: string) {
    this.searchText = text;
  }

  searchCounter: DebounceCounter = {};

  onSearch(text: string) {
    this.setSearchText(text);
    debounce(this.searchCounter, 300, () => this.updateSearch(text));
  }

  updateSearch(subString: string) {
    const tree = createTree(this.groups, subString);
    const keys = new Set<React.Key>();
    tree.forEach(({ children, key }) =>
      children?.forEach(({ id, name }) => {
        if (this.value === id || search(name, subString)) {
          keys.add(key);
        }
      }),
    );
    this.setTreeData(tree);
    this.setExpandedKeys(Array.from(keys));
  }

  treeData: TreeItem[] = [];

  setTreeData(data: TreeItem[]) {
    this.treeData = data;
  }
}

const checkGroups = (groups: ZGroup[]) => {
  let dblName = "";
  const usedAttrIds = new Set<number>();
  groups.forEach((g) => {
    g.attributes?.forEach(({ id, name }) => {
      if (usedAttrIds.has(id)) dblName = name;
      usedAttrIds.add(id);
    });
  });
  if (dblName)
    notification.warning({
      message: t("Attention!"),
      description: `Получены некорректные данные со списком справочников. Дублируется "${dblName}". Это приводит к неправильной работе компонента выбора справочника.`,
    });
};

const createTree = (groups: ZGroup[], subString?: string): TreeItem[] =>
  groups.map((gr) => ({
    key: `g${gr.id}`,
    id: gr.id,
    name: gr.name,
    title: gr.name,
    selectable: false,
    children: gr.attributes?.map((a) => ({
      key: `a${gr.id}-${a.id}`,
      id: a.id,
      name: a.name,
      get title(): React.ReactNode {
        // Оказалось, что нельзя сохранить в MobX React-элементы.
        // Вылетает ошибка в момент setTreeData как только там появляются теги
        // А если заменить на функцию, то работает.
        return dictionaryTitle(a, subString);
      },
      selectable: true,
    })),
  }));

const findAttrByField = <K extends keyof TreeItem>(
  fieldKey: K,
  needValue: TreeItem[K],
  treeData: TreeItem[],
): TreeItem | undefined => {
  // eslint-disable-next-line no-restricted-syntax
  for (const gr of treeData) {
    const a = gr?.children?.find((attr) => attr[fieldKey] === needValue);
    if (a) return a;
  }
  return undefined;
};
