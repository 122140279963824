import * as React from "react";
import { Button, Dropdown, MenuProps, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { GroupType } from "src/types/ZGroup";
import { Obj2TabStore } from "../Obj2TabStore";
import { iconGroupDict, iconGroupMnemonic } from "../utils/itemIcons";

interface PropsAddGroup {
  store: Obj2TabStore;
  disabled?: boolean;
  id?: string;
}

export const AddGroup: React.FC<PropsAddGroup> = ({ store, disabled, id }) => {
  const menuProps: MenuProps = {
    items: [
      {
        key: "1",
        label: "Мнемоническую",
        icon: iconGroupMnemonic,
        onClick: () => store.safeAddGroup(GroupType.Mnemonic),
      },
      {
        key: "2",
        label: "На основе справочника",
        icon: iconGroupDict,
        onClick: () => store.safeAddGroup(GroupType.Dictionary),
      },
    ],
  };
  return (
    <Dropdown menu={menuProps} disabled={disabled}>
      <Button id={id}>
        <Space>
          Добавить группу
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
AddGroup.defaultProps = {
  disabled: false,
  id: undefined,
};
